import { useCallback, useState } from 'react'

import { setItem } from '../lib/local-storage'
import { getStoredVisibleColumns } from '../lib/local-storage/get-valid-item'

export const useStoredColumnVisibility = <T extends string>(
  storageKey: string,
  defaultVisibility: Record<T, boolean>
) => {
  const [columnVisibility, setColumnVisibility] = useState<Record<T, boolean>>(() => {
    const visibleColumnKeys = getStoredVisibleColumns(storageKey)

    const initialColumnVisibility: Record<string, boolean> = {}
    for (const column of Object.keys(defaultVisibility)) {
      initialColumnVisibility[column] = visibleColumnKeys
        ? visibleColumnKeys.includes(column)
        : defaultVisibility[column as T]
    }

    return initialColumnVisibility
  })

  const setColumnVisibilityCallback = useCallback(
    (updatedColumnVisibility: Record<string, boolean>) => {
      setColumnVisibility(updatedColumnVisibility)
      setItem(
        storageKey,
        Object.keys(updatedColumnVisibility).filter((key) => updatedColumnVisibility[key])
      )
    },
    [storageKey]
  )

  return [columnVisibility, setColumnVisibilityCallback] as const
}
