import { AxiosInstance } from 'axios'

import { useMutationOptionsWithVariables } from './use-mutation-options'

interface DeleteGpsTrackingDeviceVariables {
  deviceId: string
}
const delteGpsTrackingDevice = (webApiClient: AxiosInstance, variables: DeleteGpsTrackingDeviceVariables) =>
  webApiClient.delete(`/tracking-devices/devices/${variables.deviceId}`)

export const useDeleteGpsTrackingDevice = () => useMutationOptionsWithVariables(delteGpsTrackingDevice)
