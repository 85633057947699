import { notification } from '../../../lib/notification'

export const notifyBookingResult = (isSuccess: boolean, isError: boolean, lockReject: boolean) => {
  if (isSuccess) {
    if (lockReject) {
      notification.error('Booking is locked for this terminal, try again soon')
    } else {
      notification.success('Successfully booked returns')
    }
  } else if (isError) {
    notification.error('Failed to book returns')
  }
}
