import React from 'react'

import { Table, TableRow } from '../../../components/bulma/table.styled'
import { StickyTableRow } from '../../../components/sticky-table-row.styled'
import { Pallet } from '../../../webapi/types/segments'

interface Props {
  pallets: Pallet[]
  className?: string
  terminalNames: Record<string, string>
}

export const PalletsTable = ({ className, pallets, terminalNames }: Props) => {
  return (
    <div>
      <Table className={className} isHoverable isFullwidth isStriped isNarrow>
        <thead>
          <StickyTableRow>
            <th>Identifier</th>
            <th>Origin</th>
            <th>Destination</th>
            <th>Number of parcels</th>
            <th>Type</th>
            <th>Barcode</th>
          </StickyTableRow>
        </thead>
        <tbody>
          {pallets.map((pallet) => (
            <TableRow key={pallet.barcode}>
              <td>{pallet.identifier}</td>
              <td>{terminalNames[pallet.origin] || pallet.origin}</td>
              <td>{terminalNames[pallet.destination] || pallet.destination}</td>
              <td>{pallet.numberOfParcels}</td>
              <td>{pallet.parcelCompositionType}</td>
              <td>{pallet.barcode}</td>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
