import React, { useCallback, useRef, useState } from 'react'

import { useClickOutside } from '../../hooks/use-click-outside'

import {
  DropdownContent,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  DropdownRoot,
  DropdownTrigger,
} from './dropdown.styled'

export type DropdownOption = {
  name: string
  code: string
}
interface Props {
  renderButton: (toggle: () => void) => JSX.Element
  selected: DropdownOption[]
  options: DropdownOption[]
  onSelected?: (options: DropdownOption[]) => void
  onClear?: () => void
  isRight?: boolean
  isStretched?: boolean
  isMenuStretched?: boolean
}

const toggle = (opts: DropdownOption[], opt: DropdownOption): DropdownOption[] => {
  if (opts.some((element) => element.code === opt.code)) {
    return opts.filter((element) => element.code !== opt.code)
  }
  return [...opts, opt]
}

export const MultiSelectDropDown = ({
  isRight,
  renderButton,
  options,
  selected,
  onSelected,
  isStretched,
  isMenuStretched,
  onClear,
}: Props) => {
  const [isActive, setIsActive] = useState(false)
  const isSelected = (option: DropdownOption): boolean => {
    if (option.code === 'NA') {
      return selected.length === 0
    }
    return selected.some((it) => it.code === option.code)
  }
  const renderOption = (option: DropdownOption) => {
    const onClick = () => {
      if (option.code === 'NA') {
        if (onClear) {
          onClear()
        }
      } else if (onSelected !== undefined) {
        onSelected(toggle(selected, option))
      }
      setIsActive(false)
    }
    return (
      <DropdownItem key={option.code} selected={isSelected(option)} onClick={onClick}>
        {option.name}
      </DropdownItem>
    )
  }

  const ref = useRef<HTMLDivElement>(null)
  const setAsInactive = useCallback(() => setIsActive(false), [])
  useClickOutside(ref, setAsInactive, isActive)

  return (
    <DropdownRoot ref={ref} isRight={isRight} expanded={isActive} isStretched={isStretched}>
      <DropdownTrigger className="dropdown-trigger">{renderButton(() => setIsActive(!isActive))}</DropdownTrigger>

      <DropdownMenu role="menu" isMenuStretched={isMenuStretched}>
        <DropdownContent>
          {options.map(renderOption)}
          <DropdownDivider />
          {renderOption({ name: 'Not Applicable', code: 'NA' })}
        </DropdownContent>
      </DropdownMenu>
    </DropdownRoot>
  )
}
