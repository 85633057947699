import styled from 'styled-components'

export const LoaderContainer = styled.div`
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`

export const Loader = styled.div.attrs({ className: 'loader' })`
  font-size: 2rem;
`
