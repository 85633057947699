import { useState } from 'react'

export interface Page {
  cursor?: string
  direction?: 'PREVIOUS' | 'NEXT'
  size?: number
}

export const usePage = (defaultPage?: Page) => {
  return useState<Page | undefined>(defaultPage)
}
