import { includes, without } from 'ramda'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'

import { useDisabledBodyScroll } from '../../../../hooks/use-disabled-body-scroll'
import { search } from '../../../../lib/search'
import { useGetMerchants } from '../../../../webapi/hooks/queries/use-get-merchants'
import { SideBoxContainer, SideBoxOverlay } from '../../../side-box.styled'
import { SimpleMessage } from '../../../simple-message'
import { ListItem } from '../list-item'
import { Search } from '../search'
import { focusSearch, scrollToTop } from '../select-side-box-utils'
import {
  SelectList,
  SideBoxFooter,
  Label,
  Button,
  Buttons,
  SelectSideBox,
  SideBoxContent,
  NoItemsMessage,
} from '../select-side-box.styled'
import { Tab } from '../tab'
import { Tabs as TabsComponent } from '../tabs'
import { TitleBar } from '../title-bar'

import { createSelectOptions, getFooterLabel } from './utils'

export const enum MerchantTabs {
  SELECTED,
  ALL,
}

export interface MerchantOption {
  id: number
  name: string
  externalName: string
}

interface MerchantSideBoxProps {
  onLoad: (selectedOptions: MerchantOption[]) => void
  onCancel: () => void
  onChange: (selectedOptions: MerchantOption[]) => void
  show: boolean
  initialSelection: number[]
  initialTab?: MerchantTabs
}

export const MerchantSideBox = ({
  onLoad,
  onCancel,
  onChange,
  show,
  initialSelection,
  initialTab = MerchantTabs.ALL,
}: MerchantSideBoxProps) => {
  const defaultOptions: MerchantOption[] = []
  const [selectedOptions, setSelectedOptions] = useState<MerchantOption[]>(defaultOptions)
  const [updatedSelection, setUpdatedSelection] = useState<MerchantOption[]>(defaultOptions)

  const [selectedTab, setSelectedTab] = useState<MerchantTabs>(initialTab)
  const [searchTerm, setSearchTerm] = useState('')

  const { data, isSuccess, isError } = useQuery(useGetMerchants())
  const merchantOptions = search(
    MerchantTabs.ALL === selectedTab ? createSelectOptions(data?.merchants) : updatedSelection,
    ['name', 'externalName'],
    searchTerm
  )

  const searchRef = useRef<HTMLInputElement>(null)
  const tabContentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (data) {
      const options = createSelectOptions(data.merchants, initialSelection)
      setSelectedOptions(options)
      setUpdatedSelection(options)
      onLoad(options)
    }
  }, [data, onLoad, initialSelection])

  useDisabledBodyScroll(show) // Prevents the background from scrolling while interacting with the component

  useEffect(() => {
    if (show) {
      focusSearch(searchRef)
    }
  }, [show, searchRef])

  const onSelectTab = (tab: MerchantTabs) => {
    setSelectedTab(tab)
    scrollToTop(tabContentRef)
    focusSearch(searchRef)
  }

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.trimStart())
    scrollToTop(tabContentRef)
  }

  const onSave = () => {
    setSelectedOptions(updatedSelection)
    onChange(updatedSelection)
  }

  const onClose = () => {
    setUpdatedSelection(selectedOptions)
    onCancel()
  }

  const onReset = () => setUpdatedSelection([])

  const onToggleSelection = (merchantOption: MerchantOption) => () => {
    if (includes(merchantOption, updatedSelection)) {
      setUpdatedSelection(without([merchantOption], updatedSelection))
    } else {
      setUpdatedSelection([...updatedSelection, merchantOption])
    }
  }

  const renderTabContent = () => (
    <SelectList ref={tabContentRef}>
      {isSuccess && (
        <>
          {merchantOptions.length > 0 &&
            merchantOptions.map((merchantOption) => (
              <ListItem
                key={merchantOption.id}
                mode={MerchantTabs.SELECTED === selectedTab ? 'REMOVE' : 'SELECT'}
                onClick={onToggleSelection(merchantOption)}
                checked={includes(merchantOption, updatedSelection)}
                label={`${merchantOption.name}, ${merchantOption.externalName}`}
                type={'checkbox'}
              />
            ))}
          {merchantOptions.length === 0 && searchTerm && <NoItemsMessage>No matches</NoItemsMessage>}
          {merchantOptions.length === 0 && !searchTerm && MerchantTabs.SELECTED === selectedTab && (
            <NoItemsMessage>No merchants selected</NoItemsMessage>
          )}
        </>
      )}
      {isError && <SimpleMessage isDanger>Failed to load merchants.</SimpleMessage>}
    </SelectList>
  )

  if (!show) {
    return null
  }

  return (
    <SideBoxContainer>
      <SideBoxOverlay onClick={onClose} />
      <SelectSideBox>
        <SideBoxContent>
          <TitleBar title="Select Merchants" onClose={onClose} />
          <TabsComponent>
            <Tab
              tab={MerchantTabs.SELECTED}
              name="Selected"
              active={MerchantTabs.SELECTED === selectedTab}
              onSelected={onSelectTab}
            />
            <Tab tab={MerchantTabs.ALL} name="All" active={MerchantTabs.ALL === selectedTab} onSelected={onSelectTab} />
          </TabsComponent>
          <Search ref={searchRef} value={searchTerm} onChange={onSearch} />
          {renderTabContent()}
        </SideBoxContent>
        <SideBoxFooter>
          <Label>{getFooterLabel(updatedSelection.length)}</Label>
          <Buttons>
            <Button disabled={updatedSelection.length === 0} onClick={onReset} isLight>
              Reset
            </Button>
            <Button disabled={updatedSelection === selectedOptions} onClick={onSave} isPrimary>
              Select
            </Button>
          </Buttons>
        </SideBoxFooter>
      </SelectSideBox>
    </SideBoxContainer>
  )
}
