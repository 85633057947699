import decode from 'jwt-decode'

export const decodeJWT = <T>(jwt: string) => {
  try {
    return decode<T>(jwt)
  } catch (error) {
    window.console.error(error)
    return null
  }
}
