import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'

import { useQueryOptions } from './use-query-options'

export interface TerminalCodeAndName {
  name: string
  code: string
}

export interface TerminalRelation {
  terminal: TerminalCodeAndName
  servicedBy: TerminalCodeAndName | null
  catchment: TerminalCodeAndName[]
}

export interface TerminalRelationsResponse {
  relations: TerminalRelation[]
}

export const getTerminalRelationsQueryKey = ['terminal-relations']

const getTerminalRelations = (webapiClient: AxiosInstance) =>
  webapiClient.get<TerminalRelationsResponse>('/freightman/terminal-relations')

export const useGetTerminalRelations = () =>
  useQueryOptions(getTerminalRelationsQueryKey, getTerminalRelations, getResponseData)
