import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useQuery } from 'react-query'

import { ListItem } from '../../../components/select/select-side-box/list-item'
import { Search } from '../../../components/select/select-side-box/search'
import { focusSearch, scrollToTop } from '../../../components/select/select-side-box/select-side-box-utils'
import {
  Button,
  Buttons,
  ListItemLabelBold,
  NoItemsMessage,
  SelectList,
  SelectSideBox,
  SideBoxContent,
  SideBoxFooter,
} from '../../../components/select/select-side-box/select-side-box.styled'
import { Tab } from '../../../components/select/select-side-box/tab'
import { Tabs } from '../../../components/select/select-side-box/tabs'
import { TitleBar } from '../../../components/select/select-side-box/title-bar'
import { SideBoxContainer, SideBoxOverlay } from '../../../components/side-box.styled'
import { SimpleMessage } from '../../../components/simple-message'
import { useDisabledBodyScroll } from '../../../hooks/use-disabled-body-scroll'
import { search } from '../../../lib/search'
import { useGetDevices } from '../../../webapi/hooks/queries/use-get-devices'

import { DeviceAssignedIcon, DeviceLabel } from './sidebox.styled'
import { TimeInputField, TimePickerColumn, TimePickerColumns } from './time-picker.styled'

interface Props {
  onCancel: () => void
  onShowPos: (startTime: number, endTime: number, onRoute?: boolean, deviceId?: string) => void
  initialDeviceId?: string
  initialStartTime?: number
  initialEndTime?: number
}

const enum GPSTrackerTabs {
  DEVICES,
  ROUTES,
}

export const GpsTrackerSideBox = ({
  onCancel,
  onShowPos,
  initialDeviceId,
  initialStartTime,
  initialEndTime,
}: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(initialStartTime ? new Date(initialStartTime) : null)
  const [endDate, setEndDate] = useState<Date | null>(initialEndTime ? new Date(initialEndTime) : null)
  const [selectedDevice, setSelectedDevice] = useState(initialDeviceId)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedTab, setSelectedTab] = useState(GPSTrackerTabs.DEVICES)
  const [onRoute, setOnRoute] = useState<boolean>()

  const searchRef = useRef<HTMLInputElement>(null)
  const selectListRef = useRef<HTMLDivElement>(null)

  const { data: devicesResponse, isSuccess, isError } = useQuery({ ...useGetDevices(), staleTime: Infinity })

  useDisabledBodyScroll()

  useEffect(() => {
    focusSearch(searchRef)
  }, [])

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.trimStart())
    scrollToTop(selectListRef)
  }

  const onSelectTab = (tab: GPSTrackerTabs) => {
    setSelectedTab(tab)
    scrollToTop(selectListRef)
    focusSearch(searchRef)
  }

  const onShow = () => {
    if (startDate && endDate) {
      onCancel()
      onShowPos(startDate.getTime(), endDate.getTime(), onRoute, selectedDevice)
    }
  }

  const renderContent = () => {
    const devices = devicesResponse?.devices || []
    const devicesSearchResult = search(devices, ['friendlyId'], searchTerm)
    return (
      <SelectList ref={selectListRef}>
        {isError && <SimpleMessage isDanger>Failed to load devices.</SimpleMessage>}
        {isSuccess && (
          <>
            {searchTerm && !devicesSearchResult.length && <NoItemsMessage>{'No matches'}</NoItemsMessage>}
            {!searchTerm && (
              <ListItem
                key={'show-all'}
                mode="SELECT"
                label={<ListItemLabelBold>Show all devices</ListItemLabelBold>}
                type={'radio'}
                onClick={() => setSelectedDevice(undefined)}
                checked={!selectedDevice}
              />
            )}
            {devicesSearchResult.map(({ deviceId, friendlyId, routeId }) => (
              <ListItem
                key={deviceId}
                mode="SELECT"
                label={
                  <DeviceLabel>
                    <span>{`ID: ${friendlyId}`}</span>
                    {routeId ? <DeviceAssignedIcon /> : null}
                  </DeviceLabel>
                }
                type={'radio'}
                onClick={() => {
                  setSelectedDevice(deviceId), setOnRoute(undefined)
                }}
                checked={selectedDevice === deviceId}
              />
            ))}
          </>
        )}
      </SelectList>
    )
  }

  return (
    <SideBoxContainer>
      <SideBoxOverlay onClick={onCancel} />
      <SelectSideBox>
        <SideBoxContent>
          <TitleBar title="Select GPS tracking device" onClose={onCancel} />
          <Tabs>
            <Tab
              tab={GPSTrackerTabs.DEVICES}
              name="Devices"
              active={GPSTrackerTabs.DEVICES === selectedTab}
              onSelected={onSelectTab}
            />
          </Tabs>
          <Search ref={searchRef} value={searchTerm} onChange={onSearch} />
          {renderContent()}
        </SideBoxContent>
        <SideBoxFooter>
          <TimePickerColumns>
            <TimePickerColumn>
              From:
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd/MM/yyyy HH:mm"
                customInput={<TimeInputField />}
              />
            </TimePickerColumn>
            <TimePickerColumn>
              To:
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd/MM/yyyy HH:mm"
                customInput={<TimeInputField />}
              />
            </TimePickerColumn>
          </TimePickerColumns>
          {!selectedDevice && (
            <ListItem
              key={'show-on-route'}
              mode="SELECT"
              label="Only show position from routes"
              type={'checkbox'}
              onClick={() => setOnRoute(!onRoute)}
              checked={onRoute || false}
            />
          )}
          <Buttons>
            <Button disabled={!startDate || !endDate} isPrimary onClick={onShow}>
              Show
            </Button>
          </Buttons>
        </SideBoxFooter>
      </SelectSideBox>
    </SideBoxContainer>
  )
}
