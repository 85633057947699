import { AxiosInstance } from 'axios'

import { PalletConfiguration } from '../../types/pallet-configuration'

import { useMutationOptionsWithVariables } from './use-mutation-options'

interface DeletePalletConfigurationVariables {
  config: PalletConfiguration
}

const deletePalletConfiguration = (webapiClient: AxiosInstance, { config }: DeletePalletConfigurationVariables) =>
  webapiClient.delete<void>(`/freightman/pallet-configs/${config.identifier}`)

export const useDeletePalletConfiguration = () => useMutationOptionsWithVariables(deletePalletConfiguration)
