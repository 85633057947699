import { useCallback, useState } from 'react'

import { getStoredTerminalCodes, setItem } from '../lib/local-storage'

export const useStoredTerminalCodes = (storageKey: string) => {
  const [storedTerminalCodes, setStoredTerminalCodes] = useState<string[]>(() => getStoredTerminalCodes(storageKey))

  const setStoredTerminalCodesCallback = useCallback(
    (terminalCodes: string[]) => {
      setStoredTerminalCodes(terminalCodes)
      setItem(storageKey, terminalCodes)
    },
    [storageKey]
  )

  return [storedTerminalCodes, setStoredTerminalCodesCallback] as const
}
