import { AppState, Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppSettings } from '../hooks/use-app-settings'
import { useEnvironmentVariable } from '../hooks/use-environment-variable'

interface Props {
  children: JSX.Element
}

export const BudbeeAuth0Provider = ({ children }: Props) => {
  const navigate = useNavigate()
  const audience = useEnvironmentVariable('AUTH0_AUDIENCE')
  const clientId = useEnvironmentVariable('AUTH0_CLIENT_ID')
  const domain = useEnvironmentVariable('AUTH0_DOMAIN')
  const { isStandaloneApplication } = useAppSettings()

  if (!isStandaloneApplication) {
    return children
  }

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      scope="openid"
      cacheLocation="localstorage"
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  )
}
