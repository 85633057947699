import classnames from 'classnames'
import styled from 'styled-components'

interface ButtonProps {
  isLoading?: boolean
  isPrimary?: boolean
  isLight?: boolean
  isSmall?: boolean
  isDanger?: boolean
  isRounded?: boolean
  isInfo?: boolean
}

export const Button = styled.button.attrs(
  ({ isLoading, isPrimary, isLight, isSmall, isDanger, isRounded, isInfo }: ButtonProps) => ({
    className: classnames('button', {
      'is-loading': isLoading,
      'is-primary': isPrimary,
      'is-light': isLight,
      'is-small': isSmall,
      'is-danger': isDanger,
      'is-info': isInfo,
      'is-rounded': isRounded,
    }),
  })
)<ButtonProps>``

export const Delete = styled.button.attrs({ className: 'delete' })``

export const SquareButton = styled(Button).attrs(() => ({}))`
  && {
    width: 150px;
    border-radius: 0;
  }
`
