import { TerminalOption } from '../../../../components/select'
import { TerminalCountryCode } from '../../../../types/terminal-country-codes'
import { TerminalLocation } from '../../../../webapi/hooks/queries/use-get-terminal-locations'
import {
  BottomLeftWidget,
  DestinationType,
  EjectionTerminal,
  EjectionTerminalRelation,
  PalletConfiguration,
} from '../../../../webapi/types/pallet-configuration'
import { TerminalGroup } from '../../../../webapi/types/terminal-groups'

export type EditableEjectionTerminal = {
  relation: EjectionTerminalRelation | null
  terminalCode: string | null
  terminalGroupId: string | null
}

export const toEditableEjectionTerminal = (ejectionTerminal: EjectionTerminal | null): EditableEjectionTerminal =>
  ejectionTerminal ?? { relation: null, terminalCode: null, terminalGroupId: null }

export const toTerminalNames = (terminalCodes: string[], terminalLocations: Map<string, TerminalLocation>) =>
  terminalCodes
    .map((terminalCode) => terminalLocations.get(terminalCode)?.name)
    .filter(Boolean)
    .join(', ')

export const toSelectOption = (terminalCode: string, terminalLocations: Map<string, TerminalLocation>) => {
  const terminalLocation = terminalLocations.get(terminalCode)
  return terminalLocation
    ? {
        name: terminalLocation.name,
        countryCode: terminalLocation.countryCode,
        code: terminalLocation.terminalCode,
        street: terminalLocation.street,
        postalCode: terminalLocation.postalCode,
      }
    : null
}

export const toSelectOptions = (
  terminalCodes: string[],
  terminalLocations: Map<string, TerminalLocation>
): TerminalOption[] => {
  const selectOptions: TerminalOption[] = []
  for (const terminalCode of terminalCodes) {
    const option = toSelectOption(terminalCode, terminalLocations)
    if (option) {
      selectOptions.push(option)
    }
  }
  return selectOptions
}

export const isValidEjectionTerminal = (
  editableEjectionTerminal: EditableEjectionTerminal
): editableEjectionTerminal is EjectionTerminal =>
  Boolean(
    editableEjectionTerminal.relation &&
      (editableEjectionTerminal.terminalCode || editableEjectionTerminal.terminalGroupId)
  )

export const buildPreviewUrl = (
  webapiUrl: string,
  destinationName: string,
  code: string,
  destinationTypes: string[],
  bottomLeftWidget: BottomLeftWidget
) => {
  const queryParams = {
    identifier: '1234567890ABCD',
    'friendly-id': 'ABC123',
    code: code,
    sender: 'Instabee HQ',
    receiver: destinationName,
    'receiver-street': '1 Foo Street',
    'receiver-postal-code': '1234',
    'receiver-country-code': 'SE',
    'allows-home': String(destinationTypes.length === 0 || destinationTypes.includes(DestinationType.HOME)),
    'allows-locker': String(destinationTypes.length === 0 || destinationTypes.includes(DestinationType.LOCKER)),
    'allows-merchant-return': String(
      destinationTypes.length === 0 || destinationTypes.includes(DestinationType.MERCHANT)
    ),
    'bottom-left': bottomLeftWidget,
  }
  return `${webapiUrl}/freightman/pallet-label.png?${new URLSearchParams(queryParams).toString()}`
}

export const createConfigIfValid = (
  identifier: string | undefined,
  appliesToTerminalCodes: string[],
  name: string,
  code: string,
  destinationIdentifier: string | null,
  ejectionTerminal: EditableEjectionTerminal,
  ejectionCountries: TerminalCountryCode[],
  destinationTypes: DestinationType[],
  returnsToMerchantWarehouseIdentifier: string | null,
  bottomLeftWidget: BottomLeftWidget
): PalletConfiguration | null => {
  if (!destinationIdentifier) {
    return null
  }

  const hasEjectionTerminal = isValidEjectionTerminal(ejectionTerminal)
  const hasEjectionCountries = ejectionCountries.length > 0
  const hasReturnsToMerchant = returnsToMerchantWarehouseIdentifier != null

  if (!hasReturnsToMerchant && !hasEjectionCountries && !hasEjectionTerminal) {
    return null
  }

  if (hasEjectionTerminal && hasEjectionCountries) {
    return null
  }

  if (name.trim().length > 0 && code.trim().length > 0 && appliesToTerminalCodes.length > 0) {
    return {
      identifier,
      appliesTo: { terminalCodes: appliesToTerminalCodes },
      name,
      code,
      destination: destinationIdentifier,
      properties: {
        ejectionTerminal: isValidEjectionTerminal(ejectionTerminal) ? ejectionTerminal : null,
        ejectionCountries,
        destinationTypes,
        returnsToMerchantWarehouse: returnsToMerchantWarehouseIdentifier
          ? { locationId: returnsToMerchantWarehouseIdentifier }
          : null,
      },
      bottomLeftWidget,
    }
  }

  return null
}

export const determineDefaultTab = (): TerminalCountryCode => 'SE'

export const prettyEjectionTerminalRelationName = (relation: EjectionTerminalRelation, skipTerminal?: boolean) => {
  switch (relation) {
    case EjectionTerminalRelation.TO_TERMINAL:
      return 'To ' + (skipTerminal ? '' : 'Terminal')
    case EjectionTerminalRelation.IN_CATCHMENT:
      return 'In Catchment'
    case EjectionTerminalRelation.IN_TERMINAL_GROUP:
      return 'In Terminal Group'
  }
}

export const prettyEjectionTerminal = (
  ejectionTerminal: EjectionTerminal,
  terminalLocations: Map<string, TerminalLocation>,
  terminalGroups?: TerminalGroup[]
) => {
  const relationName = prettyEjectionTerminalRelationName(ejectionTerminal.relation, true)
  if (ejectionTerminal.relation === 'IN_TERMINAL_GROUP') {
    const terminalGroup = terminalGroups?.find((group) => group.identifier === ejectionTerminal.terminalGroupId)
    return `${relationName} ${terminalGroup?.name ?? ''}`
  } else {
    const terminalLocation = ejectionTerminal.terminalCode ? terminalLocations.get(ejectionTerminal.terminalCode) : null
    return `${relationName} ${terminalLocation?.name ?? ''}`
  }
}

export const prettyDestinationTypeName = (type: DestinationType) => {
  switch (type) {
    case DestinationType.HOME:
      return 'Home'
    case DestinationType.LOCKER:
      return 'Locker'
    case DestinationType.MERCHANT:
      return 'Merchant'
  }
}

export const prettyBottomLeftWidgetName = (type: BottomLeftWidget) => {
  switch (type) {
    case BottomLeftWidget.DEFAULT:
      return 'Default'
    case BottomLeftWidget.FINALIZED_BOOKED:
      return 'Ready & Booked'
    case BottomLeftWidget.INTERNATIONAL:
      return 'International'
    case BottomLeftWidget.NONE:
      return 'None'
  }
}
