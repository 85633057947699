import { faSatellite } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import { Button } from '../../../components/bulma/button.styled'
import { Card, CardContent } from '../../../components/bulma/card.styled'
import { CardHeader, CardHeaderButtons, CardHeaderTitle } from '../../../components/card.styled'
import { Icon } from '../../../components/select/select.styled'
import { useEnvironmentVariable } from '../../../hooks/use-environment-variable'
import { paramToEpochMSTimestamp } from '../../../lib/url-parameters'
import { useGetDevices } from '../../../webapi/hooks/queries/use-get-devices'
import { useGetGpsTrackingPositions } from '../../../webapi/hooks/queries/use-get-gps-tracking-positions'
import { getPositions, getPositionGeoJson } from '../../../webapi/positions'

import { ErrorFetchingData, NoPositionsWarning } from './messages'
import { GpsPositionMap } from './position-map'
import { MapContent } from './position-map.styled'
import { createSearchParams, getPositionsPageTitle } from './positions-utils'
import { GpsTrackerSideBox } from './sidebox'

export const Positions = () => {
  const [selectDevices, setSelectDevices] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const startTimeParam = paramToEpochMSTimestamp(searchParams.get('startTime'))
  const endTimeParam = paramToEpochMSTimestamp(searchParams.get('endTime'))
  const defaultStartTime = useMemo(() => Date.now() - 1000 * 60 * 10, [])
  const defaultEndTime = useMemo(() => Date.now(), [])

  const startTime = startTimeParam || defaultStartTime
  const endTime = endTimeParam || defaultEndTime
  const deviceId = searchParams.get('deviceId') || undefined
  const onRoute = searchParams.get('onRoute') == 'true' || undefined

  const mapboxtoken = useEnvironmentVariable('MAPBOX_ACCESS_TOKEN')

  const queryVariables = { startTime, endTime, deviceId, onRoute }
  const {
    data: positions,
    isSuccess: isPositionsSuccess,
    isError: isPositionsError,
  } = useQuery({
    ...useGetGpsTrackingPositions(queryVariables),
  })

  const { data: devices, isError: isDevicesError } = useQuery({
    ...useGetDevices(),
    staleTime: Infinity,
  })

  const onShowPositions = (startTime: number, endTime: number, onRoute?: boolean, deviceId?: string) =>
    setSearchParams(createSearchParams(startTime, endTime, onRoute, deviceId))

  const coordinates = useMemo(() => getPositions(positions), [positions])
  const positionsList = useMemo(() => getPositionGeoJson(positions), [positions])
  const hasErrors = isPositionsError || isDevicesError

  return (
    <Card>
      <CardContent>
        <CardHeader>
          <CardHeaderTitle>{getPositionsPageTitle(devices, deviceId)}</CardHeaderTitle>
          <CardHeaderButtons>
            <Button isSmall onClick={() => setSelectDevices(true)}>
              <Icon icon={faSatellite} />
              Tracking devices
            </Button>
          </CardHeaderButtons>
        </CardHeader>
        <MapContent>
          {!hasErrors && isPositionsSuccess && coordinates?.length == 0 && <NoPositionsWarning />}
          {hasErrors && <ErrorFetchingData />}
          <GpsPositionMap coordinates={coordinates} mapboxtoken={mapboxtoken} geoJsonPositions={positionsList} />
          {selectDevices && (
            <GpsTrackerSideBox
              onCancel={() => setSelectDevices(false)}
              onShowPos={onShowPositions}
              initialDeviceId={deviceId}
              initialStartTime={startTime}
              initialEndTime={endTime}
            />
          )}
        </MapContent>
      </CardContent>
    </Card>
  )
}
