import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const SideBoxContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`

export const SideBoxOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const SideBox = styled.div.attrs(() => ({
  className: 'box',
}))`
  position: absolute;
  right: 0;
  top: 0;
  width: 500px;
  height: 100vh;
`

export const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CloseIcon = styled(FontAwesomeIcon).attrs({ icon: faClose })`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5rem;
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`
