import classnames from 'classnames'
import styled from 'styled-components'

interface NotificationProps {
  isInfo?: boolean
  isWarning?: boolean
  isSuccess?: boolean
  isDanger?: boolean
}

export const Notification = styled.div.attrs(({ isInfo, isWarning, isSuccess, isDanger }: NotificationProps) => ({
  className: classnames('notification', {
    'is-info': isInfo,
    'is-warning': isWarning,
    'is-success': isSuccess,
    'is-danger': isDanger,
  }),
}))<NotificationProps>``

export const NotificationDeleteButton = styled.button.attrs({
  className: 'delete',
})``
