import { createContext } from 'react'

import { defaultExternalEnvironmentVariables } from '../lib/environment-variables'
import { AppSettings } from '../types/app-settings'

export const AppSettingsContext = createContext<AppSettings>({
  baseUrl: '',
  basename: '',
  rootElementId: '',
  env: defaultExternalEnvironmentVariables,
})

export const AppSettingsProvider = AppSettingsContext.Provider
export const AppSettingsConsumer = AppSettingsContext.Consumer
