import mapboxgl, { Map } from 'mapbox-gl'
import React, { useRef, useEffect, useState } from 'react'

import { heatmap } from '../../../components/map/heatmap'

interface Props {
  coordinates?: number[][]
  mapboxtoken: string
  geoJsonPositions?: {
    type: 'FeatureCollection'
    features: {
      type: 'Feature'
      properties: { description: string }
      geometry: { type: 'Point'; coordinates: number[] }
    }[]
  }
}

export const GpsPositionMap = ({ coordinates, mapboxtoken, geoJsonPositions }: Props) => {
  const mapContainerRef = useRef(null)
  const [map, setMap] = useState<Map>()

  const [lat, lng] = [18.04, 59.33]
  const zoom = 10
  const heatmapId = 'heatmap-pos'
  const gpsPositionsId = 'gps-positions'

  useEffect(() => {
    if (!mapContainerRef.current) {
      return
    }

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lat, lng],
      zoom: zoom,
      accessToken: mapboxtoken,
    })
    map.addControl(new mapboxgl.NavigationControl())

    map.on('load', () => setMap(map))

    return () => map.remove()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!map || !coordinates) {
      return
    }
    if (map.getLayer(heatmapId)) {
      map.removeLayer(heatmapId)
    }
    if (map.getSource(heatmapId)) {
      map.removeSource(heatmapId)
    }
    if (coordinates.length > 0) {
      const layer = heatmap(heatmapId, coordinates)
      map.addLayer(layer)
    }
  }, [map, coordinates])

  useEffect(() => {
    if (!map || !geoJsonPositions) {
      return
    }
    if (map.getLayer(gpsPositionsId)) {
      map.removeLayer(gpsPositionsId)
    }
    if (map.getSource(gpsPositionsId)) {
      map.removeSource(gpsPositionsId)
    }
    if (geoJsonPositions) {
      map.addSource(gpsPositionsId, {
        type: 'geojson',
        data: geoJsonPositions,
      })
      map.addLayer({
        id: gpsPositionsId,
        type: 'circle',
        source: gpsPositionsId,
        paint: {
          'circle-color': '#4264fb',
          'circle-radius': 3,
          'circle-opacity': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 15, 1],
        },
      })
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })

      map.on('mouseenter', gpsPositionsId, (e) => {
        map.getCanvas().style.cursor = 'pointer'
        if (e.features) {
          if (e.features[0].geometry.type == 'Point') {
            const coordinates = e.features[0].geometry.coordinates.slice()
            const description = e.features[0].properties?.description || ' '
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
            }
            popup.setLngLat([coordinates[0], coordinates[1]]).setHTML(description).addTo(map)
          }
        }
        map.on('mouseleave', gpsPositionsId, () => {
          map.getCanvas().style.cursor = ''
          popup.remove()
        })
      })
    }
  }, [map, geoJsonPositions])

  const style = {
    width: '100%',
    height: '80vh',
  }

  return <div className="map-container" ref={mapContainerRef} style={style} data-testid={'positions-map'} />
}
