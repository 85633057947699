import { search } from '../../../lib/search'
import { TerminalCountryCode } from '../../../types/terminal-country-codes'
import { TerminalRelation } from '../../../webapi/hooks/queries/use-get-terminal-relations'

export type TerminalSelector = {
  visible: boolean
  terminalCode: string | null
  servicedByTerminalCode: string[]
  excludedTerminalCodes: string[]
}

export const hiddenSelector = (): TerminalSelector => ({
  visible: false,
  terminalCode: null,
  servicedByTerminalCode: [],
  excludedTerminalCodes: [],
})

export const visibleSelector = (terminalCode: string, servicedByTerminalCode: string | null): TerminalSelector => ({
  visible: true,
  terminalCode,
  servicedByTerminalCode: servicedByTerminalCode ? [servicedByTerminalCode] : [],
  excludedTerminalCodes: [terminalCode], // The terminal for which we select serviced by should not be selectable in the list.
})

export const determineSelectorDefaultTab = (): TerminalCountryCode => 'SE'

export const scrollIntoView = (element?: HTMLElement) =>
  element?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })

export const filterRelations = (relations: TerminalRelation[] = [], filter: string) => {
  const keys = ['terminal.name', 'servicedBy.name']
  return search(relations, keys, filter)
}
