import { omit } from 'ramda'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer, Slide } from 'react-toastify'

import { AppSettingsProvider } from '../contexts/app-settings-context'
import { ReduxStore } from '../state/store'
import { GroundOpsAdminClientOptions } from '../types/app-settings'

import { BudbeeAuth0Provider } from './budbee-auth0-provider'
import { WebapiClientProvider } from './webapi-client-provider'

interface Props {
  children: JSX.Element
  store: ReduxStore
  options: GroundOpsAdminClientOptions
}

export const BaseRoot = ({ children, store, options }: Props) => (
  <Provider store={store}>
    <QueryClientProvider client={new QueryClient()}>
      <AppSettingsProvider value={omit(['webapiJWT'], options)}>
        <WebapiClientProvider store={store}>
          <ToastContainer draggable={false} pauseOnFocusLoss={false} transition={Slide} />
          {children}
        </WebapiClientProvider>
      </AppSettingsProvider>
    </QueryClientProvider>
  </Provider>
)

export const Root = ({ children, store, options }: Props) => (
  <BaseRoot store={store} options={options}>
    <BrowserRouter basename={options.basename}>
      <BudbeeAuth0Provider>{children}</BudbeeAuth0Provider>
    </BrowserRouter>
  </BaseRoot>
)
