import { MerchantDropOffParcelsPhase } from '../../../webapi/hooks/queries/use-get-merchant-drop-off-parcels'

export const phaseDescription: Record<MerchantDropOffParcelsPhase, string> = {
  NOT_INJECTED: 'Not Injected at',
  IN_LOCKER: 'In locker',
  TO_BE_TRANSITED: 'To Transit from',
  IN_TRANSIT: 'In-transit to',
  BOOKABLE: 'Ready to Book at',
  BOOKED: 'Booked at',
  REACHED_MAX_ATTEMPTS: 'Reached max attempts',
}
