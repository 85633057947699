import { useEffect, useMemo, useState } from 'react'

const carriersMenuSelector = 'nav.navbar-wide'

/*
  Since the application is embedded within another application, we need to know certain things about the outside application.
  This hook returns the height of the carriers application's main menu and subscribes to changes to it so that it can return
  an up to date height value.
*/
export const useCarriersMenuHeight = () => {
  const element = useMemo(() => document.querySelector(carriersMenuSelector), [])
  const initialHeight = useMemo(() => (element instanceof HTMLElement ? element.offsetHeight : 0), [element])
  const [height, setHeight] = useState(initialHeight)

  useEffect(() => {
    const resizeObserver = new ResizeObserver(([menuDomRect]) => {
      if (menuDomRect) {
        setHeight(menuDomRect.contentRect.height)
      }
    })

    if (element) {
      resizeObserver.observe(element)
    }
    return () => {
      if (element) {
        resizeObserver.unobserve(element)
      }
    }
  }, [element])

  return height
}
