import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'
import { WebapiAcceptHeaders } from '../../../types/webapi-accept-headers'

import { useQueryOptions } from './use-query-options'

interface Terminal {
  terminalCode: string
  terminalName: string
}
export interface Device {
  deviceId: string
  imei: string | null
  protocol: string
  routeId: string | null
  assignedAt: number | null
  friendlyId: string
  simCardId: string | null
  terminal: Terminal | null
  batteryLevel: number | null
  batteryUpdatedAt: number | null
}

export type GetDeviceResponse = {
  devices: Device[]
}

export const getDevicesQueryKey = ['tracking-devices', 'devices']

const getDevices = (webapiClient: AxiosInstance) =>
  webapiClient.get<GetDeviceResponse>('/tracking-devices/devices', {
    headers: { accept: WebapiAcceptHeaders.DEVICES_V1 },
  })

export const useGetDevices = () => useQueryOptions(getDevicesQueryKey, getDevices, getResponseData)
