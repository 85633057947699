import { useCallback, useState } from 'react'

import { setItem } from '../lib/local-storage'
import { getStoredTerminalCode } from '../lib/local-storage/get-valid-item'

export const useStoredTerminalCode = (storageKey: string) => {
  const [storedTerminalCode, setStoredTerminalCode] = useState(() => getStoredTerminalCode(storageKey))

  const setStoredTerminalCodeCallback = useCallback(
    (terminalCode?: string) => {
      setStoredTerminalCode(terminalCode)
      setItem(storageKey, terminalCode || null)
    },
    [storageKey]
  )

  return [storedTerminalCode, setStoredTerminalCodeCallback] as const
}
