import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'

import { useQueryOptionsWithVariables } from './use-query-options'

interface GetReadyOnDemandReturnsVariables {
  terminalCode: string
}

interface ReadyOnDemandReturn {
  merchantName: string
  merchantId: number
  countParcels: number
  cutOffUtc: string
  cutOffLocal: string
}

export type GetReadyOnDemandReturnsResponse = ReadyOnDemandReturn[]

export const getReadyOnDemandReturnsQueryKey = ['orders', 'get-ready-ondemand-returns']

const getReadyOnDemandReturns = (webapiClient: AxiosInstance, { terminalCode }: GetReadyOnDemandReturnsVariables) =>
  webapiClient.get<GetReadyOnDemandReturnsResponse>(`/orders/get-ready-ondemand-returns/${terminalCode}`)

export const useGetReadyOnDemandReturns = (variables: GetReadyOnDemandReturnsVariables) =>
  useQueryOptionsWithVariables(getReadyOnDemandReturnsQueryKey, getReadyOnDemandReturns, variables, getResponseData)
