import { faSliders } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'

import { CardHeaderButton } from '../../../components/card.styled'
import { Icon } from '../../../components/select/select.styled'

import { OptionsSideBox } from './options-side-box'
import { DashboardColumnVisibility } from './types'

interface OptionsProps {
  columns: DashboardColumnVisibility
  setColumns: (columns: DashboardColumnVisibility) => void
}

export const ViewOptions = ({ columns, setColumns }: OptionsProps) => {
  const [showSideBox, setShowSideBox] = useState(false)
  const onSetColumns = (visibility: DashboardColumnVisibility) => {
    setColumns(visibility)
    setShowSideBox(false)
  }

  const enabledColumnCount = Object.values(columns).filter(Boolean).length

  return (
    <>
      <CardHeaderButton onClick={() => setShowSideBox(true)}>
        <Icon icon={faSliders} />
        Columns{enabledColumnCount ? ` (${enabledColumnCount})` : ''}
      </CardHeaderButton>
      <OptionsSideBox
        show={showSideBox}
        onCancel={() => setShowSideBox(false)}
        columns={columns}
        setColumns={onSetColumns}
      />
    </>
  )
}
