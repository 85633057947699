import { GetGpsTrackingPositionsResponse } from './hooks/queries/use-get-gps-tracking-positions'

export const getPositions = (gpsPositions?: GetGpsTrackingPositionsResponse) => {
  if (!gpsPositions) {
    return
  }
  const polyline: number[][] = []
  gpsPositions.positions.forEach(function (p) {
    polyline.push([p.longitude, p.latitude])
  })
  return polyline
}

export const getPositionGeoJson = (gpsPositions?: GetGpsTrackingPositionsResponse) => {
  if (!gpsPositions) {
    return
  }
  const geojson = {
    type: 'FeatureCollection' as const,
    features: gpsPositions.positions.map((item) => {
      return {
        type: 'Feature' as const,
        properties: {
          description: `<p><b>Time:</b> ${new Date(item.timestamp).toLocaleTimeString()}</p> 
          <p><b>Route-id:</b> ${item.routeId || '-'}</p>
          <p><b>IMEI:</b> ${item.imei}</p>`,
        },
        geometry: {
          type: 'Point' as const,
          coordinates: [item.longitude, item.latitude],
        },
      }
    }),
  }
  return geojson
}
