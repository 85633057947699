import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { ActionConfirmationModal } from '../../../components/action-confirmation-modal/action-confirmation-modal'
import { Button } from '../../../components/bulma/button.styled'
import { Dropdown, DropdownElement } from '../../../components/dropdown'
import { Icon } from '../../../components/select/select.styled'
import { notification } from '../../../lib/notification'
import { useDeletePalletConfiguration } from '../../../webapi/hooks/mutations/use-delete-pallet-configuration'
import { getPalletConfigurationsQueryKey } from '../../../webapi/hooks/queries/use-get-pallet-configurations'
import { PalletConfiguration } from '../../../webapi/types/pallet-configuration'

import { Image } from './configuration-dropdown.styled'

export const DELETE = { name: 'Delete config', code: 'DELETE' }

const OPTS = [[DELETE]]

interface Props {
  isUp?: boolean
  config: PalletConfiguration
  onDelete: () => void
}

export const ConfigurationDropdown = ({ isUp, config, onDelete }: Props) => {
  const queryClient = useQueryClient()
  const invalidateConfigQueries = () => {
    queryClient.invalidateQueries([getPalletConfigurationsQueryKey])
  }
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const deleteConfig = useMutation({
    ...useDeletePalletConfiguration(),
    onSuccess: () => {
      onDelete()
      notification.success('Deleted pallet config')
      invalidateConfigQueries()
    },
    onError: () => {
      notification.error('Failed to delete pallet config')
    },
  })

  const renderButton = (toggle: () => void) => (
    <Button isSmall aria-haspopup="true" aria-controls="dropdown-menu" onClick={() => toggle()}>
      <Image>
        <Icon icon={faEllipsisV} />
      </Image>
    </Button>
  )
  const onSelected = (value: DropdownElement) => {
    if (value.code === DELETE.code) {
      setShowConfirmModal(true)
    }
  }

  const onConfirmDelete = () => {
    deleteConfig.mutate({ config })
    setShowConfirmModal(false)
  }

  const renderConfirmModal = () => {
    return (
      <ActionConfirmationModal
        isConfirming={deleteConfig.isLoading}
        variant="danger"
        confirmButtonText="Delete"
        onClose={() => setShowConfirmModal(false)}
        onConfirm={onConfirmDelete}
      >
        Do you really want to delete config with name <b>{config.name}</b>? This cannot be undone
      </ActionConfirmationModal>
    )
  }

  return (
    <>
      {showConfirmModal && renderConfirmModal()}
      <Dropdown isUp={isUp} isRight renderButton={renderButton} elements={OPTS} onSelected={onSelected} />
    </>
  )
}
