import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'

import { useMutationOptionsWithVariables } from './use-mutation-options'

interface BookOnDemandReturnsVariables {
  terminalCode: string
  data: { merchantIds: number[] }
}

export interface BookOnDemandReturnsResponse {
  lockReject: boolean
}

const bookOnDemandReturns = (webapiClient: AxiosInstance, { terminalCode, data }: BookOnDemandReturnsVariables) =>
  webapiClient.post<BookOnDemandReturnsResponse>(`/orders/book-ondemand-returns/${terminalCode}`, data)

export const useBookOnDemandReturns = () => useMutationOptionsWithVariables(bookOnDemandReturns, getResponseData)
