import React, { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, CardContent } from '../../../components/bulma/card.styled'
import { CardHeader, CardHeaderButtonGroup, CardHeaderButtons, CardHeaderTitle } from '../../../components/card.styled'
import { TerminalOption, TerminalSelect } from '../../../components/select'
import { useStoredColumnVisibility } from '../../../hooks/use-stored-column-visibility'
import { useStoredTerminalCodes } from '../../../hooks/use-stored-terminal-codes'
import { getSegmentDetailsUrl } from '../../../lib/urls'
import { DateRange } from '../../../types/date-range'
import { isRouteSegmentDirection, RouteSegmentDirection } from '../../../webapi/types/segments'
import { columnVisibilitySegmentsKey, terminalSelectSegmentsKey } from '../../merchant-return/local-storage-keys'

import { Message, SelectContainer, StyledOverviewTable } from './segments.styled'
import { DashboardColumnVisibility, DashboardColumn } from './types'
import { ViewOptions } from './view-options'

const defaultColumnVisibility: DashboardColumnVisibility = {
  [DashboardColumn.Date]: true,
  [DashboardColumn.Segment]: true,
  [DashboardColumn.DriveTime]: true,
  [DashboardColumn.Courier]: true,
  [DashboardColumn.ScheduleDeparture]: true,
  [DashboardColumn.ActualDeparture]: true,
  [DashboardColumn.ScheduleArrival]: true,
  [DashboardColumn.ActualArrival]: true,
  [DashboardColumn.ReturnPallets]: true,
  [DashboardColumn.MixedPallets]: true,
  [DashboardColumn.LockerPallets]: true,
  [DashboardColumn.HomePallets]: true,
  [DashboardColumn.TotalNumberOfParcels]: true,
}

export const Segments = () => {
  const navigate = useNavigate()
  const [selectedTerminalCodes, setSelectedTerminalCodes] = useStoredTerminalCodes(terminalSelectSegmentsKey)
  const [selectedDateRange, setSelectedDateRange] = useState(() => ({ fromDate: new Date(), toDate: new Date() }))
  const [selectedDirection, setSelectedDirection] = useState<RouteSegmentDirection>('OUTBOUND')
  const [columnVisibility, setColumnVisibility] = useStoredColumnVisibility(
    columnVisibilitySegmentsKey,
    defaultColumnVisibility
  )

  const hasVisibleColumns = Object.values(columnVisibility).some((isVisible) => isVisible)

  const onSelectedTerminals = (selectedTerminals: TerminalOption[], dateRange?: DateRange) => {
    setSelectedTerminalCodes(selectedTerminals.map(({ code }) => code))
    if (dateRange) {
      setSelectedDateRange(dateRange)
    }
  }

  const onViewSegmentPallets = (routeId: string, sequence: number) => {
    navigate(getSegmentDetailsUrl(routeId, sequence))
  }

  const onDirectionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (isRouteSegmentDirection(event.target.value)) {
      setSelectedDirection(event.target.value)
    }
  }

  return (
    <Card>
      <CardContent>
        <CardHeader>
          <CardHeaderTitle>Segments</CardHeaderTitle>
          <CardHeaderButtons>
            <CardHeaderButtonGroup>
              <SelectContainer>
                <select onChange={onDirectionChange} value={selectedDirection}>
                  <option value={'INBOUND'}>Inbound</option>
                  <option value={'OUTBOUND'}>Outbound</option>
                  <option value={'BOTH'}>Both Inbound / Outbound</option>
                </select>
              </SelectContainer>
              <ViewOptions columns={columnVisibility} setColumns={setColumnVisibility} />
              <TerminalSelect
                showDateRangeSelection
                selectedTerminalCodes={selectedTerminalCodes}
                onChange={onSelectedTerminals}
                isMultiSelect
              />
            </CardHeaderButtonGroup>
          </CardHeaderButtons>
        </CardHeader>
        {selectedTerminalCodes.length && hasVisibleColumns ? (
          <StyledOverviewTable
            terminalCodes={selectedTerminalCodes}
            direction={selectedDirection}
            dateRange={selectedDateRange}
            columnVisibility={columnVisibility}
            onViewSegmentPallets={onViewSegmentPallets}
          />
        ) : (
          <Message isInfo>Select at least one terminal and the columns you want to display to continue.</Message>
        )}
      </CardContent>
    </Card>
  )
}
