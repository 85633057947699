import classnames from 'classnames'
import styled from 'styled-components'

interface FieldProps {
  isHorizontal?: boolean
  isStretched?: boolean
}

export const Field = styled.div.attrs(({ isHorizontal }: FieldProps) => ({
  className: classnames('field ', { 'is-horizontal': isHorizontal }),
}))<FieldProps>`
  ${({ isStretched }: FieldProps) =>
    isStretched &&
    `
    flex: 1;
  `}
`

export const FieldLabel = styled.div.attrs(() => ({ className: 'field-label' }))``

interface LabelProps {
  disabled?: boolean
}

export const Label = styled.label.attrs(() => ({ className: 'label' }))<LabelProps>`
  color: ${({ disabled }) => (disabled ? 'grey' : undefined)} !important;
`

export const FieldBody = styled.div.attrs(() => ({ className: 'field-body' }))``

export const FieldControl = styled.div.attrs(() => ({ className: 'control' }))``
