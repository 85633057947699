import React from 'react'

import { StyledMessage } from './booking.styled'

interface LockMessageProps {
  isLocked: boolean
}

export const LockMessage = ({ isLocked }: LockMessageProps) => (
  <StyledMessage isWarning={isLocked} isInfo={!isLocked}>
    After you have booked returns you need to wait a few minutes until you can book again. Booking will remain{' '}
    <strong>locked</strong> for this terminal during that time.
  </StyledMessage>
)

export const NoBookingsMessage = () => (
  <StyledMessage isInfo>No bookable returns available for this terminal.</StyledMessage>
)

export const NoTerminalSelectedMessage = () => <StyledMessage isInfo>Select a terminal to continue.</StyledMessage>

export const FailedToLoadMessage = () => <StyledMessage isDanger>Failed to load.</StyledMessage>
