import React from 'react'

import { CardHeader, CardHeaderButtonGroup, CardHeaderButtons, CardHeaderTitle } from '../../../components/card.styled'
import { TerminalOption, TerminalSelect } from '../../../components/select'

interface Props {
  filter: string
  onFilter: (value: string) => void
  isFilterDisabled: boolean
  selectedTerminalCode?: string
  onTerminalCodeSelectionChange: (value?: string) => void
}

export const ConfigurationsCardHeader = ({
  filter,
  onFilter,
  isFilterDisabled,
  selectedTerminalCode,
  onTerminalCodeSelectionChange,
}: Props) => {
  const onTerminalSelectionChange = (options: TerminalOption[]) => {
    const [option] = options
    onTerminalCodeSelectionChange(option?.code ?? undefined)
  }

  const generateSelectLabel = (selectOptions: TerminalOption[]) => {
    if (selectOptions.length > 0) {
      return `Applies to terminal: ${selectOptions[0].name}`
    }
    return 'Filter applies to terminal'
  }

  return (
    <CardHeader>
      <CardHeaderTitle>Pallet Configurations</CardHeaderTitle>
      <CardHeaderButtons>
        <CardHeaderButtonGroup>
          <input
            className="input is-small"
            type="text"
            style={{ flex: 1 }}
            placeholder="Filter names"
            value={filter}
            onChange={(e) => onFilter(e.target.value)}
            disabled={isFilterDisabled}
          />
          <TerminalSelect
            generateLabel={generateSelectLabel}
            disabled={isFilterDisabled}
            selectedTerminalCodes={selectedTerminalCode ? [selectedTerminalCode] : []}
            showClear
            onClear={() => onTerminalSelectionChange([])}
            onChange={onTerminalSelectionChange}
          />
        </CardHeaderButtonGroup>
      </CardHeaderButtons>
    </CardHeader>
  )
}
