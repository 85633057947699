import React from 'react'
import DatePicker from 'react-datepicker'

import {
  TimeInputField,
  TimePickerColumn,
  TimePickerColumns,
} from '../../../../pages/gps-tracking/positions/time-picker.styled'
import { DateRange } from '../../../../types/date-range'

interface Props {
  dateRange: DateRange
  onChange: (dateRange: DateRange) => void
}

export const DateRangePicker = ({ dateRange, onChange }: Props) => {
  const { fromDate, toDate } = dateRange
  return (
    <TimePickerColumns>
      <TimePickerColumn>
        From:
        <DatePicker
          selected={fromDate}
          onChange={(date) => onChange({ fromDate: date || new Date(), toDate })}
          dateFormat="dd/MM/yyyy"
          customInput={<TimeInputField />}
        />
      </TimePickerColumn>
      <TimePickerColumn>
        To:
        <DatePicker
          selected={toDate}
          onChange={(date) => onChange({ fromDate, toDate: date || new Date() })}
          dateFormat="dd/MM/yyyy"
          customInput={<TimeInputField />}
        />
      </TimePickerColumn>
    </TimePickerColumns>
  )
}
