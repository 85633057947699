import styled from 'styled-components'

export const StickyTableRow = styled.tr.attrs({ className: 'has-background-primary-dark' })`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;

  && {
    th {
      color: hsl(0, 0%, 96%);
      font-weight: 500;
    }
  }
`
