import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { ActionConfirmationModal } from '../../components/action-confirmation-modal/action-confirmation-modal'
import { Button } from '../../components/bulma/button.styled'
import { Dropdown, DropdownElement } from '../../components/dropdown'
import { Icon } from '../../components/select/select.styled'
import { notification } from '../../lib/notification'
import { useDeleteCollectionPlacePoint } from '../../webapi/hooks/mutations/use-delete-collection-place-point'
import { MerchantWarehouse } from '../../webapi/types/merchant-warehouses'

import { Image } from './merchant-warehouse-dropdown.styled'
export const DELETE = { name: 'Delete warehouse', code: 'DELETE' }

const OPTS = [[DELETE]]

interface Props {
  isUp?: boolean
  warehouse: MerchantWarehouse
  onDelete: () => void
}

export const MerchantWarehouseDropdown = ({ isUp, warehouse, onDelete }: Props) => {
  const queryClient = useQueryClient()
  const invalidateWarehouseQueries = () => {
    queryClient.invalidateQueries(['collection-place-points'])
  }
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const deleteWarehouse = useMutation({
    ...useDeleteCollectionPlacePoint(),
    onSuccess: () => {
      onDelete()
      notification.success('Deleted merchant warehouse')
      invalidateWarehouseQueries()
    },
    onError: () => {
      notification.error('Failed to delete merchant warehouse')
    },
  })

  const renderButton = (toggle: () => void) => (
    <Button isSmall aria-haspopup="true" aria-controls="dropdown-menu" onClick={() => toggle()}>
      <Image>
        <Icon icon={faEllipsisV} />
      </Image>
    </Button>
  )
  const onSelected = (value: DropdownElement) => {
    if (value.code === DELETE.code) {
      setShowConfirmModal(true)
    }
  }

  const onConfirmDelete = () => {
    deleteWarehouse.mutate({ warehouse })
    setShowConfirmModal(false)
  }

  const renderConfirmModal = () => {
    return (
      <ActionConfirmationModal
        variant="danger"
        onClose={() => setShowConfirmModal(false)}
        onConfirm={onConfirmDelete}
        confirmButtonText="Delete"
        isConfirming={deleteWarehouse.isLoading}
      >
        Do you really want to delete merchant warehouse with name <b>{warehouse.name}</b>? This cannot be undone
      </ActionConfirmationModal>
    )
  }

  return (
    <>
      {showConfirmModal && renderConfirmModal()}
      <Dropdown isUp={isUp} isRight renderButton={renderButton} elements={OPTS} onSelected={onSelected} />
    </>
  )
}
