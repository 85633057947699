import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { SquareButton } from '../../../components/bulma/button.styled'
import { CardFooter } from '../../../components/bulma/card.styled'
import { CardHeader, CardHeaderTitle, ScrollableCard, ScrollableCardContent } from '../../../components/card.styled'
import {
  closedContext,
  openMultiContext,
  TerminalOption,
  TerminalSelectorContext,
  TerminalSideBox,
} from '../../../components/select/select-side-box/terminal-side-box'
import { notification } from '../../../lib/notification'
import { useCreateTerminalGroup } from '../../../webapi/hooks/mutations/use-create-terminal-group'
import { useSaveTerminalGroup } from '../../../webapi/hooks/mutations/use-save-terminal-group'
import { TerminalLocation } from '../../../webapi/hooks/queries/use-get-terminal-locations'
import { TerminalGroup } from '../../../webapi/types/terminal-groups'
import { determineDefaultTab, toSelectOptions } from '../../pallets/configurations/configuration/configuration-utils'

import { GroupField } from './field'

type TerminalMember = {
  name: string
  code: string
}

interface GroupProps {
  group: TerminalGroup
  terminalLocations: Map<string, TerminalLocation>
  onClose: () => void
}

export const Group = ({ group, terminalLocations, onClose }: GroupProps) => {
  const query = useQueryClient()
  const [editing, setEditing] = useState(!group.identifier)
  const [terminalSelector, setTerminalSelector] = useState<TerminalSelectorContext>(closedContext())
  const [name, setName] = useState(group.name)
  const [terminalCodes, setTerminalCodes] = useState<string[]>(group.terminals)
  const toListItem = (code: string): TerminalMember => ({
    name: terminalLocations.get(code)?.name || code,
    code,
  })
  const toListItems = (codes: string[]): TerminalMember[] => codes.map(toListItem)
  const onAddTerminal = () => {
    const onClear = () => setTerminalCodes([])
    const onChange = (options: TerminalOption[]) => setTerminalCodes(options.map((opt) => opt.code))
    const opts = toSelectOptions(terminalCodes, terminalLocations)
    setTerminalSelector(openMultiContext(opts, onChange, onClear))
  }

  const setGroupState = useCallback((terminalGroup: TerminalGroup) => {
    setName(terminalGroup.name)
    setTerminalCodes(terminalGroup.terminals)
  }, [])

  useEffect(() => {
    setEditing(!group.identifier)
    setGroupState(group)
  }, [group, setGroupState])

  const createGroup = useMutation({
    ...useCreateTerminalGroup(),
    onSuccess: () => {
      notification.success('Created terminal group')
      query.invalidateQueries(['terminals', 'groups'])
    },
    onError: () => {
      notification.error('Failed to create terminal group')
    },
  })

  const saveGroup = useMutation({
    ...useSaveTerminalGroup(),
    onSuccess: () => {
      notification.success('Saved terminal group')
      query.invalidateQueries(['terminals', 'groups'])
    },
    onError: () => {
      notification.error('Failed to save terminal group')
    },
  })

  const onDiscard = () => {
    if (!group.identifier) {
      onClose()
    } else {
      setEditing(false)
    }
  }

  const onPersist = () => {
    const data = {
      identifier: group.identifier,
      name: name,
      terminals: terminalCodes,
    }
    if (group.identifier) {
      saveGroup.mutate({ identifier: group.identifier, body: data })
    } else {
      createGroup.mutate({ body: data })
    }
  }

  const renderTerminals = (terminals: string[]) =>
    toListItems(terminals).map((member) => <li key={member.code}>{member.name}</li>)

  return (
    <>
      <TerminalSideBox
        selectedTerminalCodes={terminalSelector.terminals.map((element) => element.code)}
        show={terminalSelector.visible}
        isMultiSelect={terminalSelector.isMulti}
        showClear
        determineDefaultTab={determineDefaultTab}
        onCancel={() => setTerminalSelector(closedContext())}
        onChange={(options) => {
          terminalSelector.onChange(options)
          setTerminalSelector(closedContext())
        }}
        onClear={() => {
          terminalSelector.onClear()
          setTerminalSelector(closedContext())
        }}
      />
      <ScrollableCard>
        <ScrollableCardContent>
          <CardHeader>
            <CardHeaderTitle>Group</CardHeaderTitle>
          </CardHeader>
          <div style={{ height: 15 }}></div>
          <GroupField label="Name">
            {editing ? (
              <input className="input is-small" type="text" value={name} onChange={(e) => setName(e.target.value)} />
            ) : (
              <span>{group.name}</span>
            )}
          </GroupField>
          <GroupField label="Terminals">
            <ul>{editing ? renderTerminals(terminalCodes) : renderTerminals(group.terminals)}</ul>
          </GroupField>
        </ScrollableCardContent>
        {editing ? (
          <EditFooter
            onAdd={onAddTerminal}
            onDiscard={onDiscard}
            persistVerb={!group.identifier ? 'Create' : 'Save'}
            disablePersist={name.trim() === ''}
            onPersist={onPersist}
          />
        ) : (
          <ViewFooter onEdit={() => setEditing(true)} onClose={onClose} />
        )}
      </ScrollableCard>
    </>
  )
}
interface EditFooterProps {
  onAdd: () => void
  onDiscard: () => void
  persistVerb: string
  disablePersist: boolean
  onPersist: () => void
}
const EditFooter = ({ onAdd, onDiscard, persistVerb, onPersist, disablePersist }: EditFooterProps) => {
  return (
    <CardFooter>
      <SquareButton isLight isRounded={false} onClick={onAdd}>
        Set Terminals
      </SquareButton>
      <div style={{ flex: 1 }}></div>
      <SquareButton isLight isRounded={false} onClick={onDiscard}>
        Discard
      </SquareButton>
      <SquareButton isPrimary isRounded={false} onClick={onPersist} disabled={disablePersist}>
        {persistVerb}
      </SquareButton>
    </CardFooter>
  )
}

interface ViewFooterProps {
  onEdit: () => void
  onClose: () => void
}
const ViewFooter = ({ onEdit, onClose }: ViewFooterProps) => {
  return (
    <CardFooter>
      <div style={{ flex: 1 }}></div>
      <SquareButton isLight isRounded={false} onClick={onClose}>
        Close
      </SquareButton>
      <SquareButton isInfo isRounded={false} onClick={onEdit}>
        Edit
      </SquareButton>
    </CardFooter>
  )
}
