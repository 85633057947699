import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Card, CardContent } from '../../../components/bulma/card.styled'
import { CardLoader } from '../../../components/card-loader.styled'
import { CardHeader, CardHeaderButtonGroup, CardHeaderButtons, CardHeaderTitle } from '../../../components/card.styled'
import { TerminalOption, TerminalSelect, MerchantSelect, MerchantOption } from '../../../components/select'
import { getStoredMerchantIds, getStoredTerminalCodes, setItem } from '../../../lib/local-storage'
import { getReturnPhaseUrl } from '../../../lib/urls'
import { useMerchantDropOffOverview } from '../../../webapi/hooks/mutations/use-merchant-drop-off-overview'
import { useGetTerminals } from '../../../webapi/hooks/queries/use-get-terminals'
import { getTerminalNames } from '../../../webapi/terminals'
import { merchantSelectDashboardKey, terminalSelectDashboardKey } from '../local-storage-keys'

import { TerminalPhase } from './dashboard-table'
import { Message, StyledOverviewTable } from './dashboard.styled'

export const Dashboard = () => {
  const navigate = useNavigate()

  const [selectedMerchantIds, setSelectedMerchantIds] = useState(() => getStoredMerchantIds(merchantSelectDashboardKey))
  const [selectedTerminalCodes, setSelectedTerminalCodes] = useState(() =>
    getStoredTerminalCodes(terminalSelectDashboardKey)
  )

  const {
    data: terminals,
    isSuccess: isTerminalsSuccess,
    isError: isTerminalsError,
    isLoading: isTerminalsLoading,
  } = useQuery(useGetTerminals())

  const {
    mutate: mutateOverview,
    data: merchantOverview,
    isSuccess: isOverviewSuccess,
    isError: isOverviewError,
    isLoading: isOverviewLoading,
  } = useMutation(useMerchantDropOffOverview())

  const hasSelectedTerminals = selectedTerminalCodes.length > 0
  const terminalNames = useMemo(() => getTerminalNames(terminals), [terminals])

  const isLoading = isTerminalsLoading || isOverviewLoading
  const isError = isTerminalsError || isOverviewError

  const showError = !isLoading && isError
  const showSelectTerminal = !isLoading && !isError && !hasSelectedTerminals
  const showResults = isTerminalsSuccess && isOverviewSuccess && hasSelectedTerminals

  useEffect(() => {
    if (selectedTerminalCodes.length) {
      mutateOverview({
        terminals: selectedTerminalCodes,
        merchantIds: selectedMerchantIds,
      })
    }
  }, [mutateOverview, selectedTerminalCodes, selectedMerchantIds])

  const onSelectPhase = ({ terminalCode, phase }: TerminalPhase) => navigate(getReturnPhaseUrl(phase, terminalCode))

  const onSelectedTerminals = (selectedTerminals: TerminalOption[]) => {
    const terminalCodes = selectedTerminals.map(({ code }) => code)
    setItem(terminalSelectDashboardKey, terminalCodes)
    setSelectedTerminalCodes(terminalCodes)
  }

  const onSelectedMerchants = (merchantOptions: MerchantOption[]) => {
    const merchantIds = merchantOptions.map(({ id }) => id)
    setItem(merchantSelectDashboardKey, merchantIds)
    setSelectedMerchantIds(merchantIds)
  }

  return (
    <Card>
      <CardContent>
        <CardHeader>
          <CardHeaderTitle>Dashboard</CardHeaderTitle>
          <CardHeaderButtons>
            <CardHeaderButtonGroup>
              <TerminalSelect
                selectedTerminalCodes={selectedTerminalCodes}
                onChange={onSelectedTerminals}
                isMultiSelect
              />
              <MerchantSelect onChange={onSelectedMerchants} selectedMerchantIds={selectedMerchantIds} />
            </CardHeaderButtonGroup>
          </CardHeaderButtons>
        </CardHeader>
        {showSelectTerminal && <Message isInfo>Select atleast one terminal to continue.</Message>}
        {showError && <Message isDanger>Failed to load.</Message>}
        {isLoading && <CardLoader />}
        {showResults &&
          (merchantOverview?.terminals.length ? (
            <StyledOverviewTable onSelectPhase={onSelectPhase} terminalNames={terminalNames} data={merchantOverview} />
          ) : (
            <Message>No results found.</Message>
          ))}
      </CardContent>
    </Card>
  )
}
