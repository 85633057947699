import { AxiosInstance } from 'axios'

import { useMutationOptionsWithVariables } from './use-mutation-options'

interface GpsTrackingDeviceVariables {
  imei: string | null
  protocol: string
  simCardId: string | null
}

interface UpdateGpsTrackingDeviceVariables {
  data: GpsTrackingDeviceVariables
  deviceId: string
}

const updateGpsTrackingDevice = (webapiClient: AxiosInstance, { data, deviceId }: UpdateGpsTrackingDeviceVariables) =>
  webapiClient.put(`/tracking-devices/devices/${deviceId}`, data)

export const useUpdateGpsTrackingDevice = () => useMutationOptionsWithVariables(updateGpsTrackingDevice)
