import { AxiosInstance } from 'axios'
import { format } from 'date-fns'

import { getResponseData } from '../../../lib/get-response-data'
import { RouteSegment, RouteSegmentDirection } from '../../types/segments'

import { useQueryOptionsWithVariables } from './use-query-options'

interface RouteSegmentSearchResultsVariables {
  terminalCodes: string[]
  fromDate: Date
  toDate: Date
  direction: RouteSegmentDirection
}

export type RouteSegmentSearchResultsResponse = { segments: RouteSegment[] }

export const getRouteSegmentSearchResultsQueryKey = ['route-segment', 'search']

const getRouteSegmentSearchResults = (
  webapiClient: AxiosInstance,
  { fromDate, toDate, ...rest }: RouteSegmentSearchResultsVariables
) =>
  webapiClient.post<RouteSegmentSearchResultsResponse>('/freightman/segment-search', {
    ...rest,
    fromDate: format(fromDate, 'yyyy-MM-dd'),
    toDate: format(toDate, 'yyyy-MM-dd'),
  })

export const useGetRouteSegmentSearchResults = (variables: RouteSegmentSearchResultsVariables) =>
  useQueryOptionsWithVariables(
    [...getRouteSegmentSearchResultsQueryKey, variables],
    getRouteSegmentSearchResults,
    variables,
    getResponseData
  )
