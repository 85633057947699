import classNames from 'classnames'
import styled from 'styled-components'

interface CsvContainerProps {
  active: boolean
}
export const CsvContainer = styled.div.attrs(({ active }: CsvContainerProps) => ({
  className: classNames({ dropdown: true, 'is-active': active }),
}))<CsvContainerProps>`
  flex: 1;
`

interface CsvTriggerProps {
  readonly: boolean
}
export const CsvTrigger = styled.div.attrs(({ readonly }: CsvTriggerProps) => ({
  className: classNames('control', { 'has-icons-right': !readonly }),
}))<CsvTriggerProps>`
  width: 100%;
`

export const Menu = styled.div.attrs({ className: 'dropdown-menu', role: 'menu' })`
  width: 100%;
`

export const MenuContent = styled.div.attrs({ className: 'dropdown-content' })``

export const ValueItem = styled.a.attrs({ href: '#', className: 'dropdown-item' })`
  display: flex !important;
  justify-content: space-between;
  padding-right: 10px !important;
`

export const ValueItemLabel = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
`

export const AddValueContainer = styled.div.attrs({ className: 'field has-addons' })`
  padding: 10px;
  padding-bottom: 0px;
`
export const AddValueInputContainer = styled.div.attrs({ className: 'control' })`
  flex: 1;
`

export const AddValueButtonContainer = styled.div.attrs({ className: 'control' })``

export const AddValueButton = styled.button.attrs({ className: 'button is-info is-small' })``

export const AddValueInput = styled.input.attrs({ className: 'input is-small', type: 'text' })``
