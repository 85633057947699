import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import FileSaver from 'file-saver'
import React, { FC } from 'react'
import { useMutation } from 'react-query'

import { Button } from '../../../components/bulma/button.styled'
import { Dropdown, DropdownElement } from '../../../components/dropdown'
import { Icon } from '../../../components/select/select.styled'
import { notification } from '../../../lib/notification'
import {
  useDownloadCandidatesByTerminalAndMerchants,
  useDownloadIdleParcelsByTerminal,
} from '../../../webapi/hooks/mutations/use-download-candidates-by-terminal-and-merchants'
import { ConsignmentsBookingCandidate } from '../../../webapi/hooks/queries/use-get-consignments-candidates'

type Props = Pick<ConsignmentsBookingCandidate, 'terminalCode'>

enum ExportType {
  CANDIDATES = 'CANDIDATES',
  IDLE = 'IDLE',
}

export const ExportActionButton: FC<Props> = ({ terminalCode }) => {
  const save = (filename: string) => (data: Blob) => {
    if (data.size === 0) notification.warning('There are no candidates to download')
    else {
      try {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        FileSaver.saveAs(blob, filename)
      } catch (e) {
        notification.error('Error downloading candidates')
      }
    }
  }

  const { mutate: mutateDownloadCandidatesByTerminalAndMerchants, isLoading: isLoadingCandidates } = useMutation({
    ...useDownloadCandidatesByTerminalAndMerchants(),
    onSuccess: save(`${terminalCode}-candidates-${new Date().toISOString()}.xlsx`),
  })

  const { mutate: mutateDownloadIdleParcelsByTerminal, isLoading: isLoadingIdle } = useMutation({
    ...useDownloadIdleParcelsByTerminal(),
    onSuccess: save(`${terminalCode}-idle-parcels-${new Date().toISOString()}.xlsx`),
  })

  const isLoading = isLoadingCandidates || isLoadingIdle
  const renderButton = (toggle: () => void) => (
    <Button
      isSmall
      isLoading={isLoading}
      name="export-consignments-dropdown-button"
      aria-haspopup="true"
      aria-controls="dropdown-menu"
      style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      onClick={() => toggle()}
    >
      <span>Export</span>
      <span className="icon is-small">
        <Icon icon={faAngleDown} />
      </span>
    </Button>
  )
  const elements = [
    {
      name: 'Candidates',
      code: ExportType.CANDIDATES,
    },
    {
      name: 'Idle Parcels',
      code: ExportType.IDLE,
    },
  ]

  const onSelected = (element: DropdownElement) => {
    if (element.code === ExportType.CANDIDATES) {
      mutateDownloadCandidatesByTerminalAndMerchants({ terminalCode })
    } else if (element.code === ExportType.IDLE) {
      mutateDownloadIdleParcelsByTerminal({ terminalCode })
    }
  }
  return <Dropdown renderButton={renderButton} elements={[elements]} onSelected={onSelected} />
}
