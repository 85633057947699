import axios from 'axios'
import React, { ReactNode, useMemo } from 'react'

import { WebapiClientContextProvider } from '../contexts/webapi-client-context'
import { useEnvironmentVariable } from '../hooks/use-environment-variable'
import { ReduxStore } from '../state/store'

interface Props {
  children: ReactNode
  store: ReduxStore
}

export const WebapiClientProvider = ({ children, store }: Props) => {
  const webapiUrl = useEnvironmentVariable('WEBAPI_URL')
  const webapiClient = useMemo(() => {
    const instance = axios.create({ baseURL: webapiUrl })
    instance.interceptors.request.use(async (config) => {
      const updatedConfig = { ...config, headers: { ...config.headers } }
      updatedConfig.headers.authorization = `Bearer ${store.getState().webapiJWT}`
      return updatedConfig
    })
    return instance
  }, [store, webapiUrl])
  return <WebapiClientContextProvider value={webapiClient}>{children}</WebapiClientContextProvider>
}
