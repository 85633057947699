import { AxiosInstance } from 'axios'

import { useMutationOptionsWithVariables } from './use-mutation-options'

interface SetTerminalServicedByVariables {
  terminalCode: string
  servicedByTerminalCode: string | null
}

export interface BookOnDemandReturnsResponse {
  lockReject: boolean
}

const setTerminalServicedBy = (
  webapiClient: AxiosInstance,
  { terminalCode, servicedByTerminalCode }: SetTerminalServicedByVariables
) =>
  webapiClient.post<void>(`/freightman/locations/terminals/${terminalCode}/serviced-by`, {
    terminalCode: servicedByTerminalCode,
  })

export const useSetTerminalServicedBy = () => useMutationOptionsWithVariables(setTerminalServicedBy)
