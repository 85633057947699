import { AxiosInstance } from 'axios'

import { TerminalCountryCode } from '../../../types/terminal-country-codes'

import { useMutationOptionsWithVariables } from './use-mutation-options'

type PostCollectionPlacePoint = {
  body: {
    name: string
    street: string
    postalCode: string
    city: string
    countryCode: TerminalCountryCode
    latitude: number
    longitude: number
    collectionPointIds: number[]
    placeIds: string[]
  }
}

const createCollectionPlacePoint = (webapiClient: AxiosInstance, { body }: PostCollectionPlacePoint) =>
  webapiClient.post<void>(`/freightman/collection-place-points`, body)

export const useCreateCollectionPlacePoints = () => useMutationOptionsWithVariables(createCollectionPlacePoint)
