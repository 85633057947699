import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import styled from 'styled-components'

import { SimpleMessage } from '../../../components/simple-message'

import { BookingTable } from './booking-table'

export const StyledBookingTable = styled(BookingTable).attrs({ className: 'mt-5' })``

export const StyledMessage = styled(SimpleMessage).attrs({ className: classnames('mt-4') })``

export const StyledLockIcon = styled(FontAwesomeIcon).attrs({ className: 'ml-3' })``
