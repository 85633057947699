import { isNumberArray, isStringArray, isString, validate } from '../validation'

import { getItem } from './local-storage'

export const getStoredMerchantIds = (key: string) => validate(getItem(key), isNumberArray) || []

export const getStoredTerminalCodes = (key: string) => validate(getItem(key), isStringArray) || []

export const getStoredTerminalCode = (key: string) => validate(getItem(key), isString) ?? undefined

export const getStoredVisibleColumns = (key: string) => validate(getItem(key), isStringArray)
