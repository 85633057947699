import React, { ChangeEvent, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'

import { List } from '../../../components/bulma/list.styled'
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderButtons,
  CardHeaderButtonGroup,
  ScrollableCard,
  ScrollableCardContent,
} from '../../../components/card.styled'
import {
  TerminalOption,
  TerminalSideBox,
} from '../../../components/select/select-side-box/terminal-side-box/terminal-side-box'
import { notification } from '../../../lib/notification'
import { useSetTerminalServicedBy } from '../../../webapi/hooks/mutations/use-set-terminal-serviced-by'
import { TerminalRelation, useGetTerminalRelations } from '../../../webapi/hooks/queries/use-get-terminal-relations'

import { TerminalRelationsListItem } from './terminal-relations-list-item'
import {
  determineSelectorDefaultTab,
  filterRelations,
  hiddenSelector,
  scrollIntoView,
  TerminalSelector,
  visibleSelector,
} from './terminal-relations-utils'
import { FilterInput, Root, StyledSimpleMessage } from './terminal-relations.styled'

export const TerminalRelations = () => {
  const [terminalSelector, setTerminalSelector] = useState<TerminalSelector>(hiddenSelector())

  const [nameFilter, setNameFilter] = useState('')
  const onFilter = (e: ChangeEvent<HTMLInputElement>) => setNameFilter(e.target.value)

  const terminalRelations = useQuery(useGetTerminalRelations())
  const filteredRelations = useMemo(
    () => filterRelations(terminalRelations.data?.relations, nameFilter),
    [nameFilter, terminalRelations.data?.relations]
  )

  const setTerminalServicedBy = useMutation({
    ...useSetTerminalServicedBy(),
    onError: () => {
      notification.error('Failed to update serviced by for terminal')
    },
    onSuccess: () => {
      terminalRelations.refetch()
    },
  })

  const onTerminalSelectorChange = ([option]: TerminalOption[]) => {
    if (terminalSelector.terminalCode) {
      setTerminalServicedBy.mutate({
        terminalCode: terminalSelector.terminalCode,
        servicedByTerminalCode: option.code,
      })
    }
    setTerminalSelector(hiddenSelector())
  }

  const clearServicedBy = (terminalCode: string) =>
    setTerminalServicedBy.mutate({ terminalCode, servicedByTerminalCode: null })

  const onTerminalSelectorCancel = () => setTerminalSelector(hiddenSelector())

  const openTerminalSelector = ({ terminal, servicedBy }: TerminalRelation) =>
    setTerminalSelector(visibleSelector(terminal.code, servicedBy?.code ?? null))

  const listElements = useRef(new Map<string, HTMLDivElement>())
  const setListElement = (terminalCode: string, listElement: HTMLDivElement | null) => {
    if (listElement) {
      listElements.current.set(terminalCode, listElement)
    } else {
      listElements.current.delete(terminalCode)
    }
  }

  const scrollToTerminal = (terminalCode: string) => scrollIntoView(listElements.current.get(terminalCode))

  return (
    <Root>
      <TerminalSideBox
        selectedTerminalCodes={terminalSelector.servicedByTerminalCode}
        excludedTerminalCodes={terminalSelector.excludedTerminalCodes}
        show={terminalSelector.visible}
        determineDefaultTab={determineSelectorDefaultTab}
        onCancel={onTerminalSelectorCancel}
        onChange={onTerminalSelectorChange}
      />
      <ScrollableCard>
        <ScrollableCardContent>
          <CardHeader>
            <CardHeaderTitle>Terminal Relations</CardHeaderTitle>
            <CardHeaderButtons>
              <CardHeaderButtonGroup>
                <FilterInput value={nameFilter} onChange={onFilter} />
              </CardHeaderButtonGroup>
            </CardHeaderButtons>
          </CardHeader>
          {terminalRelations.isError ? (
            <StyledSimpleMessage isDanger>Failed to load terminal relations</StyledSimpleMessage>
          ) : (
            <List>
              {filteredRelations.map((relation, index) => (
                <TerminalRelationsListItem
                  key={relation.terminal.code}
                  relation={relation}
                  scrollToTerminal={scrollToTerminal}
                  openTerminalSelector={openTerminalSelector}
                  clearServicedBy={clearServicedBy}
                  setRef={(listElement) => setListElement(relation.terminal.code, listElement)}
                  isLast={index === filteredRelations.length - 1}
                />
              ))}
            </List>
          )}
        </ScrollableCardContent>
      </ScrollableCard>
    </Root>
  )
}
