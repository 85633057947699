import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'

import { useQueryOptions } from './use-query-options'

export type GetProtocolResponse = {
  protocols: string[]
}

export const getDeviceProtocolQueryKey = ['tracking-devices', 'protocols']

const getProtocols = (webapiClient: AxiosInstance) =>
  webapiClient.get<GetProtocolResponse>('/tracking-devices/protocols')

export const useGetDeviceProtocols = () => useQueryOptions(getDeviceProtocolQueryKey, getProtocols, getResponseData)
