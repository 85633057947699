import { faSliders } from '@fortawesome/free-solid-svg-icons'
import React, { memo, useRef, useState } from 'react'

import { CardHeaderButton } from '../card.styled'

import { MerchantOption, MerchantSideBox } from './select-side-box/merchant-side-box/merchant-side-box'
import { Icon } from './select.styled'

const getLabel = (count?: number) => (count ? `Merchants (${count})` : 'Merchants')

interface Props {
  selectedMerchantIds: number[]
  onChange: (options: MerchantOption[]) => void
}

export const MerchantSelect = memo(({ onChange, selectedMerchantIds }: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<MerchantOption[]>([])
  const [isSelectorVisible, setIsSelectorVisible] = useState(false)
  const initialSelection = useRef(selectedMerchantIds)

  const onCancel = () => setIsSelectorVisible(false)
  const onSelectionChange = (selectedOptions: MerchantOption[]) => {
    setIsSelectorVisible(false)
    setSelectedOptions(selectedOptions)
    onChange(selectedOptions)
  }

  return (
    <>
      <CardHeaderButton onClick={() => setIsSelectorVisible(true)}>
        <Icon icon={faSliders} />
        {getLabel(selectedOptions.length)}
      </CardHeaderButton>
      <MerchantSideBox
        show={isSelectorVisible}
        onLoad={setSelectedOptions}
        onCancel={onCancel}
        onChange={onSelectionChange}
        initialSelection={initialSelection.current}
      />
    </>
  )
})
