import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const Icon = styled(FontAwesomeIcon).attrs({ className: '' })`
  svg {
    margin-left: 0px !important;
  }

  margin-right: 0.5rem;
`
