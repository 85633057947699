import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

import { TerminalCountryCode, terminalCountryCodes } from '../../types/terminal-country-codes'
import { Button } from '../bulma/button.styled'
import { Icon } from '../select/select.styled'

import { Dropdown, DropdownElement } from './dropdown'
import { MultiSelectDropDown } from './multi-select-dropdown'

type Opt = { name: string; code: TerminalCountryCode }

export const SWEDEN: Opt = { name: 'Sweden', code: 'SE' }
export const DENMARK: Opt = { name: 'Denmark', code: 'DK' }
export const FINLAND: Opt = { name: 'Finland', code: 'FI' }
export const NETHERLANDS: Opt = { name: 'Netherlands', code: 'NL' }
export const BELGIUM: Opt = { name: 'Belgium', code: 'BE' }
export const GERMANY: Opt = { name: 'Germany', code: 'DE' }
export const NORWAY: Opt = { name: 'Norway', code: 'NO' }

const COUNTRIES: Opt[] = [SWEDEN, DENMARK, NORWAY, FINLAND, NETHERLANDS, BELGIUM, GERMANY]
const NA = { name: 'Not Applicable', code: 'NA' }
interface CountryDropdownProps {
  selected: TerminalCountryCode | null
  disabled?: boolean
  readonly?: boolean
  onChange: (code: TerminalCountryCode) => void
}
export const CountryDropdown = (props: CountryDropdownProps) => {
  const { disabled, selected, readonly, onChange } = props
  const item = COUNTRIES.find((country) => country.code === selected)
  if (readonly) {
    return <input className="input is-small" readOnly={true} value={item && item.name}></input>
  }
  const renderButton = (toggle: () => void) => (
    <Button
      isSmall
      aria-haspopup="true"
      aria-controls="dropdown-menu"
      style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      onClick={() => toggle()}
      disabled={disabled}
    >
      <span>{item && item.name}</span>
      <span className="icon is-small">
        <Icon icon={faAngleDown} />
      </span>
    </Button>
  )
  return (
    <Dropdown
      renderButton={renderButton}
      elements={[COUNTRIES]}
      onSelected={(newlySelected) => {
        const selected = COUNTRIES.find((element) => element.code === newlySelected.code)
        if (selected) {
          onChange(selected.code)
        }
      }}
      isStretched
    />
  )
}

interface MultiCountryDropdownProps {
  selected: TerminalCountryCode[]
  onSelected: (countries: TerminalCountryCode[]) => void
  allowNa?: boolean
  isStretched?: boolean
  isMenuStretched?: boolean
  disabled?: boolean
}

export const MultiCountryDropdown = ({
  selected,
  onSelected,
  isStretched,
  isMenuStretched,
  allowNa,
  disabled,
}: MultiCountryDropdownProps) => {
  const options: DropdownElement[] = allowNa ? [...COUNTRIES, NA] : COUNTRIES
  const selectedOptions = options.filter((opt) => selected.some((elem) => elem === opt.code))
  const renderButton = (toggle: () => void) => (
    <Button
      isSmall
      aria-haspopup="true"
      aria-controls="dropdown-menu"
      style={{ minWidth: isStretched ? '100%' : 120, display: 'flex', justifyContent: 'space-between' }}
      onClick={() => toggle()}
      disabled={disabled}
    >
      <span>{selectedOptions.map((opt) => opt.name).join(', ')}</span>
      <span className="icon is-small">
        <Icon icon={faAngleDown} />
      </span>
    </Button>
  )
  return (
    <MultiSelectDropDown
      renderButton={renderButton}
      options={options}
      selected={selectedOptions}
      onClear={() => onSelected([])}
      onSelected={(newlySelected) => {
        const selectedCodes = terminalCountryCodes.filter((code) => newlySelected.some((opt) => opt.code === code))
        onSelected(selectedCodes)
      }}
      isStretched={isStretched}
      isMenuStretched={isMenuStretched}
    />
  )
}
