import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''

const webapiJWTSlice = createSlice({
  name: 'webapiJWT',
  initialState,
  reducers: {
    setWebapiJWT: (_state, { payload }: PayloadAction<string>) => payload,
    unsetWebapiJWT: () => '',
  },
})

export const { setWebapiJWT, unsetWebapiJWT } = webapiJWTSlice.actions
export const webapiJWT = webapiJWTSlice.reducer
