import React from 'react'
import { Routes as RoutesContainer, Route } from 'react-router-dom'

import { routeList } from './route-list'

export const Routes = () => (
  <RoutesContainer>
    {routeList.map(({ path, element }) => (
      <Route key={path} path={path} element={element} />
    ))}
  </RoutesContainer>
)
