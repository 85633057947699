import React from 'react'

import { SimpleMessage } from '../../../components/simple-message'

export const ErrorMessage = () => <SimpleMessage isDanger>Failed to save device.</SimpleMessage>

export const SuccessMessage = () => <SimpleMessage isSuccess>Successfully added device.</SimpleMessage>

export const ErrorEditMessage = () => <SimpleMessage isDanger>Failed to update device.</SimpleMessage>

export const SuccessEditMessage = () => <SimpleMessage isSuccess>Successfully updated.</SimpleMessage>

export const FailedToLoadPageMessage = () => <SimpleMessage isDanger>Failed to load page.</SimpleMessage>
