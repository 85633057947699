import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'
import { PalletConfiguration } from '../../types/pallet-configuration'

import { useMutationOptionsWithVariables } from './use-mutation-options'

interface CreatePalletConfigurationVariables {
  config: PalletConfiguration
}

export type CreatePalletConfigurationResponse = {
  config: PalletConfiguration
}

const createPalletConfiguration = (webapiClient: AxiosInstance, { config }: CreatePalletConfigurationVariables) =>
  webapiClient.post<CreatePalletConfigurationResponse>('/freightman/pallet-configs', config)

export const useCreatePalletConfiguration = () =>
  useMutationOptionsWithVariables(createPalletConfiguration, getResponseData)
