import { Expression, StyleFunction } from 'mapbox-gl'

export const heatmap = (id: string, coordinates: number[][]) => ({
  id,
  type: 'heatmap' as const,
  source: {
    type: 'geojson' as const,
    data: {
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'LineString' as const,
        coordinates,
      },
    },
  },
  paint: {
    'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 8, 15] as
      | number
      | StyleFunction
      | Expression
      | undefined,
  },
})
