import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-end;
  padding: 1rem;
`

export const ModalIcon = styled(FontAwesomeIcon)`
  font-size: 3.5rem;
  align-self: center;
`

export const ModalTitle = styled.p.attrs(() => ({ className: 'title is-4 has-text-centered mt-5 ml-5 mr-5 mb-4' }))`
  align-self: center;
`

export const ModalBody = styled.div.attrs(() => ({ className: 'subtitle is-5  mt-2' }))``

export const CancelButton = styled.button.attrs(() => ({ className: 'button is-light' }))``

export const Buttons = styled.div.attrs(() => ({ className: 'buttons' }))``

export const List = styled.div.attrs(() => ({ className: 'mt-4' }))`
  font-size: 1rem;
  ul {
    margin-left: 2.5rem;
    margin-top: 1rem;
    list-style: disc;
    li {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
  }
`
