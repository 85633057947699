import { TerminalCountryCode } from '../../types/terminal-country-codes'

export const enum DestinationType {
  HOME = 'HOME',
  LOCKER = 'LOCKER',
  MERCHANT = 'MERCHANT',
}

export const enum EjectionTerminalRelation {
  TO_TERMINAL = 'TO_TERMINAL',
  IN_CATCHMENT = 'IN_CATCHMENT',
  IN_TERMINAL_GROUP = 'IN_TERMINAL_GROUP',
}

export const enum BottomLeftWidget {
  FINALIZED_BOOKED = 'FINALIZED_BOOKED',
  INTERNATIONAL = 'INTERNATIONAL',
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
}

export const enum LocationType {
  TERMINAL = 'TERMINAL',
  MERCHANT_WAREHOUSE = 'MERCHANT_WAREHOUSE',
}

export interface EjectionTerminal {
  terminalCode: string | null
  terminalGroupId: string | null
  relation: EjectionTerminalRelation
}

export interface ReturnsToMerchantWarehouse {
  locationId: string
}

export interface PalletConfigProperties {
  destinationTypes: Array<DestinationType>
  ejectionCountries: TerminalCountryCode[]
  ejectionTerminal: EjectionTerminal | null
  returnsToMerchantWarehouse: ReturnsToMerchantWarehouse | null
}

export interface PalletConfiguration {
  appliesTo: {
    terminalCodes: string[]
  }
  code: string
  destination: string
  identifier?: string
  name: string
  properties: PalletConfigProperties
  bottomLeftWidget: BottomLeftWidget
}
