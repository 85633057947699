import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { Card } from '../../../components/bulma/card.styled'
import { CardLoader } from '../../../components/card-loader.styled'
import { CardHeader, CardHeaderTitle } from '../../../components/card.styled'
import { formatTime } from '../../../lib/date-and-time'
import { useGetSegmentsForRouteResults } from '../../../webapi/hooks/queries/use-get-segments-for-route'
import { useGetTerminals } from '../../../webapi/hooks/queries/use-get-terminals'
import { getTerminalNames } from '../../../webapi/terminals'
import { RouteSegment } from '../../../webapi/types/segments'

import { PalletsTable } from './pallets-table'
import { Message } from './segments.styled'

type SegmentDetailsUrlParameters = {
  routeId?: string
  sequence?: string
}

export const SegmentDetails = () => {
  const { data: terminals, isLoading: isTerminalsLoading } = useQuery(useGetTerminals())
  const terminalNames = getTerminalNames(terminals)
  const params = useParams<SegmentDetailsUrlParameters>()
  const sequence = params.sequence
  const {
    data: segmentsResponse,
    isLoading: isSegmentsLoading,
    isError: isSegmentsError,
  } = useQuery(useGetSegmentsForRouteResults({ routeId: params.routeId }))

  if (isSegmentsLoading || isTerminalsLoading) {
    return <CardLoader />
  }
  if (isSegmentsError) {
    return <Message isDanger>Failed to load route segments</Message>
  }

  const segment = segmentsResponse?.segments?.find(
    (segment: RouteSegment) => segment.originSequence === Number(sequence)
  )

  if (!segment) {
    return (
      <Message isInfo>
        No route segment found with sequence {sequence} for route {params.routeId}.
      </Message>
    )
  }
  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>Pallets for segment</CardHeaderTitle>
      </CardHeader>
      <h1>
        {segment.originName} (scheduled departure {formatTime(segment.scheduleDeparture)}) - {segment.destinationName}{' '}
        (scheduled arrival {formatTime(segment.scheduleArrival)})
      </h1>
      <PalletsTable pallets={segment.pallets} terminalNames={terminalNames} />
    </Card>
  )
}
