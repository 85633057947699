import { GetDeviceResponse } from '../../../webapi/hooks/queries/use-get-devices'

export const getPositionsPageTitle = (devicesResponse?: GetDeviceResponse, deviceId?: string) => {
  if (deviceId) {
    const friendlyId = devicesResponse?.devices.find(({ deviceId: id }) => id === deviceId)?.friendlyId
    return friendlyId ? `Positions - Device ${friendlyId}` : 'Positions'
  }
  return 'Positions - All devices'
}

export const createSearchParams = (startTime: number, endTime: number, onRoute?: boolean, deviceId?: string) => ({
  startTime: String(startTime),
  endTime: String(endTime),
  ...(onRoute && { onRoute: String(onRoute) }),
  ...(deviceId && { deviceId }),
})
