import { AxiosInstance } from 'axios'

import { PalletConfiguration } from '../../types/pallet-configuration'

import { useMutationOptionsWithVariables } from './use-mutation-options'

interface EditPalletConfigurationVariables {
  config: PalletConfiguration
}

const editPalletConfiguration = (webapiClient: AxiosInstance, { config }: EditPalletConfigurationVariables) =>
  webapiClient.put<void>(`/freightman/pallet-configs/${config.identifier}`, config)

export const useEditPalletConfiguration = () => useMutationOptionsWithVariables(editPalletConfiguration)
