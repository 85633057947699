import React, { useCallback, useRef, useState } from 'react'

import { useClickOutside } from '../../hooks/use-click-outside'

import {
  DropdownContent,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  DropdownRoot,
  DropdownTrigger,
} from './dropdown.styled'

export type DropdownElement = {
  name: string
  code: string
}
interface Props {
  renderButton: (toggle: () => void) => JSX.Element
  selected?: DropdownElement
  elements: DropdownElement[][]
  onSelected?: (element: DropdownElement) => void
  isUp?: boolean
  isRight?: boolean
  isStretched?: boolean
}

export const Dropdown = ({ isUp, isRight, renderButton, elements, selected, onSelected, isStretched }: Props) => {
  const [isActive, setIsActive] = useState(false)

  const dropdownMenuRef = useRef<HTMLDivElement>(null)
  const onClickOutside = useCallback(() => setIsActive(false), [])
  useClickOutside(dropdownMenuRef, onClickOutside, isActive)

  const renderElement = (element: DropdownElement) => {
    const isSelected: boolean = selected !== undefined && selected.code === element.code
    const onClick = () => {
      if (onSelected !== undefined) {
        onSelected(element)
      }
      setIsActive(false)
    }
    return (
      <DropdownItem key={element.code} selected={isSelected} onClick={onClick}>
        {element.name}
      </DropdownItem>
    )
  }
  const renderSection = (section: DropdownElement[], index: number) => {
    const key = section.map((element) => element.code).join()
    return (
      <div key={key}>
        {section.map(renderElement)}
        {index < elements.length - 1 ? <DropdownDivider /> : null}
      </div>
    )
  }
  return (
    <DropdownRoot ref={dropdownMenuRef} isUp={isUp} isRight={isRight} expanded={isActive} isStretched={isStretched}>
      <DropdownTrigger className="dropdown-trigger">{renderButton(() => setIsActive(!isActive))}</DropdownTrigger>
      <DropdownMenu role="menu" isMenuStretched={isStretched}>
        <DropdownContent>{elements.map(renderSection)}</DropdownContent>
      </DropdownMenu>
    </DropdownRoot>
  )
}
