import styled from 'styled-components'

import { TableRow } from '../../../components/bulma/table.styled'
import { SimpleMessage } from '../../../components/simple-message'

import { SegmentsTable } from './segments-table'

export const StyledOverviewTable = styled(SegmentsTable).attrs(() => ({ className: 'mt-5' }))``

export const Message = styled(SimpleMessage).attrs({ className: 'mt-4' })``

export const SelectContainer = styled.div.attrs({ className: 'select is-small' })``

interface PalletsLinkProps {
  isActive?: boolean
}

export const PalletsLink = styled.td<PalletsLinkProps>`
  color: ${({ isActive }) => (isActive ? 'hsl(217, 71%, 53%)' : 'hsl(0, 0%, 71%)')};
  cursor: pointer;
  &:hover {
    background-color: hsl(217, 71%, 53%);
    color: #fff;
  }
`

interface TableRowProps {
  isEven: boolean
}

export const StyledTableRow = styled(TableRow)<TableRowProps>`
  background-color: ${({ isEven }) => (isEven ? '#F5F5F5' : '#FFF')};
`
