import React, { ReactNode } from 'react'

import { Button } from '../bulma/button.styled'
import {
  Modal as BulmaModal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardFoot,
  ModalCardHeader,
  ModalCardTitle,
} from '../bulma/card-modal.styled'

interface Props {
  onClose: () => void
  onSave: () => void
  disableButton: boolean
  children: ReactNode
  title: string
}

export const CardModal = ({ onClose, onSave, disableButton, title, children }: Props) => {
  return (
    <BulmaModal>
      <ModalBackground onClick={onClose} />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>{title}</ModalCardTitle>
        </ModalCardHeader>
        <ModalCardBody>{children}</ModalCardBody>
        <ModalCardFoot>
          <Button isPrimary onClick={onSave} disabled={disableButton}>
            Save
          </Button>
          <Button onClick={onClose}>Exit</Button>
        </ModalCardFoot>
      </ModalCard>
    </BulmaModal>
  )
}
