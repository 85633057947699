import { AxiosInstance } from 'axios'

import { useMutationOptionsWithVariables } from './use-mutation-options'

type PutTerminalGroup = {
  identifier: string
  body: {
    name: string
    terminals: string[]
  }
}

const saveTerminalGroup = (webapiClient: AxiosInstance, { identifier, body }: PutTerminalGroup) =>
  webapiClient.put<void>(`/freightman/terminals/groups/${identifier}`, body)

export const useSaveTerminalGroup = () => useMutationOptionsWithVariables(saveTerminalGroup)
