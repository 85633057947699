import { faLock } from '@fortawesome/free-solid-svg-icons'
import { difference, without } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useQuery } from 'react-query'

import { Card, CardContent, CardFooter } from '../../../components/bulma/card.styled'
import { CardLoader } from '../../../components/card-loader.styled'
import { CardFooterButton, CardHeader, CardHeaderButtons, CardHeaderTitle } from '../../../components/card.styled'
import { TerminalOption, TerminalSelect } from '../../../components/select'
import { getStoredTerminalCodes, setItem } from '../../../lib/local-storage'
import { useBookOnDemandReturns } from '../../../webapi/hooks/mutations/use-book-on-demand-returns'
import {
  getBookOnDemandReturnsLockedQueryKey,
  useGetBookOnDemandReturnsLocked,
} from '../../../webapi/hooks/queries/use-get-book-on-demand-returns-locked'
import {
  getReadyOnDemandReturnsQueryKey,
  useGetReadyOnDemandReturns,
} from '../../../webapi/hooks/queries/use-get-ready-on-demand-returns'
import { terminalSelectBookingKey } from '../local-storage-keys'

import { StyledLockIcon, StyledBookingTable } from './booking.styled'
import { FailedToLoadMessage, LockMessage, NoBookingsMessage, NoTerminalSelectedMessage } from './messages'
import { notifyBookingResult } from './notify-booking-result'

export const Booking = () => {
  const queryClient = useQueryClient()
  const [selectedTerminalCodes, setSelectedTerminalCodes] = useState(() =>
    getStoredTerminalCodes(terminalSelectBookingKey)
  )

  const [merchantIds, setMerchantIds] = useState<number[]>([])
  const clearSelection = () => setMerchantIds([])

  const [terminalCode] = selectedTerminalCodes
  const queryVariables = { terminalCode }

  const {
    data: onDemandReturns,
    isLoading: isOnDemandReturnsLoading,
    isSuccess: isOnDemandReturnsSuccess,
    isError: isOnDemandReturnsError,
  } = useQuery({
    ...useGetReadyOnDemandReturns(queryVariables),
    enabled: Boolean(terminalCode),
  })

  const bookOnDemandReturnsLockedQuery = useQuery({
    ...useGetBookOnDemandReturnsLocked(queryVariables),
    enabled: Boolean(terminalCode),
    refetchInterval: 30000,
  })

  const invalidateQueries = () => {
    const invalidationOptions = { exact: true }
    queryClient.invalidateQueries([getReadyOnDemandReturnsQueryKey, queryVariables], invalidationOptions)
    queryClient.invalidateQueries([getBookOnDemandReturnsLockedQueryKey, queryVariables], invalidationOptions)
  }

  const {
    isSuccess: isBookOnDemandReturnsSuccess,
    isError: isBookOnDemandReturnsError,
    isLoading: isBookOnDemandReturnsLoading,
    data: bookOnDemandReturns,
    mutate: mutateOnDemandReturns,
  } = useMutation({
    ...useBookOnDemandReturns(),
    onError: invalidateQueries,
    onSuccess: invalidateQueries,
  })

  const isLocked = Boolean(bookOnDemandReturnsLockedQuery.data?.value)
  const showSelectTerminal = !isOnDemandReturnsLoading && !isOnDemandReturnsError && !terminalCode

  useEffect(
    () =>
      notifyBookingResult(
        isBookOnDemandReturnsSuccess,
        isBookOnDemandReturnsError,
        Boolean(bookOnDemandReturns?.lockReject)
      ),
    [isBookOnDemandReturnsSuccess, isBookOnDemandReturnsError, bookOnDemandReturns?.lockReject]
  )

  useEffect(() => {
    const existingMerchantIds = onDemandReturns?.map(({ merchantId }) => merchantId) || []
    const staleMerchantIds = difference(merchantIds, existingMerchantIds)
    if (staleMerchantIds.length) {
      setMerchantIds(without(staleMerchantIds, merchantIds))
    }
  }, [merchantIds, onDemandReturns])

  useEffect(clearSelection, [terminalCode])

  const onSelectTerminal = (options: TerminalOption[]) => {
    const [option] = options
    if (option) {
      setSelectedTerminalCodes([option.code])
      setItem(terminalSelectBookingKey, [option.code])
    }
  }

  const onBook = () => mutateOnDemandReturns({ terminalCode, data: { merchantIds } })

  const onToggleMerchantSelection = (merchantId: number) => {
    const hasMerchantId = merchantIds.includes(merchantId)
    setMerchantIds(hasMerchantId ? without([merchantId], merchantIds) : [...merchantIds, merchantId])
  }

  return (
    <Card>
      <CardContent>
        <CardHeader>
          <CardHeaderTitle>Book on demand returns</CardHeaderTitle>
          <CardHeaderButtons>
            <TerminalSelect selectedTerminalCodes={selectedTerminalCodes} onChange={onSelectTerminal} />
          </CardHeaderButtons>
        </CardHeader>
        {showSelectTerminal && <NoTerminalSelectedMessage />}
        {isOnDemandReturnsError && <FailedToLoadMessage />}
        {isOnDemandReturnsLoading && <CardLoader />}
        {isOnDemandReturnsSuccess &&
          (onDemandReturns?.length ? (
            <>
              <LockMessage isLocked={isLocked} />
              <StyledBookingTable
                readyOnDemandReturns={onDemandReturns}
                selectedMerchantIds={merchantIds}
                onToggleMerchantSelection={onToggleMerchantSelection}
              />
            </>
          ) : (
            <NoBookingsMessage />
          ))}
      </CardContent>
      <CardFooter>
        <CardFooterButton disabled={!merchantIds.length} onClick={clearSelection} isLight>
          Clear selection
        </CardFooterButton>
        <CardFooterButton
          disabled={isLocked || !merchantIds.length || isBookOnDemandReturnsLoading}
          onClick={onBook}
          isPrimary
          isLoading={isBookOnDemandReturnsLoading}
        >
          Book returns{isLocked && !!onDemandReturns?.length && <StyledLockIcon icon={faLock} />}
        </CardFooterButton>
      </CardFooter>
    </Card>
  )
}
