export interface ExternalEnvironmentVariables {
  AUTH0_AUDIENCE: string
  AUTH0_CLIENT_ID: string
  AUTH0_DOMAIN: string
  CARRIERS_CLIENT_URL: string
  WEBAPI_URL: string
  MAPBOX_ACCESS_TOKEN: string
}

export type EnvironmentVariables = {
  [key in keyof ExternalEnvironmentVariables as `REACT_APP_${key}`]?: ExternalEnvironmentVariables[key]
}

export const defaultExternalEnvironmentVariables: ExternalEnvironmentVariables = {
  AUTH0_AUDIENCE: '',
  AUTH0_CLIENT_ID: '',
  AUTH0_DOMAIN: '',
  CARRIERS_CLIENT_URL: '',
  WEBAPI_URL: '',
  MAPBOX_ACCESS_TOKEN: '',
} as const

export const environmentVariables: EnvironmentVariables = {
  REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_CARRIERS_CLIENT_URL: process.env.REACT_APP_CARRIERS_CLIENT_URL,
  REACT_APP_WEBAPI_URL: process.env.REACT_APP_WEBAPI_URL,
  REACT_APP_MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
} as const
