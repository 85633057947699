import { useEffect, useRef } from 'react'

export const usePrevious = <T>(previous: T) => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = previous
  }, [previous])

  return ref.current
}
