import React from 'react'

import { GetProtocolResponse } from '../../webapi/hooks/queries/use-get-device-protocols'

import { InputControl, InputDiv, InputLabel, InputSelect } from './device-input.styled'

interface Props {
  label: string
  protocols: GetProtocolResponse
  setProtocol: (selctedProtocol: string) => void
  selectedProtocol?: string
}
export const SelectProtocol = ({ label, protocols, setProtocol, selectedProtocol }: Props) => {
  return (
    <InputDiv>
      <InputLabel>{label}</InputLabel>
      <InputControl isExpanded>
        <InputSelect>
          <select name="protocol" onChange={(e) => setProtocol(e.target.value)}>
            {selectedProtocol && (
              <option value={selectedProtocol} key={selectedProtocol}>
                {selectedProtocol}
              </option>
            )}
            {!selectedProtocol && (
              <option value="placeholder" key="placeholder">
                Select protocol
              </option>
            )}
            {protocols.protocols.map((protocol) => (
              <option value={protocol} key={protocol}>
                {protocol}
              </option>
            ))}
          </select>
        </InputSelect>
      </InputControl>
    </InputDiv>
  )
}
