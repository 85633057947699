import React, { useState } from 'react'

import { ListItem } from '../../../components/select/select-side-box/list-item'
import {
  SideBoxContent,
  SelectSideBox,
  SideBoxFooter,
  Buttons,
  Button,
  SelectList,
} from '../../../components/select/select-side-box/select-side-box.styled'
import { TitleBar } from '../../../components/select/select-side-box/title-bar'
import { SideBoxContainer, SideBoxOverlay } from '../../../components/side-box.styled'

import { DashboardColumn, DashboardColumnVisibility } from './types'

interface OptionsSideBoxProps {
  show: boolean
  columns: DashboardColumnVisibility
  setColumns: (columns: DashboardColumnVisibility) => void
  onCancel: () => void
}

export const OptionsSideBox = ({ show, onCancel, columns, setColumns }: OptionsSideBoxProps) => {
  const [local, setLocal] = useState<DashboardColumnVisibility>({
    [DashboardColumn.HomePallets]: columns[DashboardColumn.HomePallets],
    [DashboardColumn.MixedPallets]: columns[DashboardColumn.MixedPallets],
    [DashboardColumn.LockerPallets]: columns[DashboardColumn.LockerPallets],
    [DashboardColumn.ReturnPallets]: columns[DashboardColumn.ReturnPallets],
    [DashboardColumn.Date]: columns[DashboardColumn.Date],
    [DashboardColumn.Segment]: columns[DashboardColumn.Segment],
    [DashboardColumn.DriveTime]: columns[DashboardColumn.DriveTime],
    [DashboardColumn.Courier]: columns[DashboardColumn.Courier],
    [DashboardColumn.ScheduleDeparture]: columns[DashboardColumn.ScheduleDeparture],
    [DashboardColumn.ActualDeparture]: columns[DashboardColumn.ActualDeparture],
    [DashboardColumn.ScheduleArrival]: columns[DashboardColumn.ScheduleArrival],
    [DashboardColumn.ActualArrival]: columns[DashboardColumn.ActualArrival],
    [DashboardColumn.TotalNumberOfParcels]: columns[DashboardColumn.TotalNumberOfParcels],
  })
  if (!show) {
    return null
  }
  const toggle = (column: DashboardColumn) => {
    setLocal({ ...local, [column]: !local[column] })
  }
  const renderColumnSetting = (column: DashboardColumn, label: string): React.ReactNode => {
    return (
      <ListItem
        onClick={() => toggle(column)}
        checked={local[column]}
        label={label}
        mode={'SELECT'}
        type={'checkbox'}
      />
    )
  }
  return (
    <SideBoxContainer>
      <SideBoxOverlay onClick={onCancel} />
      <SelectSideBox>
        <SideBoxContent>
          <TitleBar title="Show Columns" onClose={onCancel} />
          <SelectList>
            {renderColumnSetting(DashboardColumn.Date, 'Date')}
            {renderColumnSetting(DashboardColumn.Segment, 'Segment')}
            {renderColumnSetting(DashboardColumn.DriveTime, 'Drive Time')}
            {renderColumnSetting(DashboardColumn.Courier, 'Courier')}
            {renderColumnSetting(DashboardColumn.ScheduleDeparture, 'Schedule Departure')}
            {renderColumnSetting(DashboardColumn.ActualDeparture, 'Actual Departure')}
            {renderColumnSetting(DashboardColumn.ScheduleArrival, 'Schedule Arrival')}
            {renderColumnSetting(DashboardColumn.ActualArrival, 'Actual Arrival')}
            {renderColumnSetting(DashboardColumn.TotalNumberOfParcels, 'Total number of parcels')}
            {renderColumnSetting(DashboardColumn.HomePallets, 'Pallets (mostly home)')}
            {renderColumnSetting(DashboardColumn.LockerPallets, 'Pallets (mostly locker)')}
            {renderColumnSetting(DashboardColumn.ReturnPallets, 'Pallets (return pallets)')}
            {renderColumnSetting(DashboardColumn.MixedPallets, 'Pallets (mixed)')}
          </SelectList>
        </SideBoxContent>
        <SideBoxFooter>
          <Buttons>
            <Button onClick={() => setColumns(local)} isPrimary>
              Save
            </Button>
          </Buttons>
        </SideBoxFooter>
      </SelectSideBox>
    </SideBoxContainer>
  )
}
