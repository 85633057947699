import React from 'react'

import { GlobalStyle } from './app.global.styled'
import { useBulma } from './hooks/use-bulma'
import { Routes } from './routes/routes'
import { useWebapiJWTSubscription } from './webapi/hooks/use-webapi-jwt-subscription'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

export const App = () => {
  useBulma()
  useWebapiJWTSubscription()
  return (
    <>
      <GlobalStyle />
      <Routes />
    </>
  )
}
