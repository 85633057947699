import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    // Styles used for custom toasts implemented with bulma
    .Toastify__toast.toastify-custom-toast,
    .Toastify__toast-body.toastify-custom-body {
        padding: 0
    }

    // Styles the react-select component
    div.ground-operations__multi-value {
        background-color: hsl(171, 100%, 41%);
        color: white;
    }
    div.ground-operations__multi-value__label {
        color: white;
    }
    div.ground-operations__multi-value__remove {
        cursor: pointer;
        &:hover {
            background-color: hsl(171, 100%, 41%);
            opacity: 0.4;
            color: white;
        }
    }

    // Contains css to fix / compensate for styling issues in the context the app is running in.
    
    .card {
        height: auto;
    }

    label {
        font-weight: 400;
        margin-bottom: 0;
    }

    .label {
        padding: 0;
        line-height: normal;
        text-align: left;
    }

    .table {
        margin-bottom: 0;
        max-width: none;
        width: auto;
    }

    a:hover, a:focus {
        text-decoration: none;
    }

    a + a, button + button, .btn + .btn {
        margin-left: 0;
    }

    input[type='checkbox'], input[type='radio'] {
        margin: 0;
    }

    #application .navbar.navbar-default {
        font-size: 14px;
        -webkit-font-smoothing: auto;
        margin-bottom: 0px;
        background-color: #f8f8f8;

        .container {
            flex-grow: 0;
            display: block;
            min-height: 0;
            font-family: HelveticaNeue, Helvetica Neue, Helvetica, Arial, "Lucida Grande", sans-serif;
        }

        @media (min-width: 768px) {
            .container {
                width: 750px;
            }
        }
        @media (min-width: 992px) {
            .container {
                width: 970px;
            }
        }
        @media (min-width: 1200px) {
            .container {
                width: 1170px;
            }
        }
        @media (min-width: 1280px) {
            .container {
                width: 1260px;
            }
        }

        .navbar-brand {
            min-height: 0;
        }
    }
`
