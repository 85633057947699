import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'

import { useMutationOptionsWithVariables } from './use-mutation-options'

interface MerchantDropOffOverviewVariables {
  terminals: string[] // Terminal codes
  merchantIds: number[]
}

export interface MerchantDropOffTerminalOverview {
  terminal: string
  toBeInjected: number
  inLocker: number
  inTransitToTerminal: number
  toBeTransited: number
  readyToBook: number
  booked: number
  maxAttemptsReached: number
}

export interface MerchantDropOffOverviewResponse {
  terminals: MerchantDropOffTerminalOverview[]
}

const merchantDropOffOverview = (webapiClient: AxiosInstance, variables: MerchantDropOffOverviewVariables) =>
  webapiClient.post<MerchantDropOffOverviewResponse>('/merchant-drop-off/overview', variables)

export const useMerchantDropOffOverview = () =>
  useMutationOptionsWithVariables(merchantDropOffOverview, getResponseData)
