import classnames from 'classnames'
import styled from 'styled-components'

export interface MessageProps {
  isInfo?: boolean
  isSuccess?: boolean
  isWarning?: boolean
  isDanger?: boolean
  isSmall?: boolean
}

export const Message = styled.article.attrs(({ isInfo, isSuccess, isWarning, isDanger, isSmall }: MessageProps) => ({
  className: classnames('message', {
    'is-info': isInfo,
    'is-success': isSuccess,
    'is-warning': isWarning,
    'is-danger': isDanger,
    'is-small': isSmall,
  }),
}))<MessageProps>``

export const MessageBody = styled.div.attrs({ className: classnames('message-body') })``
