import styled from 'styled-components'

import { SimpleMessage } from '../../../components/simple-message'

import { DashboardTable } from './dashboard-table'

export const StyledOverviewTable = styled(DashboardTable).attrs(() => ({ className: 'mt-5' }))``

interface ParcelsLinkProps {
  isActive?: boolean
}

export const ParcelsLink = styled.td<ParcelsLinkProps>`
  color: ${({ isActive }) => (isActive ? 'hsl(217, 71%, 53%)' : 'hsl(0, 0%, 71%)')};
  cursor: pointer;
  &:hover {
    background-color: hsl(217, 71%, 53%);
    color: #fff;
  }
`

export const Message = styled(SimpleMessage).attrs({ className: 'mt-4' })``
