import styled from 'styled-components'

export const List = styled.div.attrs({
  className: 'list has-visible-pointer-controls',
})`
  flex: 1;
  margin-left: -10px;
  margin-right: -15px;
`

export const ListItem = styled.div.attrs({ className: 'list-item' })``

export const ListItemContent = styled.div.attrs({ className: 'list-item-content' })``

export const ListItemTitle = styled.div.attrs({ className: 'list-item-title' })``

export const ListItemDescription = styled.div.attrs({ className: 'list-item-description' })``

export const ListItemControls = styled.div.attrs({ className: 'list-item-controls' })``

export const ListItemButtons = styled.div.attrs({ className: 'buttons is-right' })``
