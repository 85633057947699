import { MerchantDropOffParcelsPhase } from '../webapi/hooks/queries/use-get-merchant-drop-off-parcels'

import { phaseToParam, terminalCodeToParam } from './url-parameters'

export const getStartUrl = () => '/'

export const getReturnPhaseUrl = (phase: MerchantDropOffParcelsPhase, terminalCode: string) =>
  `/merchant-return/dashboard/${phaseToParam(phase)}/${terminalCodeToParam(terminalCode)}`

export const getSegmentDetailsUrl = (routeId: string, sequence: number) =>
  `/routing/segment-details/route/${routeId}/sequence/${sequence}`

export const getPositionUrl = (startTime?: number, endTime?: number, deviceId?: string) => {
  const variables: Record<string, string> = {}
  if (startTime) {
    variables.startTime = String(startTime)
  }
  if (endTime) {
    variables.endTime = String(endTime)
  }
  if (deviceId) {
    variables.deviceId = deviceId
  }

  const searchParams = new URLSearchParams(variables)
  const searchString = searchParams.toString() ? `?${searchParams.toString()}` : ''
  return `/gps-tracking/position${searchString}`
}
