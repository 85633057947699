import { useEffect } from 'react'

export const useDisabledBodyScroll = (enabled = true) => {
  useEffect(() => {
    let previousOverflow: string
    if (enabled) {
      previousOverflow = document.documentElement.style.overflow
      document.documentElement.style.overflow = 'hidden'
    }
    return () => {
      if (enabled) {
        document.documentElement.style.overflow = previousOverflow
      }
    }
  }, [enabled])
}
