import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledLink = styled(Link)<{ $isActive?: boolean }>`
  &&& {
    background-color: ${({ $isActive }) => ($isActive ? 'hsl(171, 100%, 41%)' : undefined)};
    color: ${({ $isActive }) => ($isActive ? 'white' : undefined)};
  }
`

interface MenuContainerProps {
  isScrollable?: boolean
}

export const MenuContainer = styled.aside<MenuContainerProps>`
  padding: 1rem;
  width: 300px;
  ${({ isScrollable = false }) =>
    isScrollable
      ? css`
          overflow-y: scroll;
        `
      : css`
          position: sticky;
          top: 0;
          align-self: flex-start;
        `}
`
