import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'
import { PalletConfiguration } from '../../types/pallet-configuration'

import { useQueryOptionsWithVariables } from './use-query-options'

export type GetPalletConfigurationsResponse = { configs: PalletConfiguration[] }

export const getPalletConfigurationsQueryKey = ['pallets', 'configurations']

interface GetPalletConfigurationsVariables {
  appliesToTerminalCode?: string
}

const getPalletConfigurations = (
  webapiClient: AxiosInstance,
  { appliesToTerminalCode }: GetPalletConfigurationsVariables
) =>
  webapiClient.post<GetPalletConfigurationsResponse>('/freightman/pallet-configs/query', {
    appliesToTerminalCode: appliesToTerminalCode ?? null,
  })

export const useGetPalletConfigurations = (variables: GetPalletConfigurationsVariables) =>
  useQueryOptionsWithVariables(getPalletConfigurationsQueryKey, getPalletConfigurations, variables, getResponseData)
