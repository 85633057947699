import {
  MerchantWarehouseLocation,
  MerchantWarehouseLocationsResponse,
} from '../../../webapi/hooks/queries/use-get-merchant-warehouse-locations'
import {
  GetTerminalLocationsResponse,
  TerminalLocation,
} from '../../../webapi/hooks/queries/use-get-terminal-locations'
import { BottomLeftWidget, PalletConfiguration } from '../../../webapi/types/pallet-configuration'

export const createNewPalletConfiguration = (): PalletConfiguration => ({
  name: '',
  code: '',
  appliesTo: {
    terminalCodes: [],
  },
  destination: '',
  properties: {
    destinationTypes: [],
    ejectionCountries: [],
    ejectionTerminal: null,
    returnsToMerchantWarehouse: null,
  },
  bottomLeftWidget: BottomLeftWidget.DEFAULT,
})

export const createTerminalLocationsMap = ({ terminals }: GetTerminalLocationsResponse) => {
  const terminalLocations = new Map<string, TerminalLocation>()
  for (const location of terminals) {
    terminalLocations.set(location.identifier, location)
    terminalLocations.set(location.terminalCode, location)
  }
  return terminalLocations
}

export const createMerchantWarehouseLocationsMap = ({ locations }: MerchantWarehouseLocationsResponse) => {
  const map = new Map<string, MerchantWarehouseLocation>()
  for (const location of locations) {
    map.set(location.identifier, location)
  }
  return map
}
