import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

import { Button } from '../../../../components/bulma/button.styled'
import { Dropdown } from '../../../../components/dropdown'
import { Icon } from '../../../../components/select/select.styled'
import { BottomLeftWidget } from '../../../../webapi/types/pallet-configuration'

import { prettyBottomLeftWidgetName } from './configuration-utils'

const toElement = (value: BottomLeftWidget) => ({
  name: prettyBottomLeftWidgetName(value),
  code: value,
})

const DEFAULT = toElement(BottomLeftWidget.DEFAULT)

const ELEMENTS = [
  [
    toElement(BottomLeftWidget.FINALIZED_BOOKED),
    toElement(BottomLeftWidget.INTERNATIONAL),
    toElement(BottomLeftWidget.NONE),
    DEFAULT,
  ],
]

interface Props {
  selected: BottomLeftWidget
  onChange: (value: BottomLeftWidget) => void
}

export const BottomLeftWidgetDropdown = ({ selected, onChange }: Props) => {
  const selectedElement = ELEMENTS.flat().find((element) => element.code === selected) || DEFAULT
  const renderButton = (toggle: () => void) => {
    return (
      <Button
        isSmall
        onClick={toggle}
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        style={{ width: 180, display: 'flex', justifyContent: 'space-between' }}
      >
        <span>{selectedElement.name}</span>
        <span className="icon is-small">
          <Icon icon={faAngleDown} />
        </span>
      </Button>
    )
  }
  return (
    <Dropdown
      renderButton={renderButton}
      elements={ELEMENTS}
      onSelected={(opt) => {
        const newlySelected = ELEMENTS.flat().find((element) => element.code === opt.code)
        if (newlySelected) {
          onChange(newlySelected.code)
        }
      }}
      selected={selectedElement}
    />
  )
}
