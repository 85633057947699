import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'

import { useMutationOptionsWithVariables } from './use-mutation-options'

interface DownloadCandidatesByTerminalAndMerchantsVariables {
  terminalCode: string
}

const downloadCandidatesByTerminalAndMerchants = (
  webapiClient: AxiosInstance,
  variables: DownloadCandidatesByTerminalAndMerchantsVariables
) =>
  webapiClient.get<Blob>('/slotman/candidates-by-terminal-and-merchants', {
    params: variables,
    responseType: 'blob',
  })

export const useDownloadCandidatesByTerminalAndMerchants = () =>
  useMutationOptionsWithVariables(downloadCandidatesByTerminalAndMerchants, getResponseData)

interface DownloadIdleParcelsByTerminalVariables {
  terminalCode: string
}

const downloadIdleParcelsByTerminal = (
  webapiClient: AxiosInstance,
  variables: DownloadIdleParcelsByTerminalVariables
) =>
  webapiClient.get<Blob>('/slotman/idle-parcels-by-terminal', {
    params: variables,
    responseType: 'blob',
  })

export const useDownloadIdleParcelsByTerminal = () =>
  useMutationOptionsWithVariables(downloadIdleParcelsByTerminal, getResponseData)
