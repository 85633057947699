import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'

import { useMutationOptionsWithVariables } from './use-mutation-options'

interface GpsTrackingDeviceVariables {
  imei?: string
  protocol: string
  simCardId?: string
}

export interface GpsTrackingDeviceResponse {
  deviceId: string
  imei: string | null
  protocol: string
  simcardId: string | null
}

const gpsTrackingDevice = (webApiClient: AxiosInstance, variables: GpsTrackingDeviceVariables) =>
  webApiClient.post<GpsTrackingDeviceResponse>('/tracking-devices/devices', variables)

export const useGpsTrackingDevice = () => useMutationOptionsWithVariables(gpsTrackingDevice, getResponseData)
