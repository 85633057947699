const numberPattern = /^[0-9]+$/ // non-negative integer
const dashSeparatedStringPattern = /^[a-z]+(-[a-z]+)*$/ // Matches e.g. a terminal code in lowercase with dash separation (stockholm-brunna)

export const isDashSeparatedString = (input: string) => dashSeparatedStringPattern.test(input)

export const isNonNegativeInteger = (input: string) => numberPattern.test(input)

export const isNotNull = <T>(value: T | null): value is T => value !== null

export const isNumberArray = (value: unknown): value is number[] =>
  Array.isArray(value) && value.every((item) => typeof item === 'number')

export const isStringArray = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every((item) => typeof item === 'string')

export const isString = (value: unknown): value is string => typeof value === 'string'

export const validate = <T>(value: unknown, validator: (value: unknown) => value is T) =>
  validator(value) ? value : undefined
