import styled from 'styled-components'

export const Input = styled.input.attrs({ className: 'input is-small', type: 'text' })`
  flex: 1;
`

export const NumberInput = styled.input.attrs({ className: 'input is-small', type: 'number' })`
  flex: 1;
`

export const FieldRow = styled.div.attrs({})`
  display: flex;
  gap: 40px;
`
