import { useMemo } from 'react'

import { decodeJWT } from '../../lib/decode-jwt'
import { WebapiRole } from '../../types/webapi-role'

import { useWebapiJWT } from './use-webapi-jwt'

const rolesKey = 'https://webapi.budbee.com/roles'

interface DecodedToken {
  [rolesKey]?: WebapiRole[]
}

export const useHasAnyWebapiRole = (...roles: WebapiRole[]) => {
  const webapiJWT = useWebapiJWT()
  const decodedToken = useMemo(() => decodeJWT<DecodedToken>(webapiJWT), [webapiJWT])
  const rolesFromToken = decodedToken?.[rolesKey]
  const rolesToFind = new Set([WebapiRole.Admin, ...roles])
  return Boolean(rolesFromToken?.some((role) => rolesToFind.has(role)))
}
