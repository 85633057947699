import classnames from 'classnames'
import styled, { css } from 'styled-components'

interface TableProps {
  isHoverable?: boolean
  isFullwidth?: boolean
  isStriped?: boolean
  isNarrow?: boolean
}

export const Table = styled.table.attrs(({ isHoverable, isFullwidth, isStriped, isNarrow }: TableProps) => ({
  className: classnames('table', {
    'is-hoverable': isHoverable,
    'is-fullwidth': isFullwidth,
    'is-striped': isStriped,
    'is-narrow': isNarrow,
  }),
}))<TableProps>``

interface TableRowProps {
  isSelected?: boolean
  isSelectable?: boolean
}

export const TableRow = styled.tr.attrs(({ isSelected }: TableRowProps) => ({
  className: classnames({ 'is-selected': isSelected }),
}))<TableRowProps>`
  ${({ isSelectable }) =>
    isSelectable &&
    css`
      cursor: pointer;
    `}
`
