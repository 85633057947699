import { faAngleDown, faXmark } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useRef, useState } from 'react'

import { Icon } from '../../components/select/select.styled'
import { useClickOutside } from '../../hooks/use-click-outside'

import {
  ValueItem,
  Menu,
  MenuContent,
  AddValueContainer,
  AddValueInputContainer,
  AddValueButtonContainer,
  AddValueButton,
  AddValueInput,
  CsvContainer,
  ValueItemLabel,
  CsvTrigger,
} from './csv.styled'

interface CsvInputProps {
  readonly: boolean
  values: string[]
  onChange: (values: string[]) => void
  isValidValue: (value: string) => boolean
}
export const CsvInput = (props: CsvInputProps) => {
  const { readonly, values, onChange, isValidValue } = props
  const [newValue, setNewValue] = useState<string>('')
  const [active, setActive] = useState<boolean>(false)

  const dropdownMenuRef = useRef<HTMLDivElement>(null)
  const onClickOutside = useCallback(() => setActive(false), [])
  useClickOutside(dropdownMenuRef, onClickOutside, active)

  const onAdd = () => {
    if (!values.includes(newValue)) {
      onChange([...values, newValue])
      setNewValue('')
    }
  }
  const onRemove = (value: string) => {
    onChange(values.filter((val) => val !== value))
    setNewValue('')
  }
  const renderItem = (value: string) => {
    return (
      <ValueItem key={value}>
        <ValueItemLabel>{value}</ValueItemLabel>
        <span className="icon is-small" onClick={() => onRemove(value)}>
          <Icon icon={faXmark} />
        </span>
      </ValueItem>
    )
  }

  return (
    <CsvContainer active={active} ref={dropdownMenuRef}>
      <CsvTrigger readonly={readonly} aria-haspopup="true" aria-controls="dropdown-menu">
        <input
          className="input is-small"
          style={{ cursor: readonly ? 'text' : 'pointer' }}
          onClick={() => !readonly && setActive(!active)}
          value={values.join(', ')}
          readOnly
        />
        {!readonly && (
          <span className="icon is-small is-right">
            <Icon color="black" icon={faAngleDown} />
          </span>
        )}
      </CsvTrigger>
      <Menu>
        <MenuContent>
          {values.map(renderItem)}
          <AddValueContainer>
            <AddValueInputContainer>
              <AddValueInput value={newValue} onChange={(e) => setNewValue(e.target.value)} />
            </AddValueInputContainer>
            <AddValueButtonContainer>
              <AddValueButton onClick={onAdd} disabled={newValue.length === 0 || !isValidValue(newValue)}>
                Add
              </AddValueButton>
            </AddValueButtonContainer>
          </AddValueContainer>
        </MenuContent>
      </Menu>
    </CsvContainer>
  )
}
