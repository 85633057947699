import { AxiosInstance } from 'axios'

import { useMutationOptionsWithVariables } from './use-mutation-options'

type PostTerminalGroup = {
  body: {
    name: string
    terminals: string[]
  }
}

const createTerminalGroup = (webapiClient: AxiosInstance, { body }: PostTerminalGroup) =>
  webapiClient.post<void>(`/freightman/terminals/groups`, body)

export const useCreateTerminalGroup = () => useMutationOptionsWithVariables(createTerminalGroup)
