import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'

import { useQueryOptionsWithVariables } from './use-query-options'

interface GetBookOnDemandReturnsLockedVariables {
  terminalCode: string
}

export interface GetBookOnDemandReturnsLockedResponse {
  value: boolean
}

export const getBookOnDemandReturnsLockedQueryKey = ['orders', 'book-ondemand-returns', 'locked']

const getBookOnDemandReturnsLocked = (
  webapiClient: AxiosInstance,
  { terminalCode }: GetBookOnDemandReturnsLockedVariables
) => webapiClient.get<GetBookOnDemandReturnsLockedResponse>(`/orders/book-ondemand-returns/${terminalCode}/locked`)

export const useGetBookOnDemandReturnsLocked = (variables: GetBookOnDemandReturnsLockedVariables) =>
  useQueryOptionsWithVariables(
    getBookOnDemandReturnsLockedQueryKey,
    getBookOnDemandReturnsLocked,
    variables,
    getResponseData
  )
