import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

import { Button } from '../../../components/bulma/button.styled'
import { Dropdown, DropdownElement } from '../../../components/dropdown'
import { Icon } from '../../../components/select/select.styled'

import { Image } from './relation-dropdown.styled'

export const CHANGE = { name: 'Change Serviced By', code: 'CHANGE' }
export const CLEAR = { name: 'Clear Serviced By', code: 'CLEAR' }

const OPTS = [[CHANGE, CLEAR]]

interface Props {
  onChange: () => void
  onClear: () => void
  isUp?: boolean
}

export const RelationDropdown = ({ onChange, onClear, isUp }: Props) => {
  const renderButton = (toggle: () => void) => (
    <Button isSmall aria-haspopup="true" aria-controls="dropdown-menu" onClick={() => toggle()}>
      <Image>
        <Icon icon={faEllipsisV} />
      </Image>
    </Button>
  )
  const onSelected = (value: DropdownElement) => {
    if (value.code === CHANGE.code) {
      onChange()
    }
    if (value.code === CLEAR.code) {
      onClear()
    }
  }
  return <Dropdown isUp={isUp} isRight renderButton={renderButton} elements={OPTS} onSelected={onSelected} />
}
