import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { SquareButton } from '../../../components/bulma/button.styled'
import { CardFooter } from '../../../components/bulma/card.styled'
import { List } from '../../../components/bulma/list.styled'
import { ScrollableCard, ScrollableCardContent } from '../../../components/card.styled'
import { useStoredTerminalCode } from '../../../hooks/use-stored-terminal-code'
import { search } from '../../../lib/search'
import { useGetMerchantWarehouseLocations } from '../../../webapi/hooks/queries/use-get-merchant-warehouse-locations'
import { useGetPalletConfigurations } from '../../../webapi/hooks/queries/use-get-pallet-configurations'
import { useGetTerminalLocations } from '../../../webapi/hooks/queries/use-get-terminal-locations'
import { PalletConfiguration } from '../../../webapi/types/pallet-configuration'
import { terminalSelectPalletConfigurationsKey } from '../../merchant-return/local-storage-keys'

import { Configuration } from './configuration'
import { ConfigurationsCardHeader } from './configurations-card-header'
import { ConfigurationsListItem } from './configurations-list-item'
import {
  createMerchantWarehouseLocationsMap,
  createNewPalletConfiguration,
  createTerminalLocationsMap,
} from './configurations-utils'
import { NonSelected, Root, StyledSimpleMessage } from './configurations.styled'

export const Configurations = () => {
  const [configuration, setConfiguration] = useState<PalletConfiguration | null>(null)
  const [nameFilter, setNameFilter] = useState('')
  const [appliesToTerminalCode, setAppliesToTerminalCode] = useStoredTerminalCode(terminalSelectPalletConfigurationsKey)
  const palletConfigurationsQuery = useQuery(useGetPalletConfigurations({ appliesToTerminalCode }))
  const configs = search(palletConfigurationsQuery.data?.configs ?? [], ['name'], nameFilter)

  const terminalLocationsQuery = useQuery({
    ...useGetTerminalLocations(),
    select: createTerminalLocationsMap,
  })

  const merchantWarehouses = useQuery({
    ...useGetMerchantWarehouseLocations(),
    select: createMerchantWarehouseLocationsMap,
  })

  const onSave = (config: PalletConfiguration) => setConfiguration(config)

  const onClose = () => setConfiguration(null)

  const editConfiguration = (config?: PalletConfiguration) => {
    if (config) {
      setConfiguration({ ...config })
    } else {
      setConfiguration(createNewPalletConfiguration())
    }
  }

  const onSelectedTerminalCodeChange = (code?: string) => {
    setAppliesToTerminalCode(code)
  }

  return (
    <Root>
      <ScrollableCard>
        <ScrollableCardContent>
          <ConfigurationsCardHeader
            filter={nameFilter}
            selectedTerminalCode={appliesToTerminalCode}
            isFilterDisabled={configuration !== null}
            onTerminalCodeSelectionChange={onSelectedTerminalCodeChange}
            onFilter={setNameFilter}
          />
          {palletConfigurationsQuery.isError || terminalLocationsQuery.isError || merchantWarehouses.isError ? (
            <StyledSimpleMessage isDanger>Failed to load</StyledSimpleMessage>
          ) : (
            <List>
              {configs.map((config) => (
                <ConfigurationsListItem
                  key={config.identifier}
                  config={config}
                  onClick={() => editConfiguration(config)}
                />
              ))}
            </List>
          )}
        </ScrollableCardContent>
        <CardFooter>
          <div style={{ flex: 1 }} />
          <SquareButton
            isInfo
            style={{ width: 200 }}
            onClick={() => editConfiguration()}
            disabled={configuration !== null}
          >
            Add New Config
          </SquareButton>
        </CardFooter>
      </ScrollableCard>
      {palletConfigurationsQuery.isSuccess &&
      terminalLocationsQuery.isSuccess &&
      merchantWarehouses.isSuccess &&
      configuration ? (
        <Configuration
          terminalLocations={terminalLocationsQuery.data}
          merchantWarehouses={merchantWarehouses.data}
          config={configuration}
          onSave={onSave}
          onClose={onClose}
        />
      ) : (
        <NonSelected />
      )}
    </Root>
  )
}
