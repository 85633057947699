import React, { ReactNode } from 'react'
import { toast, ToastOptions } from 'react-toastify'

import { Notification, NotificationDeleteButton } from '../components/bulma/notification.styled'

const defaultOptions: ToastOptions = {
  className: 'toastify-custom-toast',
  bodyClassName: 'toastify-custom-body',
  closeButton: false,
  hideProgressBar: true,
}

export const notification = {
  info: (content: ReactNode, options?: ToastOptions) =>
    toast(
      <Notification isInfo>
        <NotificationDeleteButton />
        {content}
      </Notification>,
      { ...defaultOptions, ...options }
    ),
  warning: (content: ReactNode, options?: ToastOptions) =>
    toast(
      <Notification isWarning>
        <NotificationDeleteButton />
        {content}
      </Notification>,
      { ...defaultOptions, ...options }
    ),
  success: (content: ReactNode, options?: ToastOptions) =>
    toast(
      <Notification isSuccess>
        <NotificationDeleteButton />
        {content}
      </Notification>,
      { ...defaultOptions, ...options }
    ),
  error: (content: ReactNode, options?: ToastOptions) =>
    toast(
      <Notification isDanger>
        <NotificationDeleteButton />
        {content}
      </Notification>,
      { ...defaultOptions, ...options }
    ),
}
