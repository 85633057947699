import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import React, { MouseEvent, ReactNode } from 'react'

import { Icon, Item, RemoveItem, RemoveItemIcon } from './select-side-box.styled'

interface Props {
  onClick: () => void
  checked: boolean
  label: string | ReactNode
  subLabel?: string
  mode: 'SELECT' | 'REMOVE'
  type: 'checkbox' | 'radio'
  link?: string
}

const onLinkOpen = (link: string) => (e: MouseEvent) => {
  window.open(link, '_blank')
  e.preventDefault()
}

const renderLabel = (label: string | ReactNode, subLabel: string | undefined) => {
  if (!subLabel) {
    return label
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{label}</span>
      <span style={{ fontSize: 12, color: 'lightslategrey' }}>{subLabel}</span>
    </div>
  )
}

export const ListItem = ({ onClick, checked, label, subLabel, mode, type, link }: Props) =>
  mode === 'SELECT' ? (
    <Item>
      <input onChange={onClick} type={type} checked={checked} />
      <span>{renderLabel(label, subLabel)}</span>
      <div style={{ flex: 1 }} />
      {link && (
        <div onClick={onLinkOpen(link)}>
          <Icon icon={faArrowUpRightFromSquare} />
        </div>
      )}
    </Item>
  ) : (
    <RemoveItem onClick={onClick}>
      <RemoveItemIcon />
      {label}
    </RemoveItem>
  )
