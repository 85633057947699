import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'
import { WebapiAcceptHeaders } from '../../../types/webapi-accept-headers'

import { useQueryOptions } from './use-query-options'

export interface Merchant {
  id: number
  name: string
  externalName: string
}

export type GetMerchantsResponse = {
  merchants: Merchant[]
}

export const getMerchantsQueryKey = ['merchants']

const getMerchants = (webapiClient: AxiosInstance) =>
  webapiClient.get<GetMerchantsResponse>('/merchants', { headers: { accept: WebapiAcceptHeaders.MERCHANTS_V2 } })

export const useGetMerchants = () => ({
  ...useQueryOptions(getMerchantsQueryKey, getMerchants, getResponseData),
  staleTime: Infinity,
  cacheTime: Infinity,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
})
