import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'
import { RouteSegment } from '../../types/segments'

import { useQueryOptionsWithVariables } from './use-query-options'

export type GetSegmentsForRouteVariables = {
  routeId?: string
}

export type RouteSegmentsResponse = { segments: RouteSegment[] }

export const getSegmentsForRouteResultsQueryKey = ['route-segment', 'for-route']

const getSegmentsForRouteResults = (webapiClient: AxiosInstance, { routeId }: GetSegmentsForRouteVariables) => {
  if (!routeId) {
    return Promise.reject(new Error('Missing route parameter'))
  }
  return webapiClient.get<RouteSegmentsResponse>(`/freightman/routes/${routeId}/segments`)
}

export const useGetSegmentsForRouteResults = (variables: GetSegmentsForRouteVariables) =>
  useQueryOptionsWithVariables(
    [...getSegmentsForRouteResultsQueryKey, variables],
    getSegmentsForRouteResults,
    variables,
    getResponseData
  )
