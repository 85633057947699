import { differenceInMinutes, format, formatDistance, formatRelative } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'

const formatRelativeLocale = {
  lastWeek: "'Last' eeee 'at' p",
  yesterday: "'Yesterday at' p ",
  today: "'Today at' p",
  other: 'MMMM do HH:mm',
}

export const formatDate = (date: number) => {
  const baseDateTime = new Date()
  const deviceDateTime = new Date(date)
  const diff = differenceInMinutes(baseDateTime, deviceDateTime)
  if (baseDateTime.getFullYear() !== deviceDateTime.getFullYear()) {
    return format(deviceDateTime, 'yyyy-MM-dd HH:mm')
  }

  if (diff < 60) {
    return formatDistance(deviceDateTime, baseDateTime, { addSuffix: true })
  }

  return formatRelative(deviceDateTime, baseDateTime, {
    locale: {
      ...enGB,
      formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token],
    },
  })
}
