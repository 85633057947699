import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setWebapiJWT } from '../../state/slices/webapi-jwt-slice'

export const useWebapiJWTSubscription = () => {
  const { isLoading, isAuthenticated, getIdTokenClaims } = useAuth0()
  const dispatch = useDispatch()

  const getWebapiJWT = React.useCallback(async () => {
    const claims = await getIdTokenClaims()
    const { __raw: webapiJWT } = claims || { __raw: '' }
    return webapiJWT
  }, [getIdTokenClaims])

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      getWebapiJWT()
        .then((webapiJWT) => dispatch(setWebapiJWT(webapiJWT)))
        .catch(window.console.error)
    }
  }, [dispatch, getWebapiJWT, isAuthenticated, isLoading])
}
