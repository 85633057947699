import styled from 'styled-components'

import { SimpleMessage } from '../../../components/simple-message'

export const Root = styled.div.attrs({})`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const FilterInput = styled.input.attrs({
  className: 'input is-small',
  type: 'text',
  placeholder: 'Filter names',
})`
  flex: 1;
`

export const StyledSimpleMessage = styled(SimpleMessage)`
  margin-top: 1rem;
`
