import { MerchantDropOffParcelsPhase } from '../../../webapi/hooks/queries/use-get-merchant-drop-off-parcels'

export const injectionTerminalEnablement = new Set([
  MerchantDropOffParcelsPhase.InTransit,
  MerchantDropOffParcelsPhase.ToBeTransited,
  MerchantDropOffParcelsPhase.Bookable,
  MerchantDropOffParcelsPhase.Booked,
  MerchantDropOffParcelsPhase.ReachedMaxAttempts,
])

export const ejectionTerminalEnablement = new Set([
  MerchantDropOffParcelsPhase.ToBeTransited,
  MerchantDropOffParcelsPhase.NotInjected,
  MerchantDropOffParcelsPhase.InLocker,
])
