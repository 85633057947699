import { faCircleXmark, faCircleCheck, IconDefinition } from '@fortawesome/free-regular-svg-icons'
import React, { ReactNode } from 'react'

import { Button } from '../bulma/button.styled'
import { Modal as BulmaModal, ModalBackground, ModalCard, ModalCardBody } from '../bulma/card-modal.styled'

import {
  Buttons,
  CancelButton,
  ModalBody,
  ModalContent,
  ModalTitle,
  ModalIcon,
  List,
} from './action-confirmation-modal.styled'

type ActionConfirmationModalVariant = 'danger' | 'success'

export type Merchant = { id: number; name: string }

interface Props {
  variant: ActionConfirmationModalVariant
  title?: string
  isConfirming?: boolean
  confirmButtonText?: string
  merchants?: Merchant[]
  children?: ReactNode
  effects?: string[]
  onClose: () => void
  onConfirm: () => void
}

export const ActionConfirmationModal = ({
  title,
  onConfirm,
  onClose,
  variant,
  merchants,
  effects,
  confirmButtonText,
  isConfirming = false,
  children,
}: Props) => {
  return (
    <BulmaModal>
      <ModalBackground onClick={onClose} />
      <ModalCard>
        <ModalCardBody>
          <ModalContent>
            <ModalIcon {...modalIconPropsByVariant[variant]} />
            <ModalTitle>{title || 'Are you sure?'}</ModalTitle>
            <ModalBody>
              {children}
              {merchants && merchants.length > 0 && (
                <List>
                  For Merchants:
                  <ul>
                    {merchants.map((merchant) => (
                      <li key={merchant.id}>{merchant.name}</li>
                    ))}
                  </ul>
                </List>
              )}
              {effects && effects.length > 0 && (
                <List>
                  This action will:
                  <ul>
                    {effects.map((effect, i) => (
                      <li key={i}>{effect}</li>
                    ))}
                  </ul>
                </List>
              )}
            </ModalBody>
            <Buttons>
              <CancelButton disabled={isConfirming} onClick={onClose}>
                Cancel
              </CancelButton>
              <Button isLoading={isConfirming} className={`button is-${variant}`} onClick={onConfirm}>
                {confirmButtonText || 'Confirm'}
              </Button>
            </Buttons>
          </ModalContent>
        </ModalCardBody>
      </ModalCard>
    </BulmaModal>
  )
}

type ModalIconProps = {
  icon: IconDefinition
  color: string
}

const modalIconPropsByVariant: Record<ActionConfirmationModalVariant, ModalIconProps> = {
  danger: { icon: faCircleXmark, color: '#ff3860' },
  success: { icon: faCircleCheck, color: '#48c78e' },
}
