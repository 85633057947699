import classnames from 'classnames'
import styled from 'styled-components'

interface TagProps {
  isWarning?: boolean
  isLight?: boolean
}

export const Tag = styled.span.attrs(({ isWarning, isLight }: TagProps) => ({
  className: classnames('tag', { 'is-warning': isWarning, 'is-light': isLight }),
}))<TagProps>``
