import { withAuthenticationRequired } from '@auth0/auth0-react'
import React, { ComponentType } from 'react'

import { AwaitAuthentication } from '../../components/await-authentication'
import { useAppSettings } from '../../hooks/use-app-settings'

import { BasePage } from './base-page'

interface Props {
  component: ComponentType
  fixedPageHeight?: boolean
}

export const ProtectedPage = ({ component: Component, fixedPageHeight }: Props) => {
  const page = (
    <BasePage fixedPageHeight={fixedPageHeight}>
      <Component />
    </BasePage>
  )

  const { isStandaloneApplication } = useAppSettings()
  if (!isStandaloneApplication) {
    return page
  }

  const Route = withAuthenticationRequired(() => <AwaitAuthentication>{page}</AwaitAuthentication>)

  return <Route />
}
