import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'
import { TerminalCountryCode } from '../../../types/terminal-country-codes'

import { useQueryOptions } from './use-query-options'

export interface TerminalLocation {
  id: number
  terminalCode: string
  name: string
  countryCode: TerminalCountryCode
  identifier: string
  street: string
  postalCode: string
}

export type GetTerminalLocationsResponse = {
  terminals: TerminalLocation[]
}

export const getTerminalLocationsQueryKey = ['terminal-locations']

const getTerminalLocations = (webapiClient: AxiosInstance) =>
  webapiClient.get<GetTerminalLocationsResponse>('/freightman/terminal-locations')

export const useGetTerminalLocations = () => ({
  ...useQueryOptions(getTerminalLocationsQueryKey, getTerminalLocations, getResponseData),
  staleTime: Infinity,
  cacheTime: Infinity,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
})
