import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { Button, SquareButton } from '../../../../components/bulma/button.styled'
import { CardFooter } from '../../../../components/bulma/card.styled'
import { FieldControl } from '../../../../components/bulma/field.styled'
import { TabItem, Tabs } from '../../../../components/bulma/tabs.styled'
import { CardHeader, CardHeaderTitle, ScrollableCard, ScrollableCardContent } from '../../../../components/card.styled'
import { MultiCountryDropdown } from '../../../../components/dropdown/country-dropdown'
import { MerchantWarehouseSideBox } from '../../../../components/select/select-side-box/merchant-warehouse-side-box/merchant-warehouse-side-box'
import {
  closedContext,
  openContext,
  openMultiContext,
  TerminalOption,
  TerminalSelectorContext,
  TerminalSideBox,
} from '../../../../components/select/select-side-box/terminal-side-box'
import {
  closedMerchantWarehouseContext,
  MerchantWarehouseSelectorContext,
  openMerchantWarehouseContext,
} from '../../../../components/select/select-side-box/terminal-side-box/utils'
import { SimpleMessage } from '../../../../components/simple-message'
import { useEnvironmentVariable } from '../../../../hooks/use-environment-variable'
import { notification } from '../../../../lib/notification'
import { useCreatePalletConfiguration } from '../../../../webapi/hooks/mutations/use-create-pallet-configuration'
import { useEditPalletConfiguration } from '../../../../webapi/hooks/mutations/use-edit-pallet-configuration'
import { MerchantWarehouseLocation } from '../../../../webapi/hooks/queries/use-get-merchant-warehouse-locations'
import { useGetPalletConfigurations } from '../../../../webapi/hooks/queries/use-get-pallet-configurations'
import { useGetTerminalGroups } from '../../../../webapi/hooks/queries/use-get-terminal-groups'
import { TerminalLocation } from '../../../../webapi/hooks/queries/use-get-terminal-locations'
import { LocationType, PalletConfiguration } from '../../../../webapi/types/pallet-configuration'
import { ConfigurationDropdown } from '../configuration-dropdown'

import { BottomLeftWidgetDropdown } from './bottom-left-widget-dropdown'
import { ConfigurationField } from './configuration-field'
import {
  buildPreviewUrl,
  createConfigIfValid,
  determineDefaultTab,
  prettyBottomLeftWidgetName,
  prettyDestinationTypeName,
  prettyEjectionTerminal,
  toEditableEjectionTerminal,
  toSelectOption,
  toSelectOptions,
  toTerminalNames,
} from './configuration-utils'
import { DestinationTypeDropdown } from './destination-type-dropdown'
import { EjectionTerminalDropdown } from './ejection-terminal-dropdown'
import { LocationSelector } from './location-selector'
import { MerchantWarehouseField } from './merchant-warehouse-field'
import { TerminalGroupConfiguration } from './terminal-group-configuration'

const LABEL = 'LABEL'
const APPLIES_TO = 'APPLIES_TO'
const PROPERTIES = 'PROPERTIES'

interface Props {
  terminalLocations: Map<string, TerminalLocation>
  merchantWarehouses: Map<string, MerchantWarehouseLocation>
  config: PalletConfiguration
  onSave: (config: PalletConfiguration) => void
  onClose: () => void
}

const findLocationType = (
  identifier: string | null,
  terminalLocations: Map<string, TerminalLocation>,
  merchantWarehouses: Map<string, MerchantWarehouseLocation>,
  defaultTo: LocationType
): LocationType => {
  if (identifier === null) {
    return defaultTo
  }
  if (terminalLocations.get(identifier)) {
    return LocationType.TERMINAL
  }
  if (merchantWarehouses.get(identifier)) {
    return LocationType.MERCHANT_WAREHOUSE
  }
  return defaultTo
}

export const Configuration = ({ terminalLocations, merchantWarehouses, config, onSave, onClose }: Props) => {
  const webapiUrl = useEnvironmentVariable('WEBAPI_URL')
  const [terminalSelector, setTerminalSelector] = useState<TerminalSelectorContext>(closedContext())
  const [merchantWarehouseSelector, setMerchantWarehouseSelector] = useState<MerchantWarehouseSelectorContext>(
    closedMerchantWarehouseContext()
  )
  const [tab, setTab] = useState(LABEL)
  const [isEditing, setIsEditing] = useState(!config.identifier)
  const [labelUrl, setLabelUrl] = useState<string | null>(null)

  const [code, setCode] = useState(config.code)
  const [name, setName] = useState(config.name)
  const [bottomLeftWidget, setBottomLeftWidget] = useState(config.bottomLeftWidget)
  const [destination, setDestination] = useState<string | null>(config.destination)
  const [destinationType, setDestinationType] = useState<LocationType>(
    findLocationType(config.destination, terminalLocations, merchantWarehouses, LocationType.TERMINAL)
  )
  const [appliesToTerminalCodes, setAppliesToTerminalCodes] = useState(config.appliesTo.terminalCodes)
  const [destinationTypes, setDestinationTypes] = useState(config.properties.destinationTypes)
  const [ejectionCountries, setEjectionCountries] = useState(config.properties.ejectionCountries)
  const [ejectionTerminal, setEjectionTerminal] = useState(
    toEditableEjectionTerminal(config.properties.ejectionTerminal)
  )
  const [returnsToMerchantWarehouse, setReturnsToMerchantWarehouse] = useState(
    config.properties.returnsToMerchantWarehouse
  )

  const terminalGroupsQuery = useQuery({
    ...useGetTerminalGroups(),
    onError: () => notification.error('Failed to load terminal groups'),
  })

  const updatedConfig = createConfigIfValid(
    config.identifier,
    appliesToTerminalCodes,
    name,
    code,
    destination,
    ejectionTerminal,
    ejectionCountries,
    destinationTypes,
    returnsToMerchantWarehouse ? returnsToMerchantWarehouse.locationId : null,
    bottomLeftWidget
  )

  const setConfigurationState = useCallback(
    (config: PalletConfiguration) => {
      setName(config.name)
      setCode(config.code)
      setAppliesToTerminalCodes(config.appliesTo.terminalCodes)
      setDestinationTypes(config.properties.destinationTypes)
      setEjectionTerminal(toEditableEjectionTerminal(config.properties.ejectionTerminal))
      setEjectionCountries(config.properties.ejectionCountries)
      setDestination(config.destination)
      setReturnsToMerchantWarehouse(config.properties.returnsToMerchantWarehouse)
      setDestinationType(
        findLocationType(config.destination, terminalLocations, merchantWarehouses, LocationType.TERMINAL)
      )
    },
    [terminalLocations, merchantWarehouses]
  )

  useEffect(() => {
    setIsEditing(!config.identifier)
    setConfigurationState(config)
    setLabelUrl(null)
  }, [config, setConfigurationState])

  const queryClient = useQueryClient()
  const queryOptions = useGetPalletConfigurations({})
  const onSuccess = (savedConfigurationIdentifier: string) => {
    queryClient
      .fetchQuery(queryOptions)
      .then((data) => {
        const savedConfiguration = data.configs.find((config) => config.identifier === savedConfigurationIdentifier)
        if (savedConfiguration) {
          onSave(savedConfiguration)
        }
      })
      .catch((error) => {
        window.console.error(error)
        notification.error('Failed to reload after saving, please refresh the page')
      })
  }

  const editPalletConfiguration = useMutation({
    ...useEditPalletConfiguration(),
    onSuccess: (_data, variables) => {
      if (variables.config.identifier) {
        onSuccess(variables.config.identifier)
      }
    },
    onError: () => {
      notification.error('Failed to edit pallet config')
    },
  })

  const createPalletConfiguration = useMutation({
    ...useCreatePalletConfiguration(),
    onSuccess: (response) => {
      if (response.config.identifier) {
        onSuccess(response.config.identifier)
      }
    },
    onError: () => {
      notification.error('Failed to create pallet config')
    },
  })

  const save = (config: PalletConfiguration) => {
    if (config.identifier) {
      editPalletConfiguration.mutate({ config })
    } else {
      createPalletConfiguration.mutate({ config })
    }
  }

  const onDiscardChanges = () => {
    setIsEditing(false)
    setConfigurationState(config)
  }

  const clearEjectionTerminal = () => setEjectionTerminal({ relation: null, terminalCode: null, terminalGroupId: null })

  const renderTerminalSelector = (
    terminalCode: string | null,
    onChange: (option: TerminalOption) => void,
    onClear: () => void
  ) => {
    const terminalOption = terminalCode ? toSelectOption(terminalCode, terminalLocations) : null
    setTerminalSelector(openContext(terminalOption, onChange, onClear))
  }

  const renderMultiTerminalSelector = (
    terminalCodes: string[],
    onChange: (terminal: TerminalOption[]) => void,
    onClear: () => void
  ) => {
    const terminalOptions = toSelectOptions(terminalCodes, terminalLocations)
    setTerminalSelector(openMultiContext(terminalOptions, onChange, onClear))
  }

  return (
    <>
      {merchantWarehouseSelector.open ? (
        <MerchantWarehouseSideBox
          onCancel={() => setMerchantWarehouseSelector(closedMerchantWarehouseContext())}
          onClear={() => {
            merchantWarehouseSelector.onClear()
            setMerchantWarehouseSelector(closedMerchantWarehouseContext())
          }}
          onChange={(warehouse) => {
            merchantWarehouseSelector.onChange(warehouse)
            setMerchantWarehouseSelector(closedMerchantWarehouseContext())
          }}
          selectedIdentifier={merchantWarehouseSelector.selected}
        />
      ) : null}
      <TerminalSideBox
        selectedTerminalCodes={terminalSelector.terminals.map((element) => element.code)}
        show={terminalSelector.visible}
        isMultiSelect={terminalSelector.isMulti}
        showClear
        determineDefaultTab={determineDefaultTab}
        onCancel={() => setTerminalSelector(closedContext())}
        onChange={(options) => {
          terminalSelector.onChange(options)
          setTerminalSelector(closedContext())
        }}
        onClear={() => {
          terminalSelector.onClear()
          setTerminalSelector(closedContext())
        }}
      />
      <ScrollableCard>
        <ScrollableCardContent>
          <CardHeader>
            <CardHeaderTitle>Configuration</CardHeaderTitle>
            <div style={{ float: 'right' }}>
              <ConfigurationDropdown config={config} onDelete={onClose} />
            </div>
          </CardHeader>
          <div style={{ height: 15 }}></div>
          <ConfigurationField label={'Name'}>
            <FieldControl>
              {isEditing ? (
                <input className="input is-small" type="text" value={name} onChange={(e) => setName(e.target.value)} />
              ) : (
                <span>{config.name}</span>
              )}
            </FieldControl>
          </ConfigurationField>
          <ConfigurationField label="Destination">
            <LocationSelector
              editMode={isEditing}
              selected={destination}
              selectedType={destinationType}
              onLocationUpdated={(identifier, type) => {
                setDestination(identifier)
                setDestinationType(type)
              }}
              terminalLocations={terminalLocations}
              merchantWarehouseLocations={merchantWarehouses}
              openTerminalSelector={renderTerminalSelector}
              openMerchantWarehouseSelector={(identifier, onChange) =>
                setMerchantWarehouseSelector(
                  openMerchantWarehouseContext(identifier, onChange, () => setDestination(null))
                )
              }
            />
          </ConfigurationField>
          <div style={{ height: 30 }}></div>
          <Tabs>
            <ul>
              <TabItem isActive={tab === LABEL} onClick={() => setTab(LABEL)}>
                <a>Label</a>
              </TabItem>
              <TabItem isActive={tab === APPLIES_TO} onClick={() => setTab(APPLIES_TO)}>
                <a>Applies To</a>
              </TabItem>
              <TabItem isActive={tab === PROPERTIES} onClick={() => setTab(PROPERTIES)}>
                <a>Properties</a>
              </TabItem>
            </ul>
          </Tabs>
          {tab === LABEL && (
            <>
              <ConfigurationField label="Code">
                <FieldControl>
                  {isEditing ? (
                    <input
                      className="input is-small"
                      type="text"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  ) : (
                    <span>{config.code}</span>
                  )}
                </FieldControl>
              </ConfigurationField>
              <ConfigurationField label="Bottom Left Widget">
                {isEditing ? (
                  <BottomLeftWidgetDropdown
                    selected={bottomLeftWidget}
                    onChange={(value) => setBottomLeftWidget(value)}
                  />
                ) : (
                  <span>{prettyBottomLeftWidgetName(config.bottomLeftWidget)}</span>
                )}
              </ConfigurationField>
              <ConfigurationField label={'Preview'}>
                <Button
                  isSmall
                  onClick={() => {
                    const destinationName = destination
                      ? terminalLocations.get(destination)?.name ??
                        merchantWarehouses.get(destination)?.name ??
                        'Unknown'
                      : ''
                    setLabelUrl(buildPreviewUrl(webapiUrl, destinationName, code, destinationTypes, bottomLeftWidget))
                  }}
                >
                  {labelUrl == null ? 'Show' : 'Update'} Preview
                </Button>
              </ConfigurationField>
              {labelUrl && <img src={labelUrl} height="300" width="450" style={{ border: 'solid thin black' }} />}
            </>
          )}
          {tab === APPLIES_TO && (
            <ConfigurationField label="Terminals">
              <FieldControl>
                <div className="field is-small has-addons">
                  <FieldControl style={{ flex: 1 }}>
                    {isEditing ? (
                      <input
                        className="input is-small"
                        type="text"
                        style={{ flex: 1 }}
                        value={toTerminalNames(appliesToTerminalCodes, terminalLocations)}
                        readOnly
                      />
                    ) : (
                      <span>{toTerminalNames(config.appliesTo.terminalCodes, terminalLocations)}</span>
                    )}
                  </FieldControl>
                  {isEditing && (
                    <FieldControl>
                      <button
                        className="button is-small"
                        onClick={() =>
                          renderMultiTerminalSelector(
                            appliesToTerminalCodes,
                            (terminals) => setAppliesToTerminalCodes(terminals.map((t) => t.code)),
                            () => setAppliesToTerminalCodes([])
                          )
                        }
                      >
                        Select Terminal
                      </button>
                    </FieldControl>
                  )}
                </div>
              </FieldControl>
            </ConfigurationField>
          )}
          {tab === PROPERTIES && (
            <>
              {isEditing && (
                <SimpleMessage isInfo isSmall>
                  Properties Criteria:
                  <br /> — Either ejection terminal, ejection country, or returns to merchant warehouse must be
                  configured
                  <br /> — Ejection terminal and ejection country are mutually exclusive
                </SimpleMessage>
              )}
              <ConfigurationField label="Ejection Terminal">
                <FieldControl>
                  {isEditing && (
                    <div className="field is-small has-addons">
                      <FieldControl>
                        <EjectionTerminalDropdown
                          selected={ejectionTerminal.relation}
                          disabled={ejectionCountries.length !== 0}
                          onChange={(relation) => {
                            if (relation) {
                              setEjectionTerminal({ relation, terminalCode: null, terminalGroupId: null })
                              setEjectionCountries([])
                            } else {
                              clearEjectionTerminal()
                            }
                          }}
                        />
                      </FieldControl>
                      {ejectionTerminal.relation === 'IN_TERMINAL_GROUP' ? (
                        <TerminalGroupConfiguration
                          ejectionTerminalGroup={ejectionTerminal}
                          terminalGroups={terminalGroupsQuery.data?.groups}
                          setEjectionTerminal={setEjectionTerminal}
                        />
                      ) : (
                        <>
                          <FieldControl style={{ flex: 1 }}>
                            <input
                              className="input is-small"
                              type="text"
                              style={{ flex: 1 }}
                              disabled={!ejectionTerminal.relation || ejectionCountries.length > 0}
                              value={
                                ejectionTerminal.terminalCode
                                  ? toTerminalNames([ejectionTerminal.terminalCode], terminalLocations)
                                  : ''
                              }
                              readOnly
                            />
                          </FieldControl>
                          <FieldControl>
                            <button
                              className="button is-small"
                              disabled={!ejectionTerminal.relation || ejectionCountries.length > 0}
                              onClick={() =>
                                renderTerminalSelector(
                                  ejectionTerminal.terminalCode,
                                  (terminalOption) => {
                                    const location = terminalLocations.get(terminalOption.code)
                                    if (location) {
                                      setEjectionTerminal({ ...ejectionTerminal, terminalCode: location.terminalCode })
                                      setDestination(location.identifier)
                                    }
                                  },
                                  () => {
                                    setEjectionTerminal({ ...ejectionTerminal, terminalCode: null })
                                    setDestination(null)
                                  }
                                )
                              }
                            >
                              Select Terminal
                            </button>
                          </FieldControl>
                        </>
                      )}
                    </div>
                  )}
                  {!isEditing && !config.properties.ejectionTerminal && (
                    <span>
                      <i>Not Applicable</i>
                    </span>
                  )}
                  {!isEditing && config.properties.ejectionTerminal && (
                    <span>
                      {prettyEjectionTerminal(
                        config.properties.ejectionTerminal,
                        terminalLocations,
                        terminalGroupsQuery.data?.groups
                      )}
                    </span>
                  )}
                </FieldControl>
              </ConfigurationField>
              <ConfigurationField label="Ejection Country">
                <FieldControl>
                  {isEditing && (
                    <MultiCountryDropdown
                      selected={ejectionCountries}
                      disabled={ejectionTerminal.relation !== null}
                      onSelected={(countries) => {
                        setEjectionCountries(countries)
                        clearEjectionTerminal()
                      }}
                      isStretched
                      isMenuStretched
                    />
                  )}
                  {!isEditing && config.properties.ejectionCountries.length === 0 && (
                    <span>
                      <i>Not Applicable</i>
                    </span>
                  )}
                  {!isEditing && config.properties.ejectionCountries.length !== 0 && (
                    <span>{config.properties.ejectionCountries.join(' ')}</span>
                  )}
                </FieldControl>
              </ConfigurationField>
              <ConfigurationField label="Returns to Merchant Warehouse">
                <MerchantWarehouseField
                  editMode={isEditing}
                  merchantWarehouseLocations={merchantWarehouses}
                  selected={returnsToMerchantWarehouse?.locationId ?? null}
                  onLocationUpdated={(warehouse) => setReturnsToMerchantWarehouse({ locationId: warehouse.identifier })}
                  openMerchantWarehouseSelector={(selected, onChange) =>
                    setMerchantWarehouseSelector(
                      openMerchantWarehouseContext(selected, onChange, () => setReturnsToMerchantWarehouse(null))
                    )
                  }
                />
              </ConfigurationField>
              <ConfigurationField label="Destination Type">
                <FieldControl>
                  {isEditing ? (
                    <DestinationTypeDropdown
                      selected={destinationTypes}
                      onChange={(types) => setDestinationTypes(types)}
                    />
                  ) : (
                    <span>
                      {config.properties.destinationTypes.map((type) => prettyDestinationTypeName(type)).join(' ')}
                    </span>
                  )}
                </FieldControl>
              </ConfigurationField>
            </>
          )}
        </ScrollableCardContent>
        {isEditing ? (
          <CardFooter>
            <div style={{ flex: 1 }}></div>
            <SquareButton
              isLight
              isRounded={false}
              style={{ width: 150 }}
              disabled={editPalletConfiguration.isLoading || createPalletConfiguration.isLoading}
              onClick={() => {
                if (config.identifier) {
                  onDiscardChanges()
                } else {
                  onClose()
                }
              }}
            >
              Discard
            </SquareButton>
            <SquareButton
              isPrimary
              isRounded={false}
              style={{ width: 150 }}
              disabled={editPalletConfiguration.isLoading || createPalletConfiguration.isLoading || !updatedConfig}
              onClick={() => {
                if (updatedConfig) {
                  save(updatedConfig)
                }
              }}
            >
              Save
            </SquareButton>
          </CardFooter>
        ) : (
          <CardFooter>
            <div style={{ flex: 1 }}></div>
            <SquareButton isLight isRounded={false} style={{ width: 150 }} onClick={onClose}>
              Close
            </SquareButton>
            <SquareButton isInfo isRounded={false} style={{ width: 150 }} onClick={() => setIsEditing(true)}>
              Edit
            </SquareButton>
          </CardFooter>
        )}
      </ScrollableCard>
    </>
  )
}
