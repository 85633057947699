import React, { useState } from 'react'

import { FieldControl } from '../../../../components/bulma/field.styled'
import { EjectionTerminal, EjectionTerminalRelation } from '../../../../webapi/types/pallet-configuration'
import { TerminalGroup } from '../../../../webapi/types/terminal-groups'

import { EditableEjectionTerminal } from './configuration-utils'
import { TerminalGroupSidebox } from './terminal-group-side-box'

interface Props {
  ejectionTerminalGroup: EditableEjectionTerminal
  terminalGroups?: TerminalGroup[]
  setEjectionTerminal: (ejectionTerminal: EjectionTerminal) => void
}

export const TerminalGroupConfiguration = ({ ejectionTerminalGroup, terminalGroups, setEjectionTerminal }: Props) => {
  const [isGroupSelectorVisible, setIsGroupSelectorVisible] = useState(false)
  const hideTerminalGroupSelector = () => setIsGroupSelectorVisible(false)
  const showTerminalGroupSelector = () => setIsGroupSelectorVisible(true)

  return (
    <>
      <FieldControl style={{ flex: 1 }}>
        <input
          readOnly
          className="input is-small"
          type="text"
          style={{ flex: 1 }}
          value={
            ejectionTerminalGroup.terminalGroupId
              ? terminalGroups?.find((group) => group.identifier === ejectionTerminalGroup.terminalGroupId)?.name
              : ''
          }
        />
      </FieldControl>
      <FieldControl>
        <button className="button is-small" onClick={showTerminalGroupSelector}>
          Select Group
        </button>
      </FieldControl>
      {isGroupSelectorVisible && (
        <TerminalGroupSidebox
          terminalGroupIdentifier={ejectionTerminalGroup.terminalGroupId}
          onCancel={hideTerminalGroupSelector}
          onSelect={(terminalGroupId) => {
            hideTerminalGroupSelector()
            setEjectionTerminal({
              relation: EjectionTerminalRelation.IN_TERMINAL_GROUP,
              terminalGroupId,
              terminalCode: null,
            })
          }}
        />
      )}
    </>
  )
}
