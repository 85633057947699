import React, { ReactNode } from 'react'

import { SelectTabs, SelectTabsList } from './select-side-box.styled'

interface Props {
  children: ReactNode
}

export const Tabs = ({ children }: Props) => (
  <SelectTabs>
    <SelectTabsList>{children}</SelectTabsList>
  </SelectTabs>
)
