import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Navigate, useParams } from 'react-router-dom'

import { Card, CardContent } from '../../../components/bulma/card.styled'
import { CardLoader } from '../../../components/card-loader.styled'
import {
  CardHeader,
  CardHeaderButton,
  CardHeaderButtonGroup,
  CardHeaderButtonIcon,
  CardHeaderButtons,
  CardHeaderTitle,
} from '../../../components/card.styled'
import { MerchantSelect, MerchantOption } from '../../../components/select'
import { usePage } from '../../../hooks/use-page'
import { getStoredMerchantIds, setItem } from '../../../lib/local-storage'
import { paramToPhase, paramToTerminalCode } from '../../../lib/url-parameters'
import { getStartUrl } from '../../../lib/urls'
import { useGetMerchantDropOffParcels } from '../../../webapi/hooks/queries/use-get-merchant-drop-off-parcels'
import { useGetTerminals } from '../../../webapi/hooks/queries/use-get-terminals'
import { getTerminalByCode, getTerminalNames } from '../../../webapi/terminals'
import { merchantSelectDashboardKey } from '../local-storage-keys'

import { phaseDescription } from './phase-description'
import { Message, StyledPhaseTable } from './phase.styled'

type PhaseUrlParameters = {
  phase: string
  terminalCode: string
}

const pageSize = 50
const defaultPage = { size: pageSize }

export const Phase = () => {
  const [selectedMerchantIds, setSelectedMerchantIds] = useState(() => getStoredMerchantIds(merchantSelectDashboardKey))
  const [page, setPage] = usePage(defaultPage)

  const params = useParams<PhaseUrlParameters>()
  const phase = paramToPhase(params.phase)
  const terminalCode = paramToTerminalCode(params.terminalCode)

  const {
    data: terminals,
    isSuccess: isTerminalsSuccess,
    isError: isTerminalsError,
    isLoading: isTerminalsLoading,
  } = useQuery(useGetTerminals())

  const {
    data: merchantDropOffParcels,
    isSuccess: isMerchantDropOffParcelsSuccess,
    isError: isMerchantDropOffParcelsError,
    isLoading: isMerchantDropOffParcelsLoading,
    isPreviousData: isMerchantDropOffParcelsPrevious,
  } = useQuery({
    ...useGetMerchantDropOffParcels({
      page,
      terminal: terminalCode,
      merchantIds: selectedMerchantIds,
      phase,
    }),
    enabled: Boolean(terminalCode && phase),
    keepPreviousData: true,
  })

  const terminal = useMemo(() => getTerminalByCode(terminalCode, terminals), [terminalCode, terminals])
  const terminalNames = useMemo(() => getTerminalNames(terminals), [terminals])
  const isInvalidTerminal = isTerminalsSuccess && !terminal

  const isError = isTerminalsError || isMerchantDropOffParcelsError
  const isLoading = isTerminalsLoading || isMerchantDropOffParcelsLoading
  const isSuccess = isMerchantDropOffParcelsSuccess && isTerminalsSuccess

  const showError = !isLoading && isError

  const isNextDisabled =
    !merchantDropOffParcels?.nextCursor || (isMerchantDropOffParcelsPrevious && (!page || page?.direction === 'NEXT'))

  const onSelectedMerchants = (merchantOptions: MerchantOption[]) => {
    const merchantIds = merchantOptions.map(({ id }) => id)
    setItem(merchantSelectDashboardKey, merchantIds)
    setSelectedMerchantIds(merchantIds)
    setPage(defaultPage)
  }

  const onNext = () => {
    if (merchantDropOffParcels?.nextCursor) {
      setPage({
        cursor: merchantDropOffParcels.nextCursor,
        direction: 'NEXT',
        size: pageSize,
      })
    }
  }

  if (!phase || isInvalidTerminal) {
    return <Navigate replace to={getStartUrl()} />
  }

  return (
    <Card>
      <CardContent>
        <CardHeader>
          <CardHeaderTitle>
            {phaseDescription[phase]} {terminal?.name}
          </CardHeaderTitle>
          <CardHeaderButtons>
            <CardHeaderButtonGroup>
              <CardHeaderButton disabled={isNextDisabled} onClick={onNext}>
                Next
                <CardHeaderButtonIcon placement="right" icon={faChevronRight} />
              </CardHeaderButton>
              <MerchantSelect selectedMerchantIds={selectedMerchantIds} onChange={onSelectedMerchants} />
            </CardHeaderButtonGroup>
          </CardHeaderButtons>
        </CardHeader>
        {showError && <Message isDanger>Failed to load.</Message>}
        {isLoading && <CardLoader />}
        {isSuccess &&
          (merchantDropOffParcels?.parcels.length ? (
            <StyledPhaseTable data={merchantDropOffParcels} phase={phase} terminalNames={terminalNames} />
          ) : (
            <Message isInfo>No parcels found.</Message>
          ))}
      </CardContent>
    </Card>
  )
}
