import { isValid } from 'date-fns'

import { MerchantDropOffParcelsPhase } from '../webapi/hooks/queries/use-get-merchant-drop-off-parcels'

import { isDashSeparatedString, isNonNegativeInteger, isNotNull } from './validation'

export const paramsToIds = (inputs: string[]) => inputs.filter(isNonNegativeInteger).map(Number)

export const paramToTerminalCode = (input = '') =>
  isDashSeparatedString(input) ? input.toUpperCase().replaceAll('-', '_') : null

export const terminalCodeToParam = (input: string) => input.toLowerCase().replaceAll('_', '-')

export const paramsToTerminalCodes = (inputs: string[]) => inputs.map(paramToTerminalCode).filter(isNotNull)

export const terminalCodesToParams = (inputs: string[]) => inputs.map(terminalCodeToParam)

export const paramToPhase = (input = '') => {
  const value = input.toUpperCase().replaceAll('-', '_')
  return Object.values(MerchantDropOffParcelsPhase).map(String).includes(value)
    ? (value as MerchantDropOffParcelsPhase)
    : null
}

export const phaseToParam = (input: MerchantDropOffParcelsPhase) => input.toLowerCase().replaceAll('_', '-')

export const paramToEpochMSTimestamp = (input: string | null) => {
  if (input && isNonNegativeInteger(input)) {
    const epochMSTimestamp = Number(input)
    if (isValid(new Date(epochMSTimestamp))) {
      return epochMSTimestamp
    }
  }
  return undefined
}
