import { AxiosInstance } from 'axios'

import { MerchantWarehouse } from '../../types/merchant-warehouses'

import { useMutationOptionsWithVariables } from './use-mutation-options'

interface DeleteCollectionPlacePointVariables {
  warehouse: MerchantWarehouse
}

const deleteCollectionPlacePoint = (webapiClient: AxiosInstance, variables: DeleteCollectionPlacePointVariables) =>
  webapiClient.delete<void>(`/freightman/collection-place-points/${variables.warehouse.identifier}`)

export const useDeleteCollectionPlacePoint = () => useMutationOptionsWithVariables(deleteCollectionPlacePoint)
