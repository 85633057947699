import React from 'react'

import { Table, TableRow } from '../../../components/bulma/table.styled'
import { StickyTableRow } from '../../../components/sticky-table-row.styled'
import { TableCell } from '../../../components/table-cell'
import { Device } from '../../../webapi/hooks/queries/use-get-devices'

import { DeviceActiveIcon, DeviceInactiveIcon, DeviceLowBatteryIcon } from './battery-dashboard.styled'
import { formatDate } from './format-time'

interface Props {
  data: Device[] | undefined
}

export const BatteryTerminalTable = (data: Props) => {
  const twoDaysInMs = 2 * 24 * 60 * 60 * 1000
  const inactiveCutoff = Date.now() - twoDaysInMs
  const formatDateTime = (timeUnix: number | null) => {
    if (timeUnix) {
      return formatDate(timeUnix)
    }
    return '-'
  }
  const formatBattery = (battery: number | null) => {
    if (battery) {
      return `${battery} %`
    }
    return `-`
  }

  const getIcon = (device: Device) => {
    if (device.batteryUpdatedAt === null || device.batteryUpdatedAt < inactiveCutoff) {
      return <DeviceInactiveIcon />
    }
    if (device.batteryLevel !== null && device.batteryLevel < 30) {
      return <DeviceLowBatteryIcon />
    }
    return <DeviceActiveIcon />
  }

  return (
    <div>
      <>Number of devices: {data.data?.length}</>
      <Table isFullwidth>
        <thead>
          <StickyTableRow>
            <th>Status</th>
            <th>Terminal</th>
            <th>Device ID</th>
            <th>Battery level</th>
            <th>Updated at</th>
          </StickyTableRow>
        </thead>
        <tbody>
          {data.data &&
            data.data.map((device) => (
              <TableRow key={device.deviceId}>
                <TableCell data={getIcon(device)} />
                <TableCell data={device.terminal?.terminalName || '-'} />
                <TableCell data={device.friendlyId} />
                <TableCell data={formatBattery(device.batteryLevel)} />
                <TableCell data={formatDateTime(device.batteryUpdatedAt)} />
              </TableRow>
            ))}
        </tbody>
      </Table>
    </div>
  )
}
