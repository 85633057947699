import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { SimpleMessage } from '../../../components/simple-message'

import { ConsignmentsBookingTable } from './consignments-booking-table'

export const StyledConsignmentsBookingTable = styled(ConsignmentsBookingTable).attrs(() => ({
  className: 'mt-5',
}))``

export const Message = styled(SimpleMessage).attrs({ className: 'mt-4' })``
export const SyncIcon = styled(FontAwesomeIcon)<{ $isSyncing?: boolean }>`
  margin-right: 0.5rem;
  animation: ${(props) => (props.$isSyncing ? 'spin 1s linear infinite' : 'none')};

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`

export const ActionsHeaderColumn = styled.th`
  width: 9rem;
`

export const LockedConsignmentCandidate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.45);
  svg {
    margin-right: 0.5rem;
  }
`
