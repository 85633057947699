import { useEffect } from 'react'

export const useClickOutside = (ref: React.RefObject<HTMLElement>, callback: () => void, isActive = true) => {
  useEffect(() => {
    const onMouseDown = (event: MouseEvent) => {
      const hasClickedElement = event.target instanceof Element
      const hasClickedOutside = hasClickedElement && ref.current && !ref.current.contains(event.target)
      if (hasClickedOutside) {
        callback()
      }
    }

    if (isActive) {
      document.addEventListener('mousedown', onMouseDown)
    }
    return () => {
      if (isActive) {
        document.removeEventListener('mousedown', onMouseDown)
      }
    }
  }, [ref, callback, isActive])
}
