import { faAngleDown, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import {
  ActionConfirmationModal,
  Merchant,
} from '../../../components/action-confirmation-modal/action-confirmation-modal'
import { Button } from '../../../components/bulma/button.styled'
import { Dropdown, DropdownElement } from '../../../components/dropdown'
import {
  MerchantOption,
  MerchantSideBox,
} from '../../../components/select/select-side-box/merchant-side-box/merchant-side-box'
import { Icon } from '../../../components/select/select.styled'
import { notification } from '../../../lib/notification'
import { useBookConsignmentCandidate } from '../../../webapi/hooks/mutations/use-book-consignment-candidate'
import {
  ConsignmentsBookingCandidate,
  getConsignmentsCandidatesKey,
} from '../../../webapi/hooks/queries/use-get-consignments-candidates'

import { LockedConsignmentCandidate } from './consignments-booking.styled'

interface Props extends Pick<ConsignmentsBookingCandidate, 'locked' | 'terminalCode'> {
  terminalNames: Record<string, string>
  disabled?: boolean
}

enum BookType {
  ALL = 'ALL',
  MERCHANTS = 'MERCHANTS',
}

export const BookConsignmentActionButton: FC<Props> = ({ locked, terminalCode, disabled = false, terminalNames }) => {
  const terminalName = terminalNames[terminalCode]
  const [showModal, setShowModal] = useState(false)
  const [selectedMerchants, setSelectedMerchants] = useState<Merchant[]>([])
  const [isMerchantSelectorVisible, setIsMerchantSelectorVisible] = useState(false)
  const queryClient = useQueryClient()
  const { mutate: mutateBookConsignmentCandidate, isLoading } = useMutation({
    ...useBookConsignmentCandidate(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          Array.isArray(query.queryKey[0]) && query.queryKey[0][0].includes(getConsignmentsCandidatesKey[0]),
      })
      notification.success('Consignments booked successfully for ' + terminalName, { position: 'bottom-center' })
    },
    onSettled: () => setShowModal(false),
  })

  const handleBookConsignments = (terminalCode: string) => () =>
    mutateBookConsignmentCandidate({
      terminalCode: terminalCode,
      merchantIds: selectedMerchants.map((merchant) => merchant.id),
    })

  if (locked) {
    return (
      <LockedConsignmentCandidate>
        <FontAwesomeIcon size="sm" icon={faLock} />
        <span>Locked</span>
      </LockedConsignmentCandidate>
    )
  }

  const bookAssigmentsEffects = [
    'Book consignments for TODAYS delivery interval',
    'Book the consignments for today If you book AFTER routing deadline',
    'Not book consignment beyond the limit you have set',
  ]

  const renderButton = (toggle: () => void) => (
    <Button
      isSmall
      isLoading={isLoading}
      name="book-consignments-button"
      disabled={disabled}
      aria-haspopup="true"
      aria-controls="dropdown-menu"
      style={{ width: '75px', display: 'flex', justifyContent: 'space-between' }}
      onClick={() => toggle()}
    >
      Book
      <span className="icon is-small">
        <Icon icon={faAngleDown} />
      </span>
    </Button>
  )
  const elements = [
    {
      name: 'All',
      code: BookType.ALL,
    },
    {
      name: 'Merchant',
      code: BookType.MERCHANTS,
    },
  ]
  const onSelected = (element: DropdownElement) => {
    if (element.code === BookType.ALL) {
      setShowModal(true)
    } else if (element.code === BookType.MERCHANTS) {
      setIsMerchantSelectorVisible(true)
    }
  }

  const onSelectedMerchants = (merchants: MerchantOption[]) => {
    setSelectedMerchants(merchants.map((merchant) => ({ id: merchant.id, name: merchant.name })))
    setIsMerchantSelectorVisible(false)
    setShowModal(true)
  }
  const onCancelBook = () => {
    setSelectedMerchants([])
    setShowModal(false)
  }
  return (
    <>
      <Dropdown renderButton={renderButton} elements={[elements]} onSelected={onSelected} />
      <MerchantSideBox
        show={isMerchantSelectorVisible}
        onLoad={() => null}
        onCancel={() => setIsMerchantSelectorVisible(false)}
        onChange={onSelectedMerchants}
        initialSelection={[]}
      />
      {showModal && (
        <ActionConfirmationModal
          variant="success"
          confirmButtonText="Yes! book them"
          isConfirming={isLoading}
          effects={bookAssigmentsEffects}
          merchants={selectedMerchants}
          onClose={onCancelBook}
          onConfirm={handleBookConsignments(terminalCode)}
        >
          Do you really want to book consignments for the <b>{terminalName}</b> terminal{' '}
          {selectedMerchants.length == 0 ? '' : ' and merchants'}?
        </ActionConfirmationModal>
      )}
    </>
  )
}
