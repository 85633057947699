import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

import { Button } from '../../../../components/bulma/button.styled'
import { Dropdown } from '../../../../components/dropdown'
import { Icon } from '../../../../components/select/select.styled'
import { LocationType } from '../../../../webapi/types/pallet-configuration'

const ELEMENTS = [
  [
    { name: 'Terminal', code: LocationType.TERMINAL },
    {
      name: 'Merchant Warehouse',
      code: LocationType.MERCHANT_WAREHOUSE,
    },
  ],
]

const ELEMENT_OPTS = ELEMENTS.flat()

interface Props {
  disabled?: boolean
  selected: LocationType
  onSelected: (type: LocationType) => void
}

export const LocationTypeDropdown = ({ disabled, selected, onSelected }: Props) => {
  const selectedOpt = ELEMENT_OPTS.find((element) => element.code === selected) || ELEMENT_OPTS[0]
  const renderButton = (toggle: () => void) => {
    return (
      <Button isSmall onClick={toggle} aria-haspopup="true" aria-controls="dropdown-menu" disabled={disabled}>
        <span>{selectedOpt.name}</span>
        {!disabled && (
          <span className="icon is-small">
            <Icon icon={faAngleDown} />
          </span>
        )}
      </Button>
    )
  }
  return (
    <Dropdown
      renderButton={renderButton}
      elements={ELEMENTS}
      onSelected={(opt) => {
        const element = ELEMENT_OPTS.find((elmt) => elmt.code === opt.code)
        if (element) {
          onSelected(element.code)
        }
      }}
      selected={selectedOpt}
    />
  )
}
