import React, { ReactNode } from 'react'

import { Menu } from '../../components/menu'
import { useCarriersMenuHeight } from '../../hooks/use-carriers-menu-height'

import { BasePage as StyledBasePage } from './base-page.styled'
import { Section } from './base-page.styled'

interface Props {
  children: ReactNode
  fixedPageHeight?: boolean
}

export const BasePage = ({ children, fixedPageHeight }: Props) => {
  const menuHeight = useCarriersMenuHeight()
  return (
    <StyledBasePage fixedPageHeight={fixedPageHeight} carriersMenuHeight={menuHeight}>
      <Menu isScrollable={fixedPageHeight} />
      <Section>{children}</Section>
    </StyledBasePage>
  )
}
