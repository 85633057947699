import raw from 'raw.macro'
import { useLayoutEffect } from 'react'

export const useBulma = () => {
  useLayoutEffect(() => {
    const bulmaStylesheet = document.createElement('style')
    bulmaStylesheet.innerText = raw('bulma/css/bulma.min.css')
    document.head.append(bulmaStylesheet)

    const bulmaListStylesheet = document.createElement('style')
    bulmaListStylesheet.innerText = raw('bulma-list/css/bulma-list.css')
    document.head.append(bulmaListStylesheet)

    return () => {
      document.head.removeChild(bulmaStylesheet)
      document.head.removeChild(bulmaListStylesheet)
    }
  }, [])
}
