import React from 'react'

import { FieldControl } from '../../../../components/bulma/field.styled'
import { TerminalOption } from '../../../../components/select'
import { MerchantWarehouseLocation } from '../../../../webapi/hooks/queries/use-get-merchant-warehouse-locations'
import { TerminalLocation } from '../../../../webapi/hooks/queries/use-get-terminal-locations'
import { LocationType } from '../../../../webapi/types/pallet-configuration'

import { LocationTypeDropdown } from './location-type-dropdown'

interface Props {
  editMode: boolean
  terminalLocations: Map<string, TerminalLocation>
  merchantWarehouseLocations: Map<string, MerchantWarehouseLocation>
  selected: string | null
  selectedType: LocationType
  onLocationUpdated: (identifier: string | null, type: LocationType) => void
  openTerminalSelector: (
    terminalCode: string | null,
    onChange: (option: TerminalOption) => void,
    onClear: () => void
  ) => void
  openMerchantWarehouseSelector: (
    identifier: string | null,
    onChange: (warehouse: MerchantWarehouseLocation) => void,
    onClear: () => void
  ) => void
}

export const LocationSelector = (props: Props) => {
  const {
    editMode,
    terminalLocations,
    merchantWarehouseLocations,
    selected,
    selectedType,
    openTerminalSelector,
    onLocationUpdated,
    openMerchantWarehouseSelector,
  } = props
  let destinationName = ''
  if (selectedType === LocationType.TERMINAL && selected) {
    destinationName = terminalLocations.get(selected)?.name ?? ''
  }
  if (selectedType === LocationType.MERCHANT_WAREHOUSE && selected) {
    destinationName = merchantWarehouseLocations.get(selected)?.name ?? ''
  }
  const selectTerminal = () => {
    const onTerminalSelected = (opt: TerminalOption) => {
      const location = terminalLocations.get(opt.code)
      if (location) {
        onLocationUpdated(location.identifier, selectedType)
      }
    }
    const terminalCode = selected ? terminalLocations.get(selected)?.terminalCode : null
    openTerminalSelector(terminalCode || null, onTerminalSelected, () => null)
  }

  const selectMerchantWarehouse = () => {
    openMerchantWarehouseSelector(
      selected,
      (warehouse) => onLocationUpdated(warehouse.identifier, selectedType),
      () => null
    )
  }

  const onLocationTypeSelected = (type: LocationType) => {
    if (type === selectedType) {
      return
    }
    onLocationUpdated(null, type)
  }

  let selectText = ''
  let selectAction: () => void = () => null
  if (selectedType === LocationType.TERMINAL) {
    selectText = 'Select Terminal'
    selectAction = selectTerminal
  }
  if (selectedType === LocationType.MERCHANT_WAREHOUSE) {
    selectText = 'Select Warehouse'
    selectAction = selectMerchantWarehouse
  }

  return (
    <FieldControl>
      <div className="field is-small has-addons">
        <FieldControl>
          <LocationTypeDropdown disabled={!editMode} selected={selectedType} onSelected={onLocationTypeSelected} />
        </FieldControl>
        <FieldControl style={{ flex: 1 }}>
          <input className="input is-small" type="text" style={{ flex: 1 }} value={destinationName} readOnly />
        </FieldControl>
        <FieldControl>
          <button className="button is-small" onClick={selectAction} disabled={!editMode}>
            {selectText}
          </button>
        </FieldControl>
      </div>
    </FieldControl>
  )
}
