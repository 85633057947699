import { sortBy } from 'ramda'

import { Merchant } from '../../../../webapi/hooks/queries/use-get-merchants'

export const createSelectOptions = (merchants: Merchant[] = [], merchantIds?: number[]) => {
  const filteredMerchants = merchantIds ? merchants.filter(({ id }) => merchantIds.includes(id)) : merchants
  const sortedMerchants = merchantIds
    ? sortBy(({ id }) => merchantIds.indexOf(id), filteredMerchants)
    : filteredMerchants

  return sortedMerchants.map(({ id, name, externalName }) => ({
    id,
    name,
    externalName,
  }))
}

export const getFooterLabel = (selectionCount: number) => {
  switch (selectionCount) {
    case 0:
      return 'Show all merchants'
    case 1:
      return 'Show 1 merchant'
    default:
      return `Show ${selectionCount} merchants`
  }
}
