import { GetTerminalsResponse } from './hooks/queries/use-get-terminals'

export const getTerminalByCode = (code: string | null, terminals?: GetTerminalsResponse) =>
  terminals?.find((terminal) => terminal.code === code)

export const getTerminalNames = (terminals: GetTerminalsResponse = []) => {
  const memo: Record<string, string> = {}
  return terminals.reduce((memo, terminal) => {
    memo[terminal.code] = terminal.name
    return memo
  }, memo)
}

export const getTerminalCodes = (terminals: GetTerminalsResponse = []) => terminals.map(({ code }) => code)
