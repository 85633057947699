import React from 'react'
import { useLocation } from 'react-router-dom'

import { MenuContainer, StyledLink } from './menu.styled'

interface Props {
  isScrollable?: boolean
}

export const Menu = ({ isScrollable }: Props) => {
  const { pathname } = useLocation()
  return (
    <MenuContainer isScrollable={isScrollable} className="menu">
      <p className="menu-label">General</p>
      <ul className="menu-list">
        <li>
          <StyledLink to="/" $isActive={pathname === '/'}>
            Start
          </StyledLink>
        </li>
      </ul>
      <p className="menu-label">Merchant Return</p>
      <ul className="menu-list">
        <li>
          <StyledLink to="/merchant-return/dashboard" $isActive={pathname.startsWith('/merchant-return/dashboard')}>
            Dashboard
          </StyledLink>
        </li>
        <li>
          <StyledLink to="/merchant-return/booking" $isActive={pathname === '/merchant-return/booking'}>
            Book On-Demand
          </StyledLink>
        </li>
      </ul>
      <p className="menu-label">Consignments</p>
      <ul className="menu-list">
        <li>
          <StyledLink to="/consignments/booking" $isActive={pathname === '/consignments/booking'}>
            Booking
          </StyledLink>
        </li>
      </ul>
      <p className="menu-label">Locations</p>
      <ul className="menu-list">
        <li>
          <StyledLink to="/locations/terminal-groups" $isActive={pathname === '/locations/terminal-groups'}>
            Terminal Groups
          </StyledLink>
          <StyledLink to="/locations/merchant-warehouses" $isActive={pathname === '/locations/merchant-warehouses'}>
            Merchant Warehouses
          </StyledLink>
        </li>
      </ul>
      <p className="menu-label">Pallets</p>
      <ul className="menu-list">
        <li>
          <StyledLink to="/pallets/terminal-relations" $isActive={pathname === '/pallets/terminal-relations'}>
            Terminal Relations
          </StyledLink>
        </li>
        <li>
          <StyledLink to="/pallets/configurations" $isActive={pathname === '/pallets/configurations'}>
            Configurations
          </StyledLink>
        </li>
      </ul>
      <p className="menu-label">Routing</p>
      <ul className="menu-list">
        <li>
          <StyledLink to="/routing/segments" $isActive={pathname === '/routing/segments'}>
            Segments
          </StyledLink>
        </li>
      </ul>
      <p className="menu-label">Tracking</p>
      <ul className="menu-list">
        <li>
          <StyledLink to="/gps-tracking/device" $isActive={pathname === '/gps-tracking/device'}>
            GPS Devices
          </StyledLink>
        </li>
        <li>
          <StyledLink to="/gps-tracking/battery" $isActive={pathname === '/gps-tracking/battery'}>
            Battery Dashboard
          </StyledLink>
        </li>
        <li>
          <StyledLink to="/gps-tracking/position" $isActive={pathname === '/gps-tracking/position'}>
            Positions
          </StyledLink>
        </li>
      </ul>
    </MenuContainer>
  )
}
