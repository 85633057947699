import React from 'react'

import { Delete } from '../../bulma/button.styled'
import { TitleBar as StyledTitleBar } from '../../side-box.styled'

import { CloseButton, Title } from './select-side-box.styled'

interface Props {
  title: string
  onClose: () => void
}

export const TitleBar = ({ title, onClose }: Props) => (
  <StyledTitleBar>
    <Title>{title}</Title>
    <CloseButton onClick={onClose}>
      <Delete />
    </CloseButton>
  </StyledTitleBar>
)
