import { faPlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { Button } from '../../../components/bulma/button.styled'
import { Card, CardContent } from '../../../components/bulma/card.styled'
import { CardHeader, CardHeaderButtons, CardHeaderTitle } from '../../../components/card.styled'
import { Icon } from '../../../components/select/select.styled'
import { useDeleteGpsTrackingDevice } from '../../../webapi/hooks/mutations/use-delete-gps-tracking-device'
import { useGetDeviceProtocols } from '../../../webapi/hooks/queries/use-get-device-protocols'
import { getDevicesQueryKey, useGetDevices } from '../../../webapi/hooks/queries/use-get-devices'

import { AddDevice } from './add-device'
import { StyledDeviceTable } from './device.styled'
import { FailedToLoadPageMessage } from './messages'

export const DeviceDashboard = () => {
  const queryClient = useQueryClient()
  const [isModal, setIsModal] = useState(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const { data: devices, isError } = useQuery({ ...useGetDevices(), staleTime: Infinity })

  const { data: protocols } = useQuery({ ...useGetDeviceProtocols(), staleTime: Infinity })

  const invalidateQueries = () => {
    const invalidationOptions = { exact: true }
    queryClient.invalidateQueries(getDevicesQueryKey, invalidationOptions)
  }

  const deleteGpsTrackingDeviceMutation = useMutation({ ...useDeleteGpsTrackingDevice(), onSuccess: invalidateQueries })

  const onDelete = (deviceId: string) => {
    deleteGpsTrackingDeviceMutation.mutate({ deviceId: deviceId })
  }

  return (
    <Card>
      {isModal && (
        <AddDevice onClose={() => setIsModal(false)} invalidateQueries={invalidateQueries} protocols={protocols} />
      )}

      <CardContent>
        <CardHeader>
          <CardHeaderTitle>Devices</CardHeaderTitle>
          <CardHeaderButtons>
            <Button isSmall onClick={() => setIsEdit(!isEdit)}>
              <Icon icon={faPenToSquare} />
              Edit
            </Button>
            <Button isPrimary isSmall onClick={() => setIsModal(true)} disabled={isEdit}>
              <Icon icon={faPlus} />
              {'Add device'}
            </Button>
          </CardHeaderButtons>
        </CardHeader>
        {isError && <FailedToLoadPageMessage />}
        {devices && (
          <StyledDeviceTable
            onDelete={onDelete}
            isDeleting={deleteGpsTrackingDeviceMutation.isLoading}
            data={devices}
            isEdit={isEdit}
            setIsEdit={() => setIsEdit(false)}
            protocols={protocols}
          />
        )}
      </CardContent>
    </Card>
  )
}
