import { RefObject } from 'react'

export const scrollToTop = (ref: RefObject<HTMLDivElement>) => {
  if (ref.current) {
    ref.current.scrollTop = 0
  }
}

export const focusSearch = (ref: RefObject<HTMLInputElement>) => {
  if (ref.current) {
    ref.current.focus()
  }
}
