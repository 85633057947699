import styled from 'styled-components'

export const Root = styled.div.attrs({})`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 10px;
`
export const NoneSelected = styled.div.attrs({})`
  flex: 1;
  text-align: center;
  font-style: italic;
`
export const Input = styled.input.attrs({ className: 'input is-small', type: 'text' })`
  flex: 1;
`
