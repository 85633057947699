import styled from 'styled-components'

export const Card = styled.div.attrs(() => ({ className: 'card' }))`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const CardContent = styled.div.attrs(() => ({ className: 'card-content' }))`
  flex: 1;
`

export const CardFooter = styled.div.attrs(() => ({ className: 'card-footer' }))``
