import { sortBy } from 'ramda'

import { MerchantWarehouseLocation } from '../../../../webapi/hooks/queries/use-get-merchant-warehouse-locations'
import { GetTerminalsResponse } from '../../../../webapi/hooks/queries/use-get-terminals'

import { TerminalOption } from './terminal-side-box'

export const createSelectOptions = (
  terminals: GetTerminalsResponse = [],
  excludedTerminalCodes: string[] = []
): TerminalOption[] =>
  terminals
    .filter((terminal) => !excludedTerminalCodes.includes(terminal.code))
    .map(({ code, name, address: { street, postalCode, countryCode } }) => ({
      code,
      name,
      countryCode,
      street,
      postalCode,
    }))

export const getSelectedOptions = (terminalOptions: TerminalOption[], selectedTerminalCodes: string[] = []) => {
  const selectedOptions = terminalOptions.filter(({ code }) => selectedTerminalCodes.includes(code))
  return sortBy(({ code }) => selectedTerminalCodes.indexOf(code), selectedOptions)
}

export const getDefaultSelectionLabel = (selectedTerminals: TerminalOption[], isMultiSelect: boolean) => {
  if (isMultiSelect) {
    switch (selectedTerminals.length) {
      case 0:
        return 'No terminal selected'
      case 1:
        return '1 terminal selected'
      default:
        return `${selectedTerminals.length} terminals selected`
    }
  }

  const [selectedTerminal] = selectedTerminals
  return selectedTerminal ? `Selected ${selectedTerminal.name}` : 'No terminal selected'
}

export type TerminalSelectorContext = {
  visible: boolean
  terminals: TerminalOption[]
  onChange: (terminal: TerminalOption[]) => void
  onClear: () => void
  isMulti: boolean
}

export const closedContext = (): TerminalSelectorContext => ({
  visible: false,
  isMulti: false,
  terminals: [],
  onChange: () => null,
  onClear: () => null,
})

export const openContext = (
  terminal: TerminalOption | null,
  onChange: (terminal: TerminalOption) => void,
  onClear: () => void
): TerminalSelectorContext => ({
  visible: true,
  isMulti: false,
  terminals: terminal ? [terminal] : [],
  onChange: (selected) => onChange(selected[0]),
  onClear: onClear,
})

export const openMultiContext = (
  terminals: TerminalOption[],
  onChange: (terminal: TerminalOption[]) => void,
  onClear: () => void
): TerminalSelectorContext => ({
  visible: true,
  isMulti: true,
  terminals: terminals,
  onChange: onChange,
  onClear: onClear,
})

export type MerchantWarehouseSelectorContext = {
  open: boolean
  selected: string | null
  onChange: (warehouse: MerchantWarehouseLocation) => void
  onClear: () => void
}

export const openMerchantWarehouseContext = (
  selected: string | null,
  onChange: (warehouse: MerchantWarehouseLocation) => void,
  onClear: () => void
) => ({
  open: true,
  selected,
  onChange,
  onClear,
})

export const closedMerchantWarehouseContext = () => ({
  open: false,
  selected: null,
  onChange: () => null,
  onClear: () => null,
})
