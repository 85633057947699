export interface TimeWithDelta {
  time: string
  deltaMinutes: number
  isEstimation: boolean
}

export interface Pallet {
  barcode: string
  parcelCompositionType: string
  destination: string
  origin: string
  identifier: string
  numberOfParcels: number
}

export interface PalletCountByType {
  countMostlyHomePallets: number
  countMostlyLockerPallets: number
  countMixedPallets: number
  countReturnPallets: number
}

export interface RouteSegment {
  routeId: string
  originName: string
  originTerminalCode: string
  originSequence: number
  destinationName: string
  destinationCountryCode: string
  courier: string
  capacity: number
  scheduleDeparture: string
  scheduleArrival: string
  scheduleDriveTimeMinutes: number
  arrival: TimeWithDelta | null
  departure: TimeWithDelta | null
  pallets: Pallet[]
  palletCountByType: PalletCountByType
  totalNumberOfParcels: number
}

const routeSegmentDirections = ['INBOUND', 'OUTBOUND', 'BOTH'] as const

export type RouteSegmentDirection = typeof routeSegmentDirections[number]

export const isRouteSegmentDirection = (input: unknown): input is RouteSegmentDirection =>
  typeof input === 'string' && routeSegmentDirections.some((direction) => direction === input)
