import React, { ChangeEvent, useRef, useState } from 'react'
import { useQuery } from 'react-query'

import { search } from '../../../../lib/search'
import {
  MerchantWarehouseLocation,
  useGetMerchantWarehouseLocations,
} from '../../../../webapi/hooks/queries/use-get-merchant-warehouse-locations'
import { SideBoxContainer, SideBoxOverlay } from '../../../side-box.styled'
import { SimpleMessage } from '../../../simple-message'
import { ListItem } from '../list-item'
import { Search } from '../search'
import { scrollToTop } from '../select-side-box-utils'
import {
  Button,
  Buttons,
  NoItemsMessage,
  SelectList,
  SelectSideBox,
  SideBoxContent,
  SideBoxFooter,
} from '../select-side-box.styled'
import { TitleBar } from '../title-bar'

interface MerchantWarehouseSideBoxProps {
  selectedIdentifier: string | null
  onCancel: () => void
  onChange: (location: MerchantWarehouseLocation) => void
  onClear: () => void
}

export const MerchantWarehouseSideBox = ({
  selectedIdentifier,
  onCancel,
  onChange,
  onClear,
}: MerchantWarehouseSideBoxProps) => {
  const locationsQuery = useQuery(useGetMerchantWarehouseLocations())
  const [searchTerm, setSearchTerm] = useState('')
  const searchRef = useRef<HTMLInputElement>(null)
  const selectListRef = useRef<HTMLDivElement>(null)
  const [tempIdentifier, setTempIdentifier] = useState<string | null>(selectedIdentifier)
  const [tempLocation, setTempLocation] = useState<MerchantWarehouseLocation | null>(null)

  const onSave = () => {
    if (tempLocation) {
      onChange(tempLocation)
    }
  }

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.trimStart())
    scrollToTop(selectListRef)
  }

  const renderLocation = (location: MerchantWarehouseLocation) => {
    const onWarehouseSelected = () => {
      setTempIdentifier(location.identifier)
      setTempLocation(location)
    }
    return (
      <ListItem
        key={location.identifier}
        onClick={onWarehouseSelected}
        checked={location.identifier === tempIdentifier}
        label={location.name}
        subLabel={`${location.street}, ${location.postalCode}, ${location.countryCode}`}
        mode={'SELECT'}
        type={'radio'}
      />
    )
  }
  const fetched = locationsQuery.isSuccess
  const fetchedLocations = fetched ? locationsQuery.data.locations : []
  const filtered = search(fetchedLocations, ['name', 'street'], searchTerm)
  return (
    <SideBoxContainer>
      <SideBoxOverlay onClick={onCancel} />
      <SelectSideBox>
        <SideBoxContent>
          <TitleBar title={`Select Merchant Warehouse`} onClose={onCancel} />
          <Search ref={searchRef} value={searchTerm} onChange={onSearch} />
          <SelectList ref={selectListRef}>
            {!fetched && <SimpleMessage isDanger>Failed to load merchant warehouses.</SimpleMessage>}
            {searchTerm && !filtered.length && <NoItemsMessage>{'No matches'}</NoItemsMessage>}
            {filtered.map(renderLocation)}
          </SelectList>
        </SideBoxContent>
        <SideBoxFooter>
          <Buttons vertical={true}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onClear} disabled={selectedIdentifier === null}>
              Clear Selection
            </Button>
            <Button onClick={onSave} isPrimary disabled={tempLocation === null}>
              Select {tempLocation?.name}
            </Button>
          </Buttons>
        </SideBoxFooter>
      </SelectSideBox>
    </SideBoxContainer>
  )
}
