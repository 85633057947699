import { AxiosInstance } from 'axios'
import { QueryFunctionContext } from 'react-query'

import { useWebapiClient } from '../use-webapi-client'

export const useQueryOptions = <Key, QueryResponse, MappedResponse>(
  queryKey: Key[],
  queryFn: (webapiClient: AxiosInstance, context: QueryFunctionContext) => Promise<QueryResponse>,
  mapFn?: (response: QueryResponse) => MappedResponse
) => {
  const webapiClient = useWebapiClient()
  return {
    queryKey,
    queryFn: (context: QueryFunctionContext) => queryFn(webapiClient, context).then(mapFn),
  }
}

export const useQueryOptionsWithVariables = <Key, QueryResponse, Variables, MappedResponse>(
  queryKey: Key[],
  queryFn: (webapiClient: AxiosInstance, variables: Variables, context: QueryFunctionContext) => Promise<QueryResponse>,
  variables: Variables,
  mapFn?: (response: QueryResponse) => MappedResponse
) => {
  const webapiClient = useWebapiClient()
  return {
    queryKey: [queryKey, variables],
    queryFn: (context: QueryFunctionContext) => queryFn(webapiClient, variables, context).then(mapFn),
  }
}
