import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'
import { useQueryOptionsWithVariables } from '../queries/use-query-options'

interface GetConsignmentsBookingCandidatesVariables {
  terminalCodes: string[] // Terminal codes
}

export interface ConsignmentsBookingCandidate {
  terminalCode: string
  numberOfCandidates: number
  numberOfConsignmentsBooked: number
  consignmentLimit: number
  locked: boolean
  routingDeadline: string
}

export interface ConsignmentsBookingCandidateSum {
  candidates: number
  booked: number
  consignmentLimit: number
}

export interface ConsignmentsBookingCandidatesResponse {
  terminals: ConsignmentsBookingCandidate[]
  sum: ConsignmentsBookingCandidateSum
}

const getConsignmentsCandidates = (webapiClient: AxiosInstance, variables: GetConsignmentsBookingCandidatesVariables) =>
  webapiClient.post<ConsignmentsBookingCandidatesResponse>('/slotman/query-candidates', variables)

export const getConsignmentsCandidatesKey = ['consignements-booking-candidates']

export const useGetConsignmentsCandidates = (variables: GetConsignmentsBookingCandidatesVariables) =>
  useQueryOptionsWithVariables(
    [...getConsignmentsCandidatesKey, variables],
    getConsignmentsCandidates,
    variables,
    getResponseData
  )
