import React from 'react'

import { ActionConfirmationModal } from '../../../components/action-confirmation-modal/action-confirmation-modal'

interface Props {
  handleClose: () => void
  friendlyIdDelete?: string
  isDeleting?: boolean
  deviceIdDelete: string
  onDelete: (deviceId: string) => void
}

export const DeleteDevice = ({ handleClose, friendlyIdDelete, deviceIdDelete, onDelete, isDeleting }: Props) => {
  return (
    <ActionConfirmationModal
      variant="danger"
      isConfirming={isDeleting}
      confirmButtonText="Delete"
      onClose={handleClose}
      onConfirm={() => onDelete(deviceIdDelete)}
    >
      Do you really want to delete device with id <b>{friendlyIdDelete}</b>? This cannot be undone.
    </ActionConfirmationModal>
  )
}
