import React from 'react'
import { useQuery } from 'react-query'

import { Card, CardContent } from '../../../components/bulma/card.styled'
import { CardHeader, CardHeaderButtonGroup, CardHeaderButtons, CardHeaderTitle } from '../../../components/card.styled'
import { TerminalOption } from '../../../components/select'
import { TerminalSelect } from '../../../components/select/terminal-select'
import { useStoredTerminalCodes } from '../../../hooks/use-stored-terminal-codes'
import { useGetDevices } from '../../../webapi/hooks/queries/use-get-devices'
import { terminalSelectDevicesKey } from '../../merchant-return/local-storage-keys'

import { BatteryDashboardColumn, BatteryDashboardColumnContainer } from './battery-dashboard.styled'
import { BatteryTerminalTable } from './battery-terminal-table'
import { FailedToLoadPageMessage } from './messages'

export const BatteryDashboard = () => {
  const [selectedTerminalCodes, setSelectedTerminalCodes] = useStoredTerminalCodes(terminalSelectDevicesKey)

  const onSelectedTerminals = (selectedTerminals: TerminalOption[]) => {
    setSelectedTerminalCodes(selectedTerminals.map(({ code }) => code))
  }

  const { data: devices, isError } = useQuery({ ...useGetDevices(), staleTime: Infinity })

  const terminalDevices = () => {
    if (selectedTerminalCodes.length > 0) {
      return devices?.devices.filter((device) => {
        if (device.terminal?.terminalCode) {
          return selectedTerminalCodes.includes(device.terminal?.terminalCode)
        }
        return false
      })
    }
    return devices?.devices
  }

  return (
    <BatteryDashboardColumnContainer>
      <BatteryDashboardColumn>
        <Card>
          <CardContent>
            <CardHeader>
              <CardHeaderTitle>Battery dashboard</CardHeaderTitle>
              <CardHeaderButtons>
                <CardHeaderButtonGroup>
                  <TerminalSelect
                    selectedTerminalCodes={selectedTerminalCodes}
                    onChange={onSelectedTerminals}
                    isMultiSelect
                  />
                </CardHeaderButtonGroup>
              </CardHeaderButtons>
            </CardHeader>
            {isError && <FailedToLoadPageMessage />}
            <BatteryTerminalTable data={terminalDevices()} />
          </CardContent>
        </Card>
      </BatteryDashboardColumn>
    </BatteryDashboardColumnContainer>
  )
}
