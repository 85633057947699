import { ReactNode } from 'react'
import React from 'react'

import { Message, MessageBody, MessageProps } from './bulma/message.styled'

interface Props extends MessageProps {
  children: ReactNode
}

export const SimpleMessage = ({ children, ...remainingProps }: Props) => (
  <Message {...remainingProps}>
    <MessageBody>{children}</MessageBody>
  </Message>
)
