import { formatTimestamp } from '../../../lib/date-and-time'
import { MerchantDropOffParcel } from '../../../webapi/hooks/queries/use-get-merchant-drop-off-parcels'

export const formatLastScan = (parcel: MerchantDropOffParcel, terminalNames: Record<string, string>) => {
  const { terminalOfLastScan, terminalOfLastScannedTime } = parcel
  if (terminalOfLastScan && terminalOfLastScannedTime) {
    return `${terminalNames[terminalOfLastScan]} ${formatTimestamp(terminalOfLastScannedTime)}`
  }
  return null
}
