import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'

import { ListItem } from '../../../../components/select/select-side-box/list-item'
import { Search } from '../../../../components/select/select-side-box/search'
import { focusSearch, scrollToTop } from '../../../../components/select/select-side-box/select-side-box-utils'
import {
  Button,
  Buttons,
  Label,
  NoItemsMessage,
  SelectList,
  SelectSideBox,
  SideBoxContent,
  SideBoxFooter,
} from '../../../../components/select/select-side-box/select-side-box.styled'
import { TitleBar } from '../../../../components/select/select-side-box/title-bar'
import { SideBoxContainer, SideBoxOverlay } from '../../../../components/side-box.styled'
import { SimpleMessage } from '../../../../components/simple-message'
import { useDisabledBodyScroll } from '../../../../hooks/use-disabled-body-scroll'
import { search } from '../../../../lib/search'
import { useGetTerminalGroups } from '../../../../webapi/hooks/queries/use-get-terminal-groups'

interface Props {
  terminalGroupIdentifier: string | null
  onCancel: () => void
  onSelect: (terminalGroupIdentifier: string) => void
}

export const TerminalGroupSidebox = ({ terminalGroupIdentifier, onCancel, onSelect }: Props) => {
  const [selectedIdentifier, setSelectedIdentifier] = useState(terminalGroupIdentifier)
  const [searchTerm, setSearchTerm] = useState('')

  const searchRef = useRef<HTMLInputElement>(null)
  const selectListRef = useRef<HTMLDivElement>(null)

  const terminalGroupsQuery = useQuery(useGetTerminalGroups())

  useDisabledBodyScroll()

  useEffect(() => {
    focusSearch(searchRef)
  }, [])

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.trimStart())
    scrollToTop(selectListRef)
  }

  const renderContent = () => {
    const groups = terminalGroupsQuery.data?.groups || []
    const groupsSearchResult = search(groups, ['name'], searchTerm)
    return (
      <SelectList ref={selectListRef}>
        {terminalGroupsQuery.isError && <SimpleMessage isDanger>Failed to load terminal groups.</SimpleMessage>}
        {terminalGroupsQuery.isSuccess && (
          <>
            {searchTerm && !groupsSearchResult.length && <NoItemsMessage>{'No matches'}</NoItemsMessage>}
            {groupsSearchResult.map((terminalGroup) => (
              <ListItem
                key={terminalGroup.identifier}
                link={`/terminals/groups?identifier=${terminalGroup.identifier}`}
                mode="SELECT"
                label={terminalGroup.name}
                type={'radio'}
                onClick={() => setSelectedIdentifier(terminalGroup.identifier)}
                checked={terminalGroup.identifier === selectedIdentifier}
              />
            ))}
          </>
        )}
      </SelectList>
    )
  }

  return (
    <SideBoxContainer>
      <SideBoxOverlay onClick={onCancel} />
      <SelectSideBox>
        <SideBoxContent>
          <TitleBar title="Select Terminal Group" onClose={onCancel} />
          <Search ref={searchRef} value={searchTerm} onChange={onSearch} />
          {renderContent()}
        </SideBoxContent>
        <SideBoxFooter>
          <Label>
            {terminalGroupsQuery.data?.groups.find((group) => group.identifier === selectedIdentifier)?.name ??
              'No group selected'}
          </Label>
          <Buttons>
            <Button
              disabled={!selectedIdentifier}
              isPrimary
              onClick={() => {
                if (selectedIdentifier) {
                  onSelect(selectedIdentifier)
                }
              }}
            >
              Select
            </Button>
          </Buttons>
        </SideBoxFooter>
      </SelectSideBox>
    </SideBoxContainer>
  )
}
