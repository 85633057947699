import { isSameDay } from 'date-fns'
import { difference, equals, includes, without } from 'ramda'
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'

import { useDisabledBodyScroll } from '../../../../hooks/use-disabled-body-scroll'
import { usePrevious } from '../../../../hooks/use-previous'
import { search } from '../../../../lib/search'
import { DateRange } from '../../../../types/date-range'
import { isTerminalCountryCode, TerminalCountryCode } from '../../../../types/terminal-country-codes'
import { useGetTerminals } from '../../../../webapi/hooks/queries/use-get-terminals'
import { SideBoxContainer, SideBoxOverlay } from '../../../side-box.styled'
import { SimpleMessage } from '../../../simple-message'
import { ListItem } from '../list-item'
import { Search } from '../search'
import { focusSearch, scrollToTop } from '../select-side-box-utils'
import {
  SelectList,
  SideBoxContent,
  SelectSideBox,
  SideBoxFooter,
  Label,
  Buttons,
  Button,
  NoItemsMessage,
  ListItemLabelBold,
} from '../select-side-box.styled'
import { Tab } from '../tab'
import { Tabs } from '../tabs'
import { TitleBar } from '../title-bar'

import { DateRangePicker } from './date-range-picker'
import { createSelectOptions, getDefaultSelectionLabel, getSelectedOptions } from './utils'

type TerminalTabs = TerminalCountryCode | 'ALL' | 'SELECTED'

const countries: { code: TerminalCountryCode; name: string }[] = [
  { code: 'SE', name: 'Sweden' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'BE', name: 'Belgium' },
  { code: 'FI', name: 'Finland' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DE', name: 'Germany' },
  { code: 'NO', name: 'Norway' },
]

export interface TerminalOption {
  code: string
  name: string
  street: string
  postalCode: string
  countryCode: TerminalCountryCode
}

interface TerminalSideBoxProps {
  show: boolean
  isMultiSelect?: boolean
  selectedTerminalCodes: string[]
  excludedTerminalCodes?: string[]
  showDateRangeSelection?: boolean
  determineDefaultTab: (options: TerminalOption[]) => TerminalTabs
  showClear?: boolean
  onLoad?: (options: TerminalOption[]) => void
  onChange: (options: TerminalOption[], dateRange?: DateRange) => void
  onCancel: () => void
  onClear?: () => void
}

const isSameDateRange = (firstRange: DateRange, secondRange: DateRange) =>
  isSameDay(firstRange.fromDate, secondRange.fromDate) && isSameDay(firstRange.toDate, secondRange.toDate)

const getTerminalOptionsInTab = (
  terminalOptions: TerminalOption[],
  tab: TerminalTabs,
  searchTerm: string,
  selectedOptions: TerminalOption[]
) => {
  let filteredTerminalsInTab: TerminalOption[]
  if (tab === 'SELECTED') {
    filteredTerminalsInTab = terminalOptions.filter((terminal) => includes(terminal, selectedOptions))
  } else if (tab === 'ALL') {
    filteredTerminalsInTab = terminalOptions
  } else {
    filteredTerminalsInTab = terminalOptions.filter(({ countryCode }) => countryCode === tab)
  }
  return search(filteredTerminalsInTab, ['name'], searchTerm)
}

export const TerminalSideBox = ({
  show,
  isMultiSelect = false,
  selectedTerminalCodes,
  excludedTerminalCodes,
  showDateRangeSelection,
  determineDefaultTab,
  showClear,
  onLoad,
  onChange,
  onCancel,
  onClear,
}: TerminalSideBoxProps) => {
  const defaultSelection: TerminalOption[] = []
  const [selectedOptions, setSelectedOptions] = useState<TerminalOption[]>(defaultSelection)
  const [updatedSelection, setUpdatedSelection] = useState<TerminalOption[]>(defaultSelection)

  const [searchTerm, setSearchTerm] = useState('')
  const [selectedTab, setSelectedTab] = useState<TerminalTabs>()

  const isDateRangeEnabled = showDateRangeSelection && isMultiSelect
  const dateRangeToday = useRef({ fromDate: new Date(), toDate: new Date() }).current
  const [selectedDateRange, setSelectedDateRange] = useState(dateRangeToday)
  const [updatedDateRange, setUpdatedDateRange] = useState(dateRangeToday)

  const searchRef = useRef<HTMLInputElement>(null)
  const tabContentRef = useRef<HTMLDivElement>(null)

  const terminalsQuery = useQuery(useGetTerminals())

  const terminalOptions = useMemo(
    () => createSelectOptions(terminalsQuery.data, excludedTerminalCodes),
    [terminalsQuery.data, excludedTerminalCodes]
  )

  const terminalOptionsInTab = useMemo(
    () => (selectedTab ? getTerminalOptionsInTab(terminalOptions, selectedTab, searchTerm, updatedSelection) : []),
    [terminalOptions, selectedTab, searchTerm, updatedSelection]
  )

  const [hasLoaded, setHasLoaded] = useState(false)
  const previousHasLoaded = usePrevious(hasLoaded)
  useEffect(() => {
    if (hasLoaded && !previousHasLoaded) {
      setSelectedTab((tab) => (tab ? tab : determineDefaultTab(selectedOptions)))
      if (onLoad) {
        onLoad(selectedOptions)
      }
    }
  }, [hasLoaded, previousHasLoaded, selectedOptions, onLoad, determineDefaultTab])

  useEffect(() => {
    if (terminalsQuery.isSuccess) {
      const selectedOptions = getSelectedOptions(terminalOptions, selectedTerminalCodes)
      setSelectedOptions(selectedOptions)
      setUpdatedSelection(selectedOptions)
      setHasLoaded(true)
    }
  }, [terminalsQuery.isSuccess, terminalOptions, selectedTerminalCodes, setHasLoaded])

  useDisabledBodyScroll(show)

  useEffect(() => {
    if (show) {
      focusSearch(searchRef)
    }
  }, [show, searchRef])

  const onTerminalSelect = (terminal: TerminalOption) => {
    // if not multi select, always for them to select an option, maybe this should not be the case?
    if (isMultiSelect) {
      if (includes(terminal, updatedSelection)) {
        setUpdatedSelection(without([terminal], updatedSelection))
      } else {
        setUpdatedSelection([...updatedSelection, terminal])
      }
      // Do nothing if single select and already selected
    } else if (!equals([terminal], updatedSelection)) {
      setUpdatedSelection([terminal])
    }
  }

  const onSelectTab = (tab: TerminalTabs) => {
    setSelectedTab(tab)
    scrollToTop(tabContentRef)
    focusSearch(searchRef)
  }

  const clearSearch = () => setSearchTerm('')

  const onResetMultiSelect = () => {
    clearSearch()
    setUpdatedSelection([])
    setUpdatedDateRange(dateRangeToday)
  }

  const onClearSelection = () => {
    setUpdatedSelection([])
    if (onClear) {
      onClear()
    }
  }

  const onSave = () => {
    clearSearch()
    setSelectedOptions(updatedSelection)
    setSelectedDateRange(updatedDateRange)
    onChange(updatedSelection, isDateRangeEnabled ? updatedDateRange : undefined)
  }

  const onClose = () => {
    clearSearch()
    setUpdatedSelection(selectedOptions)
    setUpdatedDateRange(selectedDateRange)
    onCancel()
  }

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.trimStart())
    scrollToTop(tabContentRef)
  }

  const renderTabs = () => (
    <Tabs>
      {isMultiSelect && (
        <Tab<TerminalTabs>
          tab={'SELECTED'}
          name="Selected"
          active={selectedTab === 'SELECTED'}
          onSelected={() => onSelectTab('SELECTED')}
        />
      )}
      {
        <Tab<TerminalTabs>
          tab={'ALL'}
          name="All"
          active={selectedTab === 'ALL'}
          onSelected={() => onSelectTab('ALL')}
        />
      }
      {countries.map(({ name, code }) => (
        <Tab<TerminalTabs>
          key={code}
          tab={code}
          name={name}
          active={selectedTab === code}
          onSelected={() => onSelectTab(code)}
        />
      ))}
    </Tabs>
  )

  const renderAllTerminalsInCountryToggle = (countryTerminals: TerminalOption[], countryCode: TerminalCountryCode) => {
    const countryName = countries.find(({ code }) => code === countryCode)?.name
    const terminalsToAdd = difference(countryTerminals, updatedSelection)
    const isAllSelected = terminalsToAdd.length === 0
    const onToggleAllTerminalsInCountry = () => {
      if (isAllSelected) {
        setUpdatedSelection(without(countryTerminals, updatedSelection))
      } else {
        setUpdatedSelection([...updatedSelection, ...terminalsToAdd])
      }
    }

    return (
      <ListItem
        key={countryCode}
        onClick={onToggleAllTerminalsInCountry}
        checked={isAllSelected}
        label={<ListItemLabelBold>All in {countryName}</ListItemLabelBold>}
        mode={'SELECT'}
        type={'checkbox'}
      />
    )
  }

  const renderTerminal = (terminal: TerminalOption) => (
    <ListItem
      key={terminal.code}
      onClick={() => onTerminalSelect(terminal)}
      checked={includes(terminal, updatedSelection)}
      label={terminal.name}
      subLabel={`${terminal.street}, ${terminal.postalCode}, ${terminal.countryCode}`}
      mode={selectedTab === 'SELECTED' ? 'REMOVE' : 'SELECT'}
      type={isMultiSelect ? 'checkbox' : 'radio'}
    />
  )

  const renderTabContent = () => (
    <SelectList ref={tabContentRef}>
      {terminalsQuery.isSuccess && (
        <>
          {terminalOptionsInTab.length === 0 && (
            <NoItemsMessage>{searchTerm ? 'No matches' : 'No terminals selected'}</NoItemsMessage>
          )}
          {isMultiSelect &&
            isTerminalCountryCode(selectedTab) &&
            !searchTerm &&
            renderAllTerminalsInCountryToggle(terminalOptionsInTab, selectedTab)}
          {terminalOptionsInTab.map(renderTerminal)}
        </>
      )}
      {terminalsQuery.isError && <SimpleMessage isDanger>Failed to load terminals.</SimpleMessage>}
    </SelectList>
  )

  const isResetDisabled =
    updatedSelection.length === 0 && (!isDateRangeEnabled || isSameDateRange(updatedDateRange, dateRangeToday))
  const isSaveDisabled =
    updatedSelection === selectedOptions && (!isDateRangeEnabled || updatedDateRange === selectedDateRange)

  if (!show) {
    return null
  }

  return (
    <SideBoxContainer>
      <SideBoxOverlay onClick={onClose} />
      <SelectSideBox>
        <SideBoxContent>
          <TitleBar title={`Select Terminal${isMultiSelect ? 's' : ''}`} onClose={onClose} />
          {renderTabs()}
          <Search ref={searchRef} value={searchTerm} onChange={onSearch} />
          {renderTabContent()}
        </SideBoxContent>
        <SideBoxFooter>
          <Label>{getDefaultSelectionLabel(updatedSelection, isMultiSelect)}</Label>
          {isDateRangeEnabled && <DateRangePicker onChange={setUpdatedDateRange} dateRange={updatedDateRange} />}
          <Buttons>
            {showClear && (
              <Button onClick={onClearSelection} isLight>
                Clear
              </Button>
            )}
            {isMultiSelect && (
              <Button disabled={isResetDisabled} onClick={onResetMultiSelect} isLight>
                Reset
              </Button>
            )}
            <Button disabled={isSaveDisabled} onClick={onSave} isPrimary>
              Save
            </Button>
          </Buttons>
        </SideBoxFooter>
      </SelectSideBox>
    </SideBoxContainer>
  )
}
