import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { CardModal } from '../../../components/device/card-modal'
import { DeviceInput } from '../../../components/device/device-input'
import { InputField } from '../../../components/device/device-input.styled'
import { SelectProtocol } from '../../../components/device/select-protocol'
import { useUpdateGpsTrackingDevice } from '../../../webapi/hooks/mutations/use-update-gps-tracking.device'
import { GetProtocolResponse } from '../../../webapi/hooks/queries/use-get-device-protocols'
import { Device, getDevicesQueryKey } from '../../../webapi/hooks/queries/use-get-devices'

import { ErrorEditMessage, SuccessEditMessage } from './messages'

interface Props {
  onClose: () => void
  protocols?: GetProtocolResponse
  device: Device
}

export const EditDevice = (props: Props) => {
  const queryClient = useQueryClient()
  const [imei, setImei] = useState<string>(props.device.imei || '')
  const [protocol, setProtocol] = useState(props.device.protocol)
  const [simcardId, setSimCardId] = useState<string>(props.device.simCardId || '')

  const invalidateQueries = () => {
    const invalidationOptions = { exact: true }
    queryClient.invalidateQueries(getDevicesQueryKey, invalidationOptions)
  }

  const updateGpsTrackingDeviceMutation = useMutation({ ...useUpdateGpsTrackingDevice(), onSuccess: invalidateQueries })
  const { isLoading, isSuccess, isError, mutate } = updateGpsTrackingDeviceMutation

  const onSave = () => {
    const updateVariables = {
      imei: imei || null,
      protocol: protocol,
      simCardId: simcardId || null,
    }
    mutate({
      deviceId: props.device.deviceId,
      data: updateVariables,
    })
  }

  return (
    <CardModal
      onClose={props.onClose}
      onSave={onSave}
      disableButton={!protocol || isLoading || isSuccess}
      title="Edit device"
    >
      {isError && <ErrorEditMessage />}
      {isSuccess && <SuccessEditMessage />}
      <DeviceInput label="Device ID">
        <InputField aria-label="friendly-id" type="text" placeholder={props.device.friendlyId} disabled={true} />
      </DeviceInput>
      <DeviceInput label="IMEI">
        <InputField
          aria-label="imei"
          type="text"
          placeholder="12345"
          value={imei}
          onChange={(e) => setImei(e.target.value)}
        />
      </DeviceInput>
      <DeviceInput label="SIM card ID">
        <InputField
          aria-label="simcard"
          type="text"
          placeholder="12345"
          value={simcardId}
          onChange={(e) => setSimCardId(e.target.value)}
        />
      </DeviceInput>
      {props.protocols && (
        <SelectProtocol
          label="Protocol"
          protocols={props.protocols}
          setProtocol={setProtocol}
          selectedProtocol={protocol}
        />
      )}
    </CardModal>
  )
}
