import styled from 'styled-components'

import { ListItemDescription, ListItemTitle } from '../../../components/bulma/list.styled'

export const ListItemColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 16px;
`

export const ListItemColumn = styled.div`
  flex-direction: column;
  display: flex;
`

export const TerminalRelationsListItemTitle = styled(ListItemTitle)`
  font-size: 0.875rem;
`

export const TerminalRelationsListItemDescription = styled(ListItemDescription)`
  font-size: 0.875rem;
  word-break: break-word;
`

export const TerminalRelationsListItemCatchment = styled(TerminalRelationsListItemDescription)`
  display: inline-flex;
  gap: 2px 8px;
  flex-wrap: wrap;
`
