import { configureStore } from '@reduxjs/toolkit'

import { webapiJWT } from './slices/webapi-jwt-slice'

export const storeConfiguration = {
  reducer: {
    webapiJWT,
  },
  devTools: {
    name: 'Ground Ops Admin',
  },
}

interface PreloadedReduxState {
  webapiJWT: string
}

export const createStore = (preloadedState?: PreloadedReduxState) =>
  configureStore({ ...storeConfiguration, preloadedState })

export type ReduxStore = ReturnType<typeof createStore>
export type ReduxState = ReturnType<ReturnType<typeof createStore>['getState']>
export type ReduxDispatch = ReturnType<typeof createStore>['dispatch']
