import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const DeviceLabel = styled.span`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const DeviceAssignedIcon = styled(FontAwesomeIcon).attrs({
  className: 'has-text-info is-size-6 ml-3',
  icon: faTruck,
})``
