import React from 'react'

import { Tab as StyledTab } from './select-side-box.styled'

interface Props<T> {
  tab: T
  name: string
  active: boolean
  onSelected: (tab: T) => void
}

export const Tab = <T,>({ tab, name, active, onSelected }: Props<T>) => (
  <StyledTab active={active} onClick={() => onSelected(tab)}>
    <a>{name}</a>
  </StyledTab>
)
