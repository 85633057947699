export enum DashboardColumn {
  Date = 'DATE',
  Segment = 'SEGMENT',
  DriveTime = 'DRIVE_TIME',
  Courier = 'COURIER',
  ScheduleDeparture = 'SCHEDULE_DEPARTURE',
  ActualDeparture = 'ACTUAL_DEPARTURE',
  ScheduleArrival = 'SCHEDULE_ARRIVAL',
  ActualArrival = 'ACTUAL_ARRIVAL',
  HomePallets = 'HOME_PALLETS',
  ReturnPallets = 'RETURN_PALLETS',
  LockerPallets = 'LOCKER_PALLETS',
  MixedPallets = 'MIXED_PALLETS',
  TotalNumberOfParcels = 'TOTAL_NUMBER_OF_PARCELS',
}

export type DashboardColumnVisibility = {
  [key in DashboardColumn]: boolean
}
