import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'
import { TerminalCountryCode } from '../../../types/terminal-country-codes'

import { useQueryOptions } from './use-query-options'

interface Coordinate {
  latitude: number
  longitude: number
}

export interface MerchantWarehouseLocation {
  name: string
  identifier: string
  street: string
  postalCode: string
  countryCode: TerminalCountryCode
  coordinate: Coordinate
}

export type MerchantWarehouseLocationsResponse = {
  locations: MerchantWarehouseLocation[]
}

export const getMerchantWarehouseLocationsQueryKey = ['merchant-warehouses']

const getMerchantWarehouses = (webapiClient: AxiosInstance) =>
  webapiClient.get<MerchantWarehouseLocationsResponse>('/freightman/merchant-warehouse-locations')

export const useGetMerchantWarehouseLocations = () => ({
  ...useQueryOptions(getMerchantWarehouseLocationsQueryKey, getMerchantWarehouses, getResponseData),
  staleTime: Infinity,
  cacheTime: Infinity,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
})
