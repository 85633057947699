import React from 'react'

import { Table, TableRow } from '../../../components/bulma/table.styled'
import { StickyTableRow } from '../../../components/sticky-table-row.styled'
import { MerchantDropOffOverviewResponse } from '../../../webapi/hooks/mutations/use-merchant-drop-off-overview'
import { MerchantDropOffParcelsPhase } from '../../../webapi/hooks/queries/use-get-merchant-drop-off-parcels'

import { ParcelsLink } from './dashboard.styled'

export interface TerminalPhase {
  terminalCode: string
  phase: MerchantDropOffParcelsPhase
}

interface Props {
  data: MerchantDropOffOverviewResponse
  terminalNames: Record<string, string>
  className?: string
  onSelectPhase: (terminalPhase: TerminalPhase) => void
}

export const DashboardTable = ({ data, className, terminalNames, onSelectPhase }: Props) => (
  <Table className={className} isNarrow isFullwidth isStriped>
    <thead>
      <StickyTableRow>
        <th>Terminal</th>
        <th>To be injected</th>
        <th>In locker</th>
        <th>In-transit to terminal</th>
        <th>To be transited</th>
        <th>Ready to book</th>
        <th>Booked</th>
        <th>Reached max attempts</th>
      </StickyTableRow>
    </thead>
    <tbody>
      {data.terminals.map(
        ({
          terminal: terminalCode,
          inLocker,
          toBeInjected,
          inTransitToTerminal,
          toBeTransited,
          readyToBook,
          booked,
          maxAttemptsReached,
        }) => (
          <TableRow key={terminalCode}>
            <td>{terminalNames[terminalCode]}</td>
            <ParcelsLink
              onClick={() => onSelectPhase({ terminalCode, phase: MerchantDropOffParcelsPhase.NotInjected })}
              isActive={Boolean(toBeInjected)}
            >
              {toBeInjected}
            </ParcelsLink>
            <ParcelsLink
              onClick={() => onSelectPhase({ terminalCode, phase: MerchantDropOffParcelsPhase.InLocker })}
              isActive={Boolean(inLocker)}
            >
              {inLocker}
            </ParcelsLink>
            <ParcelsLink
              onClick={() => onSelectPhase({ terminalCode, phase: MerchantDropOffParcelsPhase.InTransit })}
              isActive={Boolean(inTransitToTerminal)}
            >
              {inTransitToTerminal}
            </ParcelsLink>
            <ParcelsLink
              onClick={() => onSelectPhase({ terminalCode, phase: MerchantDropOffParcelsPhase.ToBeTransited })}
              isActive={Boolean(toBeTransited)}
            >
              {toBeTransited}
            </ParcelsLink>
            <ParcelsLink
              onClick={() => onSelectPhase({ terminalCode, phase: MerchantDropOffParcelsPhase.Bookable })}
              isActive={Boolean(readyToBook)}
            >
              {readyToBook}
            </ParcelsLink>
            <ParcelsLink
              onClick={() => onSelectPhase({ terminalCode, phase: MerchantDropOffParcelsPhase.Booked })}
              isActive={Boolean(booked)}
            >
              {booked}
            </ParcelsLink>
            <ParcelsLink
              onClick={() => onSelectPhase({ terminalCode, phase: MerchantDropOffParcelsPhase.ReachedMaxAttempts })}
              isActive={Boolean(maxAttemptsReached)}
            >
              {maxAttemptsReached}
            </ParcelsLink>
          </TableRow>
        )
      )}
    </tbody>
  </Table>
)
