import { faCircle, faBatteryQuarter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const BatteryDashboardColumn = styled.div.attrs({ className: 'column' })``

export const BatteryDashboardColumnContainer = styled.div.attrs({ className: 'columns mt-3' })``
export const BatteryDasboardSubtitle = styled.h2.attrs({ className: 'subtitle mt-3' })``

export const DeviceActiveIcon = styled(FontAwesomeIcon).attrs({
  className: 'has-text-success is-size-6 ml-3',
  icon: faCircle,
})``

export const DeviceLowBatteryIcon = styled(FontAwesomeIcon).attrs({
  className: 'has-text-danger is-size-6 ml-3',
  icon: faBatteryQuarter,
})``

export const DeviceInactiveIcon = styled(FontAwesomeIcon).attrs({
  className: 'has-text-grey is-size-6 ml-3',
  icon: faCircle,
})``
