import React from 'react'

import { Table, TableRow } from '../../../components/bulma/table.styled'
import { StickyTableRow } from '../../../components/sticky-table-row.styled'
import { formatDateTime } from '../../../lib/date-and-time'
import { ConsignmentsBookingCandidatesResponse } from '../../../webapi/hooks/queries/use-get-consignments-candidates'

import { BookConsignmentActionButton } from './book-consignment-action-button'
import { ActionButtons, ActionsHeaderColumn } from './consignments-booking.styled'
import { ExportActionButton } from './export-action-button'

interface Props {
  data: ConsignmentsBookingCandidatesResponse
  terminalNames: Record<string, string>
  className?: string
}

export const ConsignmentsBookingTable = ({ data, className, terminalNames }: Props) => {
  return (
    <Table className={className} isNarrow isFullwidth isStriped>
      <thead>
        <StickyTableRow>
          <th>Terminal</th>
          <th>Candidates</th>
          <th>Booked</th>
          <th>{"Today's"} Limit</th>
          <th>Routing deadline</th>
          <ActionsHeaderColumn>Actions</ActionsHeaderColumn>
        </StickyTableRow>
      </thead>
      <tbody>
        {data.terminals.map(
          ({
            terminalCode,
            numberOfCandidates,
            numberOfConsignmentsBooked,
            consignmentLimit,
            routingDeadline,
            locked,
          }) => (
            <TableRow key={terminalCode}>
              <td>{terminalNames[terminalCode]}</td>
              <td>{numberOfCandidates}</td>
              <td>{numberOfConsignmentsBooked}</td>
              <td>{consignmentLimit < 0 ? <span className="tag is-danger is-light">Error</span> : consignmentLimit}</td>
              <td>{routingDeadline ? formatDateTime(routingDeadline) : 'N/A'}</td>
              <td>
                <ActionButtons>
                  <BookConsignmentActionButton
                    terminalNames={terminalNames}
                    locked={locked}
                    terminalCode={terminalCode}
                    disabled={consignmentLimit < 0}
                  />
                  <ExportActionButton terminalCode={terminalCode} />
                </ActionButtons>
              </td>
            </TableRow>
          )
        )}
      </tbody>
      {data.sum && (
        <tfoot>
          <tr>
            <th>Total</th>
            <td>{data.sum.candidates}</td>
            <td>{data.sum.booked}</td>
            <td>{data.sum.consignmentLimit}</td>
            <td></td>
          </tr>
        </tfoot>
      )}
    </Table>
  )
}
