import React from 'react'
import { useQuery } from 'react-query'

import { Table } from '../../../components/bulma/table.styled'
import { CardLoader } from '../../../components/card-loader.styled'
import { StickyTableRow } from '../../../components/sticky-table-row.styled'
import { TableCell } from '../../../components/table-cell'
import { formatDate, formatDurationMinutes, formatTime } from '../../../lib/date-and-time'
import { DateRange } from '../../../types/date-range'
import { useGetRouteSegmentSearchResults } from '../../../webapi/hooks/queries/use-get-route-segment-search'
import { RouteSegment, RouteSegmentDirection } from '../../../webapi/types/segments'

import { formatTimeWithDelta } from './format-time-with-delta'
import { groupSegmentsByRouteId } from './segments-table-utils'
import { Message, PalletsLink, StyledTableRow } from './segments.styled'
import { DashboardColumn, DashboardColumnVisibility } from './types'

interface Props {
  terminalCodes: string[]
  direction: RouteSegmentDirection
  dateRange: DateRange
  columnVisibility: DashboardColumnVisibility
  className?: string
  onViewSegmentPallets: (routeId: string, sequence: number) => void
}

export const SegmentsTable = ({
  terminalCodes,
  direction,
  dateRange,
  columnVisibility,
  className,
  onViewSegmentPallets,
}: Props) => {
  const {
    data: segmentsByRoute,
    isLoading: isSegmentsLoading,
    isError: isSegmentsError,
  } = useQuery({
    select: groupSegmentsByRouteId,
    ...useGetRouteSegmentSearchResults({
      terminalCodes,
      fromDate: dateRange.fromDate,
      toDate: dateRange.toDate,
      direction,
    }),
  })

  const renderColumn = (column: DashboardColumn, html: React.ReactNode): React.ReactNode | null =>
    columnVisibility[column] ? html : null

  const renderPalletColumn = (column: DashboardColumn, count: number, routeSegment: RouteSegment) => {
    return renderColumn(
      column,
      <PalletsLink
        isActive={Boolean(count)}
        onClick={() => onViewSegmentPallets(routeSegment.routeId, routeSegment.originSequence)}
      >
        {count}
      </PalletsLink>
    )
  }

  if (isSegmentsLoading) {
    return <CardLoader />
  }
  if (isSegmentsError) {
    return <Message isDanger>Failed to load route segments</Message>
  }
  if (segmentsByRoute?.length === 0) {
    return <Message isInfo>No route segments found for given selection.</Message>
  }

  return (
    <Table className={className} isFullwidth isNarrow>
      <thead>
        <StickyTableRow>
          {renderColumn(DashboardColumn.Date, <th>Date</th>)}
          {renderColumn(DashboardColumn.Segment, <th>Segment</th>)}
          {renderColumn(DashboardColumn.DriveTime, <th>Drive Time</th>)}
          {renderColumn(DashboardColumn.Courier, <th>Courier</th>)}
          {renderColumn(DashboardColumn.ScheduleDeparture, <th>Schedule Departure</th>)}
          {renderColumn(DashboardColumn.ActualDeparture, <th>Actual Departure</th>)}
          {renderColumn(DashboardColumn.ScheduleArrival, <th>Schedule Arrival</th>)}
          {renderColumn(DashboardColumn.ActualArrival, <th>Actual Arrival</th>)}
          {renderColumn(DashboardColumn.HomePallets, <th>Home pallets</th>)}
          {renderColumn(DashboardColumn.LockerPallets, <th>Locker pallets</th>)}
          {renderColumn(DashboardColumn.ReturnPallets, <th>Return pallets</th>)}
          {renderColumn(DashboardColumn.MixedPallets, <th>Mixed pallets</th>)}
          {renderColumn(DashboardColumn.TotalNumberOfParcels, <th>Parcels</th>)}
        </StickyTableRow>
      </thead>
      <tbody>
        {segmentsByRoute?.map((routeSegments, routeIndex) =>
          routeSegments.map((routeSegment, routeSegmentIndex) => {
            return (
              <StyledTableRow isEven={routeIndex % 2 === 0} key={`${routeIndex}${routeSegmentIndex}`}>
                {renderColumn(DashboardColumn.Date, <TableCell data={formatDate(routeSegment.scheduleDeparture)} />)}
                {renderColumn(
                  DashboardColumn.Segment,
                  <TableCell data={`${routeSegment.originName} to ${routeSegment.destinationName}`} />
                )}
                {renderColumn(
                  DashboardColumn.DriveTime,
                  <TableCell data={formatDurationMinutes(routeSegment.scheduleDriveTimeMinutes)} />
                )}
                {renderColumn(DashboardColumn.Courier, <TableCell data={routeSegment.courier} />)}
                {renderColumn(
                  DashboardColumn.ScheduleDeparture,
                  <TableCell data={formatTime(routeSegment.scheduleDeparture)} />
                )}
                {renderColumn(
                  DashboardColumn.ActualDeparture,
                  <TableCell data={formatTimeWithDelta(routeSegment.departure)} />
                )}
                {renderColumn(
                  DashboardColumn.ScheduleArrival,
                  <TableCell data={formatTime(routeSegment.scheduleArrival)} />
                )}
                {renderColumn(
                  DashboardColumn.ActualArrival,
                  <TableCell data={formatTimeWithDelta(routeSegment.arrival)} />
                )}
                {renderPalletColumn(
                  DashboardColumn.HomePallets,
                  routeSegment.palletCountByType.countMostlyHomePallets,
                  routeSegment
                )}
                {renderPalletColumn(
                  DashboardColumn.LockerPallets,
                  routeSegment.palletCountByType.countMostlyLockerPallets,
                  routeSegment
                )}
                {renderPalletColumn(
                  DashboardColumn.ReturnPallets,
                  routeSegment.palletCountByType.countReturnPallets,
                  routeSegment
                )}
                {renderPalletColumn(
                  DashboardColumn.MixedPallets,
                  routeSegment.palletCountByType.countMixedPallets,
                  routeSegment
                )}
                {renderColumn(
                  DashboardColumn.TotalNumberOfParcels,
                  <TableCell data={routeSegment.totalNumberOfParcels} />
                )}
              </StyledTableRow>
            )
          })
        )}
      </tbody>
    </Table>
  )
}
