import { AxiosInstance } from 'axios'

import { TerminalCountryCode } from '../../../types/terminal-country-codes'

import { useMutationOptionsWithVariables } from './use-mutation-options'

type PutCollectionPlacePoint = {
  identifier: string
  body: {
    name: string
    street: string
    postalCode: string
    city: string
    countryCode: TerminalCountryCode
    latitude: number
    longitude: number
    collectionPointIds: number[]
    placeIds: string[]
  }
}

const saveCollectionPlacePoints = (webapiClient: AxiosInstance, { identifier, body }: PutCollectionPlacePoint) =>
  webapiClient.put<void>(`/freightman/collection-place-points/${identifier}`, body)

export const useSaveCollectionPlacePoints = () => useMutationOptionsWithVariables(saveCollectionPlacePoints)
