import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'
import { TerminalCountryCode } from '../../../types/terminal-country-codes'

import { useQueryOptions } from './use-query-options'

export interface CollectionPlacePoint {
  identifier: string
  name: string
  street: string
  postalCode: string
  city: string
  countryCode: TerminalCountryCode
  latitude: number
  longitude: number
  collectionPointIds: number[]
  placeIds: string[]
}

export type GetCollectionPlacePointResponse = {
  collectionPlacePoints: CollectionPlacePoint[]
}

export const getCollectionPlacePointQueryKey = ['collection-place-points']

const getCollectionPlacePoints = (webapiClient: AxiosInstance) =>
  webapiClient.get<GetCollectionPlacePointResponse>('/freightman/collection-place-points')

export const useGetCollectionPlacePoints = () => ({
  ...useQueryOptions(getCollectionPlacePointQueryKey, getCollectionPlacePoints, getResponseData),
  staleTime: Infinity,
  cacheTime: Infinity,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
})
