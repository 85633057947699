import styled from 'styled-components'

export const TimePickerContent = styled.div`
  display: inline-flex;
  flex-direction: row;
`

export const TimePickerColumns = styled.div.attrs({ className: 'columns' })``

export const TimePickerColumn = styled.div.attrs({ className: 'column' })``

export const TimeInputField = styled.input.attrs({ className: 'input is-small' })``
