import { format } from 'date-fns'
import React from 'react'

import { Table, TableRow } from '../../../components/bulma/table.styled'
import { StickyTableRow } from '../../../components/sticky-table-row.styled'
import { GetReadyOnDemandReturnsResponse } from '../../../webapi/hooks/queries/use-get-ready-on-demand-returns'

interface Props {
  readyOnDemandReturns: GetReadyOnDemandReturnsResponse
  onToggleMerchantSelection: (merchantId: number) => void
  selectedMerchantIds: number[]
  className?: string
}

export const BookingTable = ({
  readyOnDemandReturns,
  onToggleMerchantSelection,
  selectedMerchantIds,
  className,
}: Props) => (
  <Table className={className} isHoverable isNarrow isFullwidth isStriped>
    <thead>
      <StickyTableRow>
        <th>Merchant ID</th>
        <th>Merchant Name</th>
        <th>Parcels to Book</th>
        <th>Cut-Off</th>
      </StickyTableRow>
    </thead>
    <tbody>
      {readyOnDemandReturns.map(({ merchantId, merchantName, countParcels, cutOffLocal }) => (
        <TableRow
          key={merchantId}
          onClick={() => onToggleMerchantSelection(merchantId)}
          isSelected={selectedMerchantIds.includes(merchantId)}
          isSelectable
        >
          <td>{merchantId}</td>
          <td>{merchantName}</td>
          <td>{countParcels}</td>
          <td>{format(new Date(cutOffLocal), 'HH:mm')}</td>
        </TableRow>
      ))}
    </tbody>
  </Table>
)
