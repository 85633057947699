import styled, { css } from 'styled-components'

export const Section = styled.section`
  width: 100%;
  padding: 1rem 1rem 1rem 0;
`

interface BasePageProps {
  fixedPageHeight?: boolean
  carriersMenuHeight?: number
}

export const BasePage = styled.div<BasePageProps>`
  display: flex;
  ${({ fixedPageHeight, carriersMenuHeight = 0 }) =>
    fixedPageHeight &&
    css`
      overflow: hidden;
      height: ${`calc(100vh - ${carriersMenuHeight}px)`};
    `}
`
