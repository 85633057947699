import classNames from 'classnames'
import styled from 'styled-components'

interface InputProps {
  isExpanded?: boolean
}

export const InputField = styled.input.attrs({ className: 'input' })``
export const InputLabel = styled.label.attrs({ className: 'label' })``
export const InputControl = styled.div.attrs(({ isExpanded }: InputProps) => ({
  className: classNames('control', {
    'is-expanded': isExpanded,
  }),
}))<InputProps>``
export const InputDiv = styled.div.attrs(() => ({ classname: 'field' }))`
  margin-bottom: 0.75rem;
`
export const InputSelect = styled.div.attrs(() => ({ className: 'select is-fullwidth' }))``
