import React, { ReactNode } from 'react'

import { InputControl, InputDiv, InputLabel } from './device-input.styled'

interface Props {
  label: string
  children: ReactNode
}

export const DeviceInput = ({ label, children }: Props) => {
  return (
    <InputDiv>
      <InputLabel>{label}</InputLabel>
      <InputControl>{children}</InputControl>
    </InputDiv>
  )
}
