import React, { PropsWithChildren } from 'react'

import { Field, FieldBody, FieldLabel, Label } from '../../../../components/bulma/field.styled'

interface Props {
  label: string
}

export const ConfigurationField = ({ label, children }: PropsWithChildren<Props>) => (
  <Field isHorizontal>
    <FieldLabel>
      <Label>{label}</Label>
    </FieldLabel>
    <FieldBody>
      <Field>{children}</Field>
    </FieldBody>
  </Field>
)
