import { AxiosInstance } from 'axios'

import { useWebapiClient } from '../use-webapi-client'

export const useMutationOptions = <MutationResponse, MappedResponse>(
  mutationFn: (webapiClient: AxiosInstance) => Promise<MutationResponse>,
  mapFn?: (response: MutationResponse) => MappedResponse
) => {
  const webapiClient = useWebapiClient()
  return {
    mutationFn: () => mutationFn(webapiClient).then(mapFn),
  }
}

export const useMutationOptionsWithVariables = <Key, MutationResponse, Variables, MappedResponse>(
  mutationFn: (webapiClient: AxiosInstance, variables: Variables) => Promise<MutationResponse>,
  mapFn?: (response: MutationResponse) => MappedResponse,
  mutationKey?: Key[],
  variables?: Variables
) => {
  const webapiClient = useWebapiClient()
  return {
    mutationKey: [mutationKey, variables],
    mutationFn: (variables: Variables) => mutationFn(webapiClient, variables).then(mapFn),
  }
}
