import { format } from 'date-fns'

export const timestampFormat = `yyyy-MM-dd HH:mm:ss`
export const dateFormat = 'yyyy-MM-dd'
export const timeFormat = 'HH:mm'
export const dateTimeFormat = `${dateFormat} ${timeFormat}`

export const formatDurationMinutes = (totalMinutes: number) => {
  if (totalMinutes < 1) {
    return null
  }

  const formattedDurationSegments: string[] = []

  const hours = Math.floor(totalMinutes / 60)
  if (hours) {
    formattedDurationSegments.push(`${hours}h`)
  }

  const minutes = totalMinutes % 60
  if (minutes) {
    formattedDurationSegments.push(`${minutes}min`)
  }

  return formattedDurationSegments.length ? formattedDurationSegments.join(' ') : null
}

export const formatDeltaMinutes = (deltaMinutes: number) => {
  if (deltaMinutes === 0) {
    return null
  }

  const prefix = deltaMinutes > 0 ? '+' : '-'
  const absoluteDeltaMinutes = Math.abs(deltaMinutes)
  const formattedDuration = formatDurationMinutes(absoluteDeltaMinutes)
  return `${prefix}${formattedDuration}`
}

export const formatTimestamp = (timestamp: string | number) => format(new Date(timestamp), timestampFormat)
export const formatDate = (date: string | number) => format(new Date(date), dateFormat)
export const formatTime = (date: string | number) => format(new Date(date), timeFormat)
export const formatDateTime = (date: string | number) => format(new Date(date), dateTimeFormat)
