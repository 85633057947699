import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import styled from 'styled-components'

import { Button as BulmaButton } from '../../bulma/button.styled'
import { Tabs } from '../../bulma/tabs.styled'
import { SideBox } from '../../side-box.styled'

export const SelectSideBox = styled(SideBox).attrs({ className: classNames('p-0') })`
  && {
    display: flex;
    flex-direction: column;
  }
`

export const SideBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  overflow: hidden;
`

export const Title = styled.p.attrs(() => ({ className: 'title is-6 has-text-centered mt-5 ml-5 mr-5 mb-4' }))``

export const SelectList = styled.div.attrs(() => ({ className: 'pl-5 pr-5' }))`
  && {
    overflow-y: scroll;
    flex: 1;
  }
`

export const SelectTabs = styled(Tabs).attrs({ className: String('ml-5 mr-5 mb-0') })``

export const SelectTabsList = styled.ul`
  flex-wrap: wrap;
  width: 100%;
`

interface TabProps {
  active: boolean
  onClick: () => void
}

export const Tab = styled.li.attrs(({ active, onClick }: TabProps) => ({
  className: classNames({ 'is-active': active }),
  onClick: onClick,
}))<TabProps>``

export const RemoveItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em 0.75em;
  cursor: pointer;
  width: 100%;

  svg {
    margin-right: calc(0.75rem + 3px); // Makes the icon occupy the same space as check boxes
  }

  :not(:last-child) {
    border-bottom: 1px solid #ededed;
  }

  :hover {
    background-color: #f5f5f5;
    svg {
      opacity: 0.5;
    }
  }
`

export const Item = styled.label`
  color: #4a4a4a;
  display: inline-flex;
  cursor: pointer;
  padding: 0.5em 0.75em;
  width: 100%;

  input[type='radio'],
  input[type='checkbox'] {
    cursor: pointer;
    margin-right: 0.75rem;
  }

  :not(:last-child) {
    border-bottom: 1px solid #ededed;
  }

  :hover {
    background-color: #f5f5f5;
  }
`

export const ListItemLabelBold = styled.span.attrs({
  className: 'has-text-weight-semibold',
})``

export const Search = styled.p.attrs({
  className: 'control has-icons-left mt-2 mb-2 ml-5 mr-5',
  type: 'text',
})``

export const SearchInput = styled.input.attrs({ className: 'input', placeholder: 'Search' })``

export const SideBoxFooter = styled.div.attrs({ className: classNames('p-5') })`
  border-top: 1px solid #dbdbdb;
`

export const Label = styled.div.attrs({ className: 'is-size-6 pb-3' })`
  display: flex;
  justify-content: center;
`

interface ButtonsProps {
  vertical?: boolean
}
export const Buttons = styled.div<ButtonsProps>`
  display: flex;
  gap: 0.75rem;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
`

export const Button = styled(BulmaButton).attrs(() => ({
  className: 'card-footer-item',
}))`
  && {
    border-radius: 0;
  }
`

export const NoItemsMessage = styled.div.attrs(() => ({ className: 'is-italic has-text-centered mt-3' }))`
  width: 100%;
  color: hsl(0, 0%, 71%);
`

export const RemoveItemIcon = styled(FontAwesomeIcon).attrs({ icon: faTimes })`
  opacity: 0.3;
  :hover {
    opacity: 5;
  }
`

export const CloseButton = styled.div.attrs({ className: 'p-5' })`
  cursor: pointer;
  :hover {
    button {
      background-color: rgba(10, 10, 10, 0.3);
    }
  }
`
export const Icon = styled(FontAwesomeIcon).attrs({ className: '' })`
  svg {
    margin-left: 0px !important;
  }
  color: steelblue;
  margin-top: 0.1em;
  margin-right: 0.5rem;
`
