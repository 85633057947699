import styled from 'styled-components'

import { SimpleMessage } from '../../../components/simple-message'

export const Root = styled.div.attrs({})`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 10px;
`

export const NonSelected = styled.div.attrs({})`
  flex: 1;
  text-align: center;
  font-style: italic;
`

export const StyledSimpleMessage = styled(SimpleMessage)`
  margin-top: 1rem;
`
