import React from 'react'

import { ListItem, ListItemButtons, ListItemContent, ListItemControls } from '../../../components/bulma/list.styled'
import { Tag } from '../../../components/bulma/tag.styled'
import { TerminalCodeAndName, TerminalRelation } from '../../../webapi/hooks/queries/use-get-terminal-relations'

import { RelationDropdown } from './relation-dropdown'
import {
  ListItemColumn,
  ListItemColumns,
  TerminalRelationsListItemCatchment,
  TerminalRelationsListItemDescription,
  TerminalRelationsListItemTitle,
} from './terminal-relations-list-item.styled'

interface Props {
  relation: TerminalRelation
  openTerminalSelector: (relation: TerminalRelation) => void
  clearServicedBy: (terminalCode: string) => void
  scrollToTerminal: (terminalCode: string) => void
  setRef: (ref: HTMLDivElement | null) => void
  isLast: boolean
}

export const TerminalRelationsListItem = ({
  relation,
  openTerminalSelector,
  clearServicedBy,
  scrollToTerminal,
  setRef,
  isLast,
}: Props) => {
  const scrollToRelatedTerminal = (terminal: TerminalCodeAndName | null) => {
    if (terminal) {
      scrollToTerminal(terminal.code)
    }
  }

  return (
    <ListItem ref={setRef}>
      <ListItemContent>
        <ListItemColumns>
          <ListItemColumn>
            <TerminalRelationsListItemDescription>Name</TerminalRelationsListItemDescription>
            <TerminalRelationsListItemTitle>{relation.terminal.name}</TerminalRelationsListItemTitle>
          </ListItemColumn>
          <ListItemColumn>
            <TerminalRelationsListItemDescription>Serviced By</TerminalRelationsListItemDescription>
            <TerminalRelationsListItemDescription>
              {relation.servicedBy ? (
                <a onClick={() => scrollToRelatedTerminal(relation.servicedBy)}>{relation.servicedBy.name}</a>
              ) : (
                <Tag isWarning isLight>
                  Not serviced by terminal
                </Tag>
              )}
            </TerminalRelationsListItemDescription>
          </ListItemColumn>
          <ListItemColumn>
            <TerminalRelationsListItemDescription>Catchment</TerminalRelationsListItemDescription>
            <TerminalRelationsListItemCatchment>
              {relation.catchment.map((terminal, index) => (
                <a key={index} onClick={() => scrollToRelatedTerminal(terminal)}>
                  {terminal.name}
                  {index < relation.catchment.length - 1 ? ',' : ''}
                </a>
              ))}
            </TerminalRelationsListItemCatchment>
          </ListItemColumn>
        </ListItemColumns>
      </ListItemContent>
      <ListItemControls>
        <ListItemButtons>
          <RelationDropdown
            isUp={isLast}
            onChange={() => openTerminalSelector(relation)}
            onClear={() => clearServicedBy(relation.terminal.code)}
          />
        </ListItemButtons>
      </ListItemControls>
    </ListItem>
  )
}
