import { faPen, faXmark } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'

import { Button } from '../../../components/bulma/button.styled'
import { Table, TableRow } from '../../../components/bulma/table.styled'
import { Icon } from '../../../components/select/select.styled'
import { StickyTableRow } from '../../../components/sticky-table-row.styled'
import { TableCell } from '../../../components/table-cell'
import { GetProtocolResponse } from '../../../webapi/hooks/queries/use-get-device-protocols'
import { Device, GetDeviceResponse } from '../../../webapi/hooks/queries/use-get-devices'

import { DeleteDevice } from './delete-device'
import { EditDevice } from './edit-device'

interface Props {
  data: GetDeviceResponse
  isEdit: boolean
  setIsEdit: () => void
  onDelete: (deviceId: string) => void
  isDeleting?: boolean
  className?: string
  protocols?: GetProtocolResponse
}

export const DeviceTable = (data: Props) => {
  const [isModal, setIsModal] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState<Device>()
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('')
  const [editDevice, setEditDevice] = useState(false)

  const onConfirmDelete = (deviceId: string) => {
    data.onDelete(deviceId)
    setIsModal(false)
    data.setIsEdit()
  }

  return (
    <div className={data.className}>
      {isModal && selectedDevice && (
        <DeleteDevice
          handleClose={() => setIsModal(false)}
          friendlyIdDelete={selectedDevice?.friendlyId}
          deviceIdDelete={selectedDeviceId}
          isDeleting={data.isDeleting}
          onDelete={onConfirmDelete}
        />
      )}
      {editDevice && selectedDevice && (
        <EditDevice onClose={() => setEditDevice(false)} protocols={data.protocols} device={selectedDevice} />
      )}
      <Table>
        <thead>
          <StickyTableRow>
            <th> ID </th>
            <th> IMEI </th>
            <th> SIM card ID</th>
            <th> Protocol </th>
            <th> Active Route ID</th>
            <th> Assigned at</th>

            {data.isEdit && (
              <>
                <th />
                <th />
              </>
            )}
          </StickyTableRow>
        </thead>
        <tbody>
          {data.data.devices.map((device) => (
            <TableRow key={device.deviceId}>
              <TableCell data={device.friendlyId} />
              <TableCell data={device.imei} />
              <TableCell data={device.simCardId} />
              <TableCell data={device.protocol} />
              <TableCell data={device.routeId} />
              <td>{device.assignedAt || '-'}</td>

              {data.isEdit && (
                <>
                  <td>
                    <Button
                      isPrimary
                      isRounded
                      isSmall
                      onClick={() => {
                        setSelectedDevice(device)
                        setEditDevice(true)
                      }}
                      aria-label="edit-device-button"
                    >
                      <Icon icon={faPen} />
                      {'Edit'}
                    </Button>
                  </td>
                  <td>
                    <Button
                      isDanger
                      isRounded
                      isSmall
                      onClick={() => {
                        setIsModal(true)
                        setSelectedDevice(device)
                        setSelectedDeviceId(device.deviceId)
                      }}
                    >
                      <Icon icon={faXmark} />
                      {'Delete'}
                    </Button>
                  </td>
                </>
              )}
            </TableRow>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
