import { useWebapiJWT } from '../webapi/hooks/use-webapi-jwt'

interface Props {
  children: JSX.Element
}

export const AwaitAuthentication = ({ children }: Props) => {
  const hasToken = Boolean(useWebapiJWT())
  return hasToken ? children : null
}
