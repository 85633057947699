import React from 'react'

import { Button } from '../../../components/bulma/button.styled'
import {
  ListItem,
  ListItemButtons,
  ListItemContent,
  ListItemControls,
  ListItemTitle,
} from '../../../components/bulma/list.styled'
import { PalletConfiguration } from '../../../webapi/types/pallet-configuration'

interface Props {
  config: PalletConfiguration
  onClick: () => void
}

export const ConfigurationsListItem = ({ config, onClick }: Props) => (
  <ListItem key={config.identifier}>
    <ListItemContent>
      <ListItemTitle>{config.name}</ListItemTitle>
    </ListItemContent>
    <ListItemControls>
      <ListItemButtons>
        <Button isSmall onClick={onClick}>
          View
        </Button>
      </ListItemButtons>
    </ListItemControls>
  </ListItem>
)
