import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent, forwardRef } from 'react'

import { Search as StyledSearch, SearchInput } from './select-side-box.styled'

interface Props {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const Search = forwardRef<HTMLInputElement, Props>(({ value, onChange }: Props, ref) => (
  <StyledSearch>
    <SearchInput ref={ref} value={value} onChange={onChange} />
    <span className="icon is-left">
      <FontAwesomeIcon icon={faSearch} />
    </span>
  </StyledSearch>
))
