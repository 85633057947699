import { formatDeltaMinutes, formatTime } from '../../../lib/date-and-time'
import { TimeWithDelta } from '../../../webapi/types/segments'

export const formatTimeWithDelta = (timeWithDelta: TimeWithDelta | null) => {
  if (!timeWithDelta) {
    return null
  }

  const { time, deltaMinutes } = timeWithDelta
  const formattedTime = formatTime(time)
  const formattedDelta = formatDeltaMinutes(deltaMinutes)
  return formattedDelta ? `${formattedTime} ${formattedDelta}` : formattedTime
}
