import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from './app'
import { Root } from './components/root'
import { getConfig } from './lib/get-config'
import { createStore } from './state/store'
import { GroundOpsAdminClientOptions } from './types/app-settings'

const getPreloadedState = ({ webapiJWT = '' }: GroundOpsAdminClientOptions) => ({
  webapiJWT,
})

export class GroundOpsAdminClient {
  private root: ReactDOM.Root | undefined = undefined

  initialize = (options: Omit<GroundOpsAdminClientOptions, 'env'>) => {
    getConfig(options.baseUrl)
      .then((env) => this.renderApplication({ ...options, env }))
      .catch(window.console.error)
  }

  renderApplication = (options: GroundOpsAdminClientOptions) => {
    const { rootElementId } = options
    const rootElement = document.getElementById(rootElementId)
    if (!rootElement) {
      throw new Error(`Root element with id ${rootElementId} not found`)
    }

    this.root = ReactDOM.createRoot(rootElement)
    this.root.render(
      <Root store={createStore(getPreloadedState(options))} options={options}>
        <App />
      </Root>
    )
  }

  destroy = () => {
    this.root?.unmount()
  }
}

window.groundOpsAdmin = { GroundOpsAdminClient }

// The application supports either running as a micro frontend or standalone.
// When running as a micro-frontend it is initialized from the host application instead of by itself.
const isStandaloneApplication = !document.currentScript?.getAttribute('data-is-micro-frontend')
if (isStandaloneApplication) {
  new window.groundOpsAdmin.GroundOpsAdminClient().initialize({
    rootElementId: 'ground-ops-admin-root',
    basename: '',
    baseUrl: '',
    isStandaloneApplication,
  })
}
