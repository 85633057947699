export const getKey = (key: string) => `budbee:ground-ops:${key}`

export const getItem = (key: string): unknown => {
  const item = localStorage.getItem(getKey(key))
  if (item) {
    try {
      return JSON.parse(item)
    } catch (error) {
      window.console.error('Unable to get localStorage item', error)
    }
  }
  return undefined
}

export const setItem = (key: string, value: unknown) => {
  try {
    localStorage.setItem(getKey(key), JSON.stringify(value))
  } catch (error) {
    window.console.error('Unable to set localStorage item', error)
  }
}
