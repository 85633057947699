import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'
import { TerminalCountryCode } from '../../../types/terminal-country-codes'
import { WebapiAcceptHeaders } from '../../../types/webapi-accept-headers'

import { useQueryOptions } from './use-query-options'

export interface Terminal {
  id: number
  code: string
  name: string
  address: { street: string; postalCode: string; countryCode: TerminalCountryCode }
}

export type GetTerminalsResponse = Terminal[]

export const getTerminalsQueryKey = ['terminals']

const getTerminals = (webapiClient: AxiosInstance) =>
  webapiClient.get<Terminal[]>('/terminals', { headers: { accept: WebapiAcceptHeaders.TERMINALS_V1 } })

export const useGetTerminals = () => ({
  ...useQueryOptions(getTerminalsQueryKey, getTerminals, getResponseData),
  staleTime: Infinity,
  cacheTime: Infinity,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
})
