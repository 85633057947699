import React, { useState } from 'react'
import { useMutation } from 'react-query'

import { CardModal } from '../../../components/device/card-modal'
import { DeviceInput } from '../../../components/device/device-input'
import { InputField } from '../../../components/device/device-input.styled'
import { SelectProtocol } from '../../../components/device/select-protocol'
import { useGpsTrackingDevice } from '../../../webapi/hooks/mutations/use-gps-tracking-device'
import { GetProtocolResponse } from '../../../webapi/hooks/queries/use-get-device-protocols'

import { ErrorMessage, SuccessMessage } from './messages'

interface Props {
  onClose: () => void
  invalidateQueries: () => void
  protocols?: GetProtocolResponse
}

export const AddDevice = (props: Props) => {
  const [imei, setImei] = useState<string>()
  const [protocol, setProtocol] = useState('')
  const [simcardId, setSimCardId] = useState<string>()

  const gpsTrackingDeviceMutation = useMutation({ ...useGpsTrackingDevice(), onSuccess: props.invalidateQueries })
  const { isLoading, isError, isSuccess, mutate } = gpsTrackingDeviceMutation

  const onSave = () => {
    mutate({
      imei: imei,
      protocol: protocol,
      simCardId: simcardId,
    })
  }

  return (
    <CardModal
      onClose={props.onClose}
      onSave={onSave}
      disableButton={!protocol || isLoading || isSuccess}
      title="Add device"
    >
      {isSuccess && <SuccessMessage />}
      {isError && <ErrorMessage />}
      <DeviceInput label="IMEI">
        <InputField
          aria-label="imei"
          type="text"
          placeholder="12345"
          onChange={(e) => setImei(e.target.value)}
          disabled={isLoading || isSuccess}
        />
      </DeviceInput>
      <DeviceInput label="SIM card ID">
        <InputField
          aria-label="simcard"
          type="text"
          placeholder="12345"
          onChange={(e) => setSimCardId(e.target.value)}
          disabled={isLoading || isSuccess}
        />
      </DeviceInput>
      {props.protocols && <SelectProtocol label="Protocol" protocols={props.protocols} setProtocol={setProtocol} />}
    </CardModal>
  )
}
