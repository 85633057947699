import { faSliders } from '@fortawesome/free-solid-svg-icons'
import React, { memo, useRef, useState } from 'react'

import { DateRange } from '../../types/date-range'
import { CardHeaderButton } from '../card.styled'

import { TerminalOption, TerminalSideBox } from './select-side-box/terminal-side-box/terminal-side-box'
import { Icon } from './select.styled'

const getLabel = (
  options: TerminalOption[],
  isMultiSelect?: boolean,
  generateLabel?: (options: TerminalOption[]) => string
) => {
  if (generateLabel) {
    return generateLabel(options)
  }
  if (options.length) {
    if (isMultiSelect) {
      return `Terminal (${options.length})`
    }
    const [option] = options
    return `Terminal: ${option.name}`
  }

  return 'Terminal'
}

const determineDefaultTab = (selectedOptions: TerminalOption[]) =>
  selectedOptions.length === 0 ? 'ALL' : selectedOptions[0].countryCode

interface Props {
  showDateRangeSelection?: boolean
  selectedTerminalCodes: string[]
  isMultiSelect?: boolean
  showClear?: boolean
  generateLabel?: (options: TerminalOption[]) => string
  onChange: (options: TerminalOption[], dateRange?: DateRange) => void
  onClear?: () => void
  disabled?: boolean
}

export const TerminalSelect = memo(
  ({
    showDateRangeSelection,
    selectedTerminalCodes,
    onChange,
    isMultiSelect,
    generateLabel,
    showClear,
    onClear,
    disabled,
  }: Props) => {
    const [selectedOptions, setSelectedOptions] = useState<TerminalOption[]>([])
    const [isSelectorVisible, setIsSelectorVisible] = useState(false)
    const initialSelection = useRef(selectedTerminalCodes)

    const onCancel = () => setIsSelectorVisible(false)
    const onSelectionChange = (options: TerminalOption[], dateRange?: DateRange) => {
      setIsSelectorVisible(false)
      setSelectedOptions(options)
      onChange(options, dateRange)
    }

    const onCleared = () => {
      onSelectionChange([])
      if (onClear) {
        onClear()
      }
    }

    return (
      <>
        <CardHeaderButton onClick={() => setIsSelectorVisible(true)} disabled={disabled}>
          <Icon icon={faSliders} />
          {getLabel(selectedOptions, isMultiSelect, generateLabel)}
        </CardHeaderButton>
        <TerminalSideBox
          selectedTerminalCodes={initialSelection.current}
          show={isSelectorVisible}
          showClear={showClear}
          showDateRangeSelection={showDateRangeSelection}
          isMultiSelect={isMultiSelect}
          determineDefaultTab={determineDefaultTab}
          onLoad={setSelectedOptions}
          onClear={onCleared}
          onCancel={onCancel}
          onChange={onSelectionChange}
        />
      </>
    )
  }
)
