import React from 'react'
import { Navigate } from 'react-router-dom'

import { ProtectedPage } from '../pages/base/protected-page'
import { ConsignmentsBooking } from '../pages/consignments'
import { BatteryDashboard, DeviceDashboard, Positions } from '../pages/gps-tracking'
import { Dashboard, Booking } from '../pages/merchant-return'
import { Phase } from '../pages/merchant-return'
import { MerchantWarehouses } from '../pages/merchant-warehouses'
import { Configurations, TerminalRelations } from '../pages/pallets'
import { Segments } from '../pages/routing'
import { SegmentDetails } from '../pages/routing/segments/segment-details'
import { Start } from '../pages/start'
import { TerminalGroups } from '../pages/terminals/groups'

export const routeList = [
  {
    path: '/',
    element: <ProtectedPage component={Start} />,
  },
  {
    path: '/merchant-return/dashboard',
    element: <ProtectedPage component={Dashboard} />,
  },
  {
    path: '/merchant-return/dashboard/:phase/:terminalCode',
    element: <ProtectedPage component={Phase} />,
  },
  {
    path: '/merchant-return/booking',
    element: <ProtectedPage component={Booking} />,
  },
  {
    path: '/consignments/booking',
    element: <ProtectedPage component={ConsignmentsBooking} />,
  },
  {
    path: '/routing/segments',
    element: <ProtectedPage component={Segments} />,
  },
  {
    path: '/routing/segment-details/route/:routeId/sequence/:sequence/',
    element: <ProtectedPage component={SegmentDetails} />,
  },
  {
    path: '/gps-tracking/device',
    element: <ProtectedPage component={DeviceDashboard} />,
  },
  {
    path: '/gps-tracking/position',
    element: <ProtectedPage component={Positions} />,
  },
  {
    path: '/pallets/terminal-relations',
    element: <ProtectedPage component={TerminalRelations} fixedPageHeight />,
  },
  {
    path: '/pallets/configurations',
    element: <ProtectedPage component={Configurations} fixedPageHeight />,
  },
  {
    path: '/locations/terminal-groups',
    element: <ProtectedPage component={TerminalGroups} fixedPageHeight />,
  },
  {
    path: '/locations/merchant-warehouses',
    element: <ProtectedPage component={MerchantWarehouses} fixedPageHeight />,
  },
  {
    path: '/gps-tracking/battery',
    element: <ProtectedPage component={BatteryDashboard} />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]
