import { AxiosInstance } from 'axios'

import { useMutationOptionsWithVariables } from './use-mutation-options'

interface BookConsignmentCandidateVariables {
  terminalCode: string
  merchantIds: number[]
}

const bookConsignmentCandidate = (webapiClient: AxiosInstance, variables: BookConsignmentCandidateVariables) =>
  webapiClient.post<void>('/slotman/book-candidates', variables)

const bookConsignmentCandidateKey = ['bookConsignmentCandidate']
export const useBookConsignmentCandidate = () =>
  useMutationOptionsWithVariables(bookConsignmentCandidate, undefined, bookConsignmentCandidateKey)
