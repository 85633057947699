import classnames from 'classnames'
import styled from 'styled-components'

interface TabProps {
  isSmall?: boolean
}

export const Tabs = styled.div.attrs(({ isSmall }: TabProps) => ({
  className: classnames('tabs', { 'is-small': isSmall }),
}))<TabProps>``

interface TabItemProps {
  isActive: boolean
}

export const TabItem = styled.li.attrs(({ isActive }: TabItemProps) => ({
  className: classnames({ 'is-active': isActive }),
}))<TabItemProps>``
