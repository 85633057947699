import { AxiosInstance } from 'axios'

import { Page } from '../../../hooks/use-page'
import { getResponseData } from '../../../lib/get-response-data'

import { useQueryOptionsWithVariables } from './use-query-options'

export enum MerchantDropOffParcelsPhase {
  NotInjected = 'NOT_INJECTED',
  ToBeTransited = 'TO_BE_TRANSITED',
  InTransit = 'IN_TRANSIT',
  Bookable = 'BOOKABLE',
  Booked = 'BOOKED',
  InLocker = 'IN_LOCKER',
  ReachedMaxAttempts = 'REACHED_MAX_ATTEMPTS',
}

interface GetMerchantDropOffParcelsVariables {
  page?: Page
  terminal: string | null
  merchantIds: number[]
  phase: MerchantDropOffParcelsPhase | null
}

export interface MerchantDropOffParcel {
  id: number
  type: 'RETURN' | 'DELIVERY'
  orderToken: string | null
  packageId: string
  shipmentId: string
  injectionLocker: {
    locker: string
  } | null
  terminalOfLastScan: string | null
  terminalOfLastScannedTime: string | null
  injectionTerminalCode: string
  ejectionTerminalCode: string
  merchantDropOff: {
    isReturnedToMerchant: boolean
    toBeBookedForDropOff: boolean
    dateDropOff: string | null
  }
}

export interface GetMerchantDropOffParcelsResponse {
  nextCursor: string | null
  previousCursor: string | null
  parcels: MerchantDropOffParcel[]
}

export const getMerchantDropOffParcelsQueryKey = ['merchant-drop-off', 'parcels-at-terminal']

const getMerchantDropOffParcels = (
  webapiClient: AxiosInstance,
  { page, ...variables }: GetMerchantDropOffParcelsVariables
) => {
  if (variables.terminal && variables.phase) {
    return webapiClient.post<GetMerchantDropOffParcelsResponse>('/merchant-drop-off/parcels-at-terminal', {
      ...variables,
      ...page,
    })
  }
  return Promise.reject(new Error('Argument validation failed'))
}

export const useGetMerchantDropOffParcels = (variables: GetMerchantDropOffParcelsVariables) =>
  useQueryOptionsWithVariables(getMerchantDropOffParcelsQueryKey, getMerchantDropOffParcels, variables, getResponseData)
