import classnames from 'classnames'
import styled, { css } from 'styled-components'

interface DropdownRootProps {
  expanded: boolean
  isRight?: boolean
  isUp?: boolean
  isStretched?: boolean
}

export const DropdownRoot = styled.div.attrs(({ expanded, isUp, isRight }: DropdownRootProps) => ({
  className: classnames('dropdown', {
    'is-active': expanded,
    'is-right': isRight,
    'is-up': isUp,
  }),
}))<DropdownRootProps>`
  ${({ isStretched }: DropdownRootProps) =>
    isStretched &&
    `
    width: 100%;
    display: flex !important;
  `}
`

export const DropdownTrigger = styled.div.attrs({ className: 'dropdown-trigger' })`
  width: 100%;
`

interface DropdownMenuProps {
  isMenuStretched?: boolean
}

export const DropdownMenu = styled.div.attrs({ className: 'dropdown-menu' })<DropdownMenuProps>`
  ${({ isMenuStretched }) =>
    isMenuStretched &&
    css`
      width: 100%;
    `}
`

export const DropdownContent = styled.div.attrs({ className: 'dropdown-content' })``

interface DropdownItemProps {
  selected: boolean
}

export const DropdownItem = styled.a.attrs(({ selected }: DropdownItemProps) => ({
  className: classnames('dropdown-item', {
    'is-active': selected,
  }),
}))<DropdownItemProps>``

export const DropdownDivider = styled.hr.attrs({ className: 'dropdown-divider' })``
