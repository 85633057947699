import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import styled from 'styled-components'

import { Button } from './bulma/button.styled'
import { Card, CardContent } from './bulma/card.styled'
import { Title5 } from './bulma/title.styled'

export const CardHeader = styled.div.attrs(() => ({}))`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const CardHeaderTitle = styled(Title5)`
  &&& {
    margin-bottom: 0;
  }
`

export const CardHeaderButtons = styled.div`
  display: flex;
  gap: 1.5rem;
`

export const CardHeaderButtonGroup = styled.div`
  display: flex;
  gap: 0.75rem;
`

interface CardHeaderButtonProps {
  isLoading?: boolean
}
export const CardHeaderButton = styled.button.attrs(({ isLoading }: CardHeaderButtonProps) => ({
  className: classnames('button is-small has-background-light', {
    'is-loading': isLoading,
  }),
}))<CardHeaderButtonProps>``

interface CardHeaderButtonIconProps {
  placement?: 'left' | 'right'
}

export const CardHeaderButtonIcon = styled(FontAwesomeIcon)<CardHeaderButtonIconProps>`
  margin-left: ${({ placement = 'left' }) => placement === 'right' && '0.5rem'};
  margin-right: ${({ placement = 'left' }) => placement === 'left' && '0.5rem'};
`

export const CardFooterButton = styled(Button).attrs(() => ({
  className: 'card-footer-item',
}))`
  && {
    border-radius: 0;
  }
`

export const ScrollableCard = styled(Card)`
  height: 100%;
`

export const ScrollableCardContent = styled(CardContent)`
  overflow-y: scroll;
`
