import React from 'react'

import { FieldControl } from '../../../../components/bulma/field.styled'
import { MerchantWarehouseLocation } from '../../../../webapi/hooks/queries/use-get-merchant-warehouse-locations'

interface Props {
  editMode: boolean
  merchantWarehouseLocations: Map<string, MerchantWarehouseLocation>
  selected: string | null
  onLocationUpdated: (warehouse: MerchantWarehouseLocation) => void
  openMerchantWarehouseSelector: (
    identifier: string | null,
    onChange: (warehouse: MerchantWarehouseLocation) => void,
    onClear: () => void
  ) => void
}

export const MerchantWarehouseField = (props: Props) => {
  const { editMode, selected, merchantWarehouseLocations, openMerchantWarehouseSelector, onLocationUpdated } = props
  const warehouseName = selected ? merchantWarehouseLocations.get(selected)?.name ?? '' : ''
  if (!editMode) {
    return <>{warehouseName}</>
  }
  return (
    <div className="field is-small has-addons">
      <FieldControl style={{ flex: 1 }}>
        <input className="input is-small" type="text" style={{ flex: 1 }} value={warehouseName} readOnly />
      </FieldControl>
      <FieldControl>
        <button
          className="button is-small"
          onClick={() =>
            openMerchantWarehouseSelector(
              selected,
              (warehouse) => onLocationUpdated(warehouse),
              () => null
            )
          }
        >
          Select Warehouse
        </button>
      </FieldControl>
    </div>
  )
}
