import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'
import { TerminalGroup } from '../../types/terminal-groups'

import { useQueryOptions } from './use-query-options'

export type GetTerminalGroupsResponse = {
  groups: TerminalGroup[]
}

export const getTerminalGroupsQueryKey = ['terminals', 'groups']

const getTerminalGroups = (webapiClient: AxiosInstance) =>
  webapiClient.get<GetTerminalGroupsResponse>('/freightman/terminals/groups')

export const useGetTerminalGroups = () => ({
  ...useQueryOptions(getTerminalGroupsQueryKey, getTerminalGroups, getResponseData),
  staleTime: 1000 * 60 * 5,
})
