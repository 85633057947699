import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

import { Button } from '../../../../components/bulma/button.styled'
import { MultiSelectDropDown } from '../../../../components/dropdown/multi-select-dropdown'
import { Icon } from '../../../../components/select/select.styled'
import { DestinationType } from '../../../../webapi/types/pallet-configuration'

import { prettyDestinationTypeName } from './configuration-utils'

const OPTIONS = [
  { name: 'Home', code: 'HOME', type: DestinationType.HOME },
  { name: 'Locker', code: 'LOCKER', type: DestinationType.LOCKER },
  { name: 'Merchant', code: 'MERCHANT', type: DestinationType.MERCHANT },
]

interface Props {
  selected: DestinationType[]
  onChange: (types: DestinationType[]) => void
}

export const DestinationTypeDropdown = ({ selected, onChange }: Props) => {
  const selectedOpts = OPTIONS.filter((option) => selected.some((e) => option.type === e))
  const textValue =
    selectedOpts.length === 0
      ? 'Not Applicable'
      : selectedOpts.map((it) => prettyDestinationTypeName(it.type)).join(', ')
  const renderButton = (toggle: () => void) => {
    return (
      <Button
        isSmall
        onClick={toggle}
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
        <span>{textValue}</span>
        <span className="icon is-small">
          <Icon icon={faAngleDown} />
        </span>
      </Button>
    )
  }
  return (
    <MultiSelectDropDown
      renderButton={renderButton}
      options={OPTIONS}
      selected={selectedOpts}
      onSelected={(newlySelected) => {
        const type: DestinationType[] = OPTIONS.filter((option) =>
          newlySelected.some((e) => e.code === option.code)
        ).map((option) => option.type)
        onChange(type)
      }}
      onClear={() => onChange([])}
      isStretched
      isMenuStretched
    />
  )
}
