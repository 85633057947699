import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import { Card, CardContent } from '../../../components/bulma/card.styled'
import { CardLoader } from '../../../components/card-loader.styled'
import { CardHeader, CardHeaderButtonGroup, CardHeaderButtons, CardHeaderTitle } from '../../../components/card.styled'
import { TerminalOption, TerminalSelect } from '../../../components/select'
import { getStoredTerminalCodes, setItem } from '../../../lib/local-storage'
import { useGetConsignmentsCandidates } from '../../../webapi/hooks/queries/use-get-consignments-candidates'
import { useGetTerminals } from '../../../webapi/hooks/queries/use-get-terminals'
import { getTerminalNames } from '../../../webapi/terminals'
import { terminalSelectDashboardKey } from '../../merchant-return/local-storage-keys'

import { Message, StyledConsignmentsBookingTable } from './consignments-booking.styled'

export const ConsignmentsBooking = () => {
  const [selectedTerminalCodes, setSelectedTerminalCodes] = useState(() =>
    getStoredTerminalCodes(terminalSelectDashboardKey)
  )
  const {
    data: terminals,
    isSuccess: isTerminalsSuccess,
    isError: isTerminalsError,
    isLoading: isTerminalsLoading,
  } = useQuery(useGetTerminals())

  const {
    data: consignmentsCandidates,
    isSuccess: isConsignmentsCandicatesSuccess,
    isError: isConsignmentsCandicatesError,
    isLoading: isConsignmentsCandicatesLoading,
  } = useQuery(useGetConsignmentsCandidates({ terminalCodes: selectedTerminalCodes }))

  const hasSelectedTerminals = selectedTerminalCodes.length > 0

  const isLoading = isTerminalsLoading || isConsignmentsCandicatesLoading
  const isError = isTerminalsError || isConsignmentsCandicatesError

  const showError = !isLoading && isError
  const showSelectTerminal = !isLoading && !isError && !hasSelectedTerminals
  const showResults = isTerminalsSuccess && isConsignmentsCandicatesSuccess && hasSelectedTerminals

  const onSelectedTerminals = (selectedTerminals: TerminalOption[]) => {
    const terminalCodes = selectedTerminals.map(({ code }) => code)
    setItem(terminalSelectDashboardKey, terminalCodes)
    setSelectedTerminalCodes(terminalCodes)
  }
  const terminaleNames = useMemo(() => getTerminalNames(terminals), [terminals])

  return (
    <Card>
      <CardContent>
        <CardHeader>
          <CardHeaderTitle>Dashboard</CardHeaderTitle>
          <CardHeaderButtons>
            <CardHeaderButtonGroup>
              <TerminalSelect
                selectedTerminalCodes={selectedTerminalCodes}
                onChange={onSelectedTerminals}
                isMultiSelect
              />
            </CardHeaderButtonGroup>
          </CardHeaderButtons>
        </CardHeader>
        {showSelectTerminal && <Message isInfo>Select atleast one terminal to continue.</Message>}
        {showError && <Message isDanger>Failed to load.</Message>}
        {isLoading && <CardLoader />}
        {showResults &&
          (consignmentsCandidates?.terminals.length ? (
            <StyledConsignmentsBookingTable data={consignmentsCandidates} terminalNames={terminaleNames} />
          ) : (
            <Message>No results found.</Message>
          ))}
      </CardContent>
    </Card>
  )
}
