import React from 'react'

import { Table, TableRow } from '../../../components/bulma/table.styled'
import { StickyTableRow } from '../../../components/sticky-table-row.styled'
import { useEnvironmentVariable } from '../../../hooks/use-environment-variable'
import { getOrderPageUrl } from '../../../lib/external-urls'
import {
  GetMerchantDropOffParcelsResponse,
  MerchantDropOffParcelsPhase,
} from '../../../webapi/hooks/queries/use-get-merchant-drop-off-parcels'

import { ejectionTerminalEnablement, injectionTerminalEnablement } from './column-enablement'
import { formatLastScan } from './column-formatting'

interface Props {
  data: GetMerchantDropOffParcelsResponse
  phase: MerchantDropOffParcelsPhase
  terminalNames: Record<string, string>
  className?: string
}

export const PhaseTable = ({ data, phase, terminalNames, className }: Props) => {
  const carriersClientUrl = useEnvironmentVariable('CARRIERS_CLIENT_URL')
  return (
    <Table className={className} isHoverable isFullwidth isStriped isNarrow>
      <thead>
        <StickyTableRow>
          <th>Order</th>
          <th>Package ID</th>
          <th>Shipment ID</th>
          <th>Locker</th>
          {injectionTerminalEnablement.has(phase) && <th>Injection terminal</th>}
          {ejectionTerminalEnablement.has(phase) && <th>Ejection terminal</th>}
          <th>Last scanned</th>
        </StickyTableRow>
      </thead>
      <tbody>
        {data.parcels.map((parcel) => (
          <TableRow key={parcel.packageId}>
            <td>
              {parcel.orderToken && (
                <a href={getOrderPageUrl(carriersClientUrl, parcel.orderToken)}>{parcel.orderToken}</a>
              )}
            </td>
            <td>{parcel.packageId}</td>
            <td>{parcel.shipmentId}</td>
            <td>{parcel.injectionLocker?.locker}</td>
            {injectionTerminalEnablement.has(phase) && <td>{terminalNames[parcel.injectionTerminalCode]}</td>}
            {ejectionTerminalEnablement.has(phase) && <td>{terminalNames[parcel.ejectionTerminalCode]}</td>}
            <td>{formatLastScan(parcel, terminalNames)}</td>
          </TableRow>
        ))}
      </tbody>
    </Table>
  )
}
