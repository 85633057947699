import { AxiosInstance } from 'axios'

import { getResponseData } from '../../../lib/get-response-data'
import { WebapiAcceptHeaders } from '../../../types/webapi-accept-headers'

import { useQueryOptionsWithVariables } from './use-query-options'

interface GpsTrackingPositionVariables {
  startTime: number
  endTime: number
  deviceId?: string
  onRoute?: boolean
}

export interface GpsTrackingPoistion {
  deviceId: string
  routeId: string
  timestamp: number
  longitude: number
  latitude: number
  speed: number
  imei: string
}

export type GetGpsTrackingPositionsResponse = {
  positions: GpsTrackingPoistion[]
}

export const getUrl = (deviceId?: string) => {
  if (deviceId) {
    return `/tracking-devices/devices/${deviceId}/positions`
  }
  return '/tracking-devices/positions'
}

export const getGpsTrackingPositionsKey = ['gps-tracking-device', 'positions']
const getGpsTrackingPositions = (
  webapiClient: AxiosInstance,
  { startTime, endTime, deviceId, onRoute }: GpsTrackingPositionVariables
) =>
  webapiClient.get<GetGpsTrackingPositionsResponse>(getUrl(deviceId), {
    headers: { accept: WebapiAcceptHeaders.GPS_POSITIONS_V1 },
    params: {
      startTime: startTime,
      endTime: endTime,
      onRoute: onRoute,
    },
  })

export const useGetGpsTrackingPositions = (variables: GpsTrackingPositionVariables) =>
  useQueryOptionsWithVariables(getGpsTrackingPositionsKey, getGpsTrackingPositions, variables, getResponseData)
