import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import { Button, SquareButton } from '../../../components/bulma/button.styled'
import { CardFooter } from '../../../components/bulma/card.styled'
import {
  List,
  ListItem,
  ListItemButtons,
  ListItemContent,
  ListItemControls,
  ListItemTitle,
} from '../../../components/bulma/list.styled'
import {
  CardHeader,
  CardHeaderButtonGroup,
  CardHeaderButtons,
  CardHeaderTitle,
  ScrollableCard,
  ScrollableCardContent,
} from '../../../components/card.styled'
import { notification } from '../../../lib/notification'
import { useGetTerminalGroups } from '../../../webapi/hooks/queries/use-get-terminal-groups'
import { useGetTerminalLocations } from '../../../webapi/hooks/queries/use-get-terminal-locations'
import { TerminalGroup } from '../../../webapi/types/terminal-groups'
import { createTerminalLocationsMap } from '../../pallets/configurations/configurations-utils'

import { Group } from './group'
import { NonSelected, Root } from './terminal-groups.styled'

const newGroup = (): TerminalGroup => ({ identifier: '', name: '', terminals: [] })

export const TerminalGroups = () => {
  const terminalLocationsQuery = useQuery({
    ...useGetTerminalLocations(),
    select: createTerminalLocationsMap,
  })
  const terminalGroupsQuery = useQuery({
    ...useGetTerminalGroups(),
    onError: () => notification.error('Failed to load terminal groups'),
  })
  const [searchParams] = useSearchParams()
  const [group, setGroup] = useState<TerminalGroup | undefined>()
  const onClose = () => setGroup(undefined)
  useEffect(() => {
    const identifier = searchParams.get('identifier')
    if (identifier) {
      setGroup(terminalGroupsQuery.data?.groups.find((group) => group.identifier === identifier))
    }
  }, [searchParams, terminalGroupsQuery.data])
  return (
    <Root>
      <ScrollableCard>
        <ScrollableCardContent>
          <GroupsHeader />
          <List>
            {terminalGroupsQuery.isSuccess &&
              terminalGroupsQuery.data?.groups.map((group) => (
                <GroupListItem
                  key={group.identifier}
                  identifier={group.identifier}
                  name={group.name}
                  onView={() => setGroup(group)}
                />
              ))}
          </List>
        </ScrollableCardContent>
        <CardFooter>
          <div style={{ flex: 1 }} />
          <SquareButton isInfo onClick={() => setGroup(newGroup())} disabled={!!group}>
            New
          </SquareButton>
        </CardFooter>
      </ScrollableCard>
      {group && terminalLocationsQuery.isSuccess ? (
        <Group group={group} terminalLocations={terminalLocationsQuery.data} onClose={onClose} />
      ) : (
        <NonSelected />
      )}
    </Root>
  )
}

const GroupsHeader = () => {
  return (
    <CardHeader>
      <CardHeaderTitle>Terminal Groups</CardHeaderTitle>
      <CardHeaderButtons>
        <CardHeaderButtonGroup></CardHeaderButtonGroup>
      </CardHeaderButtons>
    </CardHeader>
  )
}

interface GroupListItemProps {
  identifier: string
  name: string
  onView: () => void
}
const GroupListItem = ({ identifier, name, onView }: GroupListItemProps) => {
  return (
    <ListItem key={identifier}>
      <ListItemContent>
        <ListItemTitle>{name}</ListItemTitle>
      </ListItemContent>
      <ListItemControls>
        <ListItemButtons>
          <Button isSmall onClick={onView}>
            View
          </Button>
        </ListItemButtons>
      </ListItemControls>
    </ListItem>
  )
}
