import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

import { Button } from '../../../../components/bulma/button.styled'
import { Dropdown } from '../../../../components/dropdown'
import { Icon } from '../../../../components/select/select.styled'
import { EjectionTerminalRelation } from '../../../../webapi/types/pallet-configuration'

import { prettyEjectionTerminalRelationName } from './configuration-utils'

const NA = { name: 'Not Applicable', code: 'NA', relation: null }

const ELEMENTS = [
  [
    { name: 'To Terminal', code: 'TO_TERMINAL', relation: EjectionTerminalRelation.TO_TERMINAL },
    {
      name: 'In Catchment',
      code: 'IN_CATCHMENT',
      relation: EjectionTerminalRelation.IN_CATCHMENT,
    },
    {
      name: 'In Terminal Group',
      code: 'IN_TERMINAL_GROUP',
      relation: EjectionTerminalRelation.IN_TERMINAL_GROUP,
    },
  ],
  [NA],
]

interface Props {
  selected: EjectionTerminalRelation | null
  onChange: (relation: EjectionTerminalRelation | null) => void
  disabled?: boolean
}

export const EjectionTerminalDropdown = ({ selected, onChange, disabled }: Props) => {
  const selectedOpt = ELEMENTS.flat().find((opt) => opt.relation === selected) ?? NA
  const renderButton = (toggle: () => void) => {
    return (
      <Button
        isSmall
        onClick={toggle}
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        disabled={disabled}
        style={{ width: 180, display: 'flex', justifyContent: 'space-between' }}
      >
        <span>{selectedOpt.relation ? prettyEjectionTerminalRelationName(selectedOpt.relation) : NA.name}</span>
        <span className="icon is-small">
          <Icon icon={faAngleDown} />
        </span>
      </Button>
    )
  }
  return (
    <Dropdown
      renderButton={renderButton}
      elements={ELEMENTS}
      onSelected={(opt) => {
        const newlySelected = ELEMENTS.flat()
          .filter((element) => element.code === opt.code)
          .map((element) => element.relation)[0]
        onChange(newlySelected)
      }}
      selected={selectedOpt}
    />
  )
}
