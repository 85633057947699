import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { Button, SquareButton } from '../../components/bulma/button.styled'
import { CardFooter } from '../../components/bulma/card.styled'
import {
  List,
  ListItem,
  ListItemButtons,
  ListItemContent,
  ListItemControls,
  ListItemTitle,
} from '../../components/bulma/list.styled'
import { CardHeader, CardHeaderTitle, ScrollableCard, ScrollableCardContent } from '../../components/card.styled'
import { useGetCollectionPlacePoints } from '../../webapi/hooks/queries/use-get-collection-place-points'
import { MerchantWarehouse } from '../../webapi/types/merchant-warehouses'

import { NoneSelected, Root } from './merchant-warehouses.styled'
import { Warehouse } from './warehouse'

export const MerchantWarehouses = () => {
  const query = useQuery({
    ...useGetCollectionPlacePoints(),
  })
  const [warehouse, setWarehouse] = useState<MerchantWarehouse | null>(null)
  const onNew = () => {
    setWarehouse({
      identifier: '',
      name: '',
      street: '',
      postalCode: '',
      city: '',
      countryCode: 'SE',
      latitude: 0,
      longitude: 0,
      collectionPointIds: [],
      placeIds: [],
    })
  }
  return (
    <Root>
      <ScrollableCard>
        <ScrollableCardContent>
          <CardHeader>
            <CardHeaderTitle>Merchant Warehouses</CardHeaderTitle>
          </CardHeader>
          <List>
            {query.isSuccess &&
              query.data.collectionPlacePoints.map((cpp) => (
                <WarehouseListItem key={cpp.identifier} name={cpp.name} onView={() => setWarehouse(cpp)} />
              ))}
          </List>
        </ScrollableCardContent>
        <CardFooter>
          <div style={{ flex: 1 }} />
          <SquareButton isInfo onClick={onNew} disabled={warehouse !== null}>
            New
          </SquareButton>
        </CardFooter>
      </ScrollableCard>
      {warehouse ? <Warehouse warehouse={warehouse} onClose={() => setWarehouse(null)} /> : <NoneSelected />}
    </Root>
  )
}

interface WarehouseListItemProps {
  name: string
  onView: () => void
}
const WarehouseListItem = ({ name, onView }: WarehouseListItemProps) => {
  return (
    <ListItem>
      <ListItemContent>
        <ListItemTitle>{name}</ListItemTitle>
      </ListItemContent>
      <ListItemControls>
        <ListItemButtons>
          <Button isSmall onClick={onView}>
            View
          </Button>
        </ListItemButtons>
      </ListItemControls>
    </ListItem>
  )
}
